import React from "react";
import PropTypes from "prop-types";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/opacity.css";

const LazyImage = ({ src, alt, ...otherProps }) => {
  return (
    <>
      <LazyLoadImage effect="opacity" src={src} alt={alt} {...otherProps} />
      <noscript>
        <img src={src} alt={alt} className="h-full w-full" />
      </noscript>
    </>
  );
};

LazyImage.defaultProps = {
  src: undefined,
};

LazyImage.propTypes = {
  src: (props, propName, componentName) => {
    const { path, src } = props;
    if (!src && !path) {
      return new Error(
        `One of props 'path' or 'src' was not specified in '${componentName}'.`
      );
    }
    return null;
  },
  alt: PropTypes.string.isRequired,
};

export default LazyImage;
