/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Step from "./components/Step";
import { TextInput, Dropdown, TextArea, Button } from "components";
import { DatePicker } from "containers";
import ErrorAlert from "components/ErrorAlert";
import apiRequest from "helpers/apiRequest";
import AddPeriodModal from "components/AddPeriodModal";
import AddCategoryModal from "components/AddCategoryModal";
import AddMaterialModal from "components/AddMaterialModal";
import AddTypeModal from "components/AddTypeModal";
import RichTextEditor from "react-rte";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { Text } from "components";
import moment from "moment";

const FirstStepContainer = ({ match, location }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [defaultCollection, setDefaultCollection] = useState(null);
  const [defaultSubCollection, setDefaultSubCollection] = useState(null);
  const [itemData, setItemData] = useState({
    name: "",
    date_enrollment: "",
    invNumber: "",
    description: "",
    origin: "",
    dimension: "",
    preservation: "",
    collectionId: null,
    how_made: "",
    where_made: "",
    accommodation: "",
    restauration: "",
    note: "",
    ageId: null,
    categoryId: null,
    materialId: null,
    speciesId: null,
  });
  const [collection, setCollections] = useState([]);
  const [subCollections, setSubCollections] = useState([]);
  const [error, setError] = useState(null);
  const [checking, setChecking] = useState(false);
  const [fromSecondStep, setFromSecondStep] = useState(false);
  const [period, setPeriods] = useState([]);
  const [defaultPeriod, setDefaultPeriod] = useState(null);
  const [modalPeriodOpen, setModalPeriodOpen] = useState(false);
  const [category, setCategory] = useState([]);
  const [defaultCategory, setDefaultCategory] = useState(null);
  const [modalCategoryOpen, setModalCategoryOpen] = useState(false);
  const [material, setMaterial] = useState([]);
  const [defaultMaterial, setDefaultMaterial] = useState(null);
  const [modalMaterialOpen, setModalMaterialOpen] = useState(false);
  const [type, setType] = useState([]);
  const [defaultType, setDefaultType] = useState(null);
  const [modalTypeOpen, setModalTypeOpen] = useState(false);

  const [textEditorValue, setTextEditorValue] = useState(
    //RichTextEditor.createEmptyValue()
    ""
  );
  const [defaultDate, setDefaultDate] = useState(null);

  const findSubcategory = async (id) => {
    setDefaultSubCollection(null);
    try {
      const response = await apiRequest({
        method: "get",
        url: `collection/list/${
          id || match.params.categoryId
        }?pageSize=1000000`,
      });
      if (response.status === 200 && response.data?.files?.length > 0) {
        // console.log("RESPONSE", response.data?.files);
        let matching = response.data.files.find(
          (elem) => elem.id == match.params.subCategoryId
        );
        //elem.id == id ||

        setDefaultSubCollection({ value: matching?.id, label: matching?.name });

        let coll = [];
        response.data.files.map((elem) => {
          return coll.push({
            value: elem.id,
            label: elem.name,
          });
        });
        setSubCollections([...coll]);
        setItemData({ ...itemData, collectionId: matching.id });
      }
    } catch (err) {
      if (err?.response?.status === 401) {
        history.push("/auth/login");
      }

      console.log(err);
    }
  };

  const getAllCollections = async () => {
    try {
      const response = await apiRequest({
        method: "get",
        url: "collection?page=1&pageSize=1000000",
      });
      if (response.status === 200) {
        let matching = response.data.files.find(
          (elem) => elem.id == match.params.categoryId
        );

        setDefaultCollection({ value: matching?.id, label: matching?.name });

        const coll = [];
        response.data.files.map((elem) => {
          return coll.push({
            value: elem.id,
            label: elem.name,
          });
        });
        setCollections([...coll]);
        setItemData({ ...itemData, collectionId: matching.id });
        checkForSubcategory(match.params.categoryId);
      }
    } catch (err) {
      if (err?.response?.status === 401) {
        history.push("/auth/login");
      }
      console.log(err);
    }
  };
  const getAllPeriods = async () => {
    try {
      const response = await apiRequest({
        method: "get",
        url: "age",
      });
      if (response.status === 200) {
        const coll = [];
        response.data.files.map((elem) => {
          return coll.push({
            value: elem.id,
            label: elem.name,
          });
        });
        setPeriods([...coll]);
        //setDefaultPeriod({ value: coll[0].value, label: coll[0].label });
      }
    } catch (err) {
      if (err?.response?.status === 401) {
        history.push("/auth/login");
      }
      console.log(err);
    }
  };
  //GETTING CATEGORIES FROM SETTINGS
  const getAllCategories = async () => {
    try {
      const response = await apiRequest({
        method: "get",
        url: "category",
      });
      if (response.status === 200) {
        const coll = [];
        response.data.files.map((elem) => {
          return coll.push({
            value: elem.id,
            label: elem.name,
          });
        });
        setCategory([...coll]);
        //setDefaultCategory({ value: coll[0].value, label: coll[0].label });
      }
    } catch (err) {
      if (err?.response?.status === 401) {
        history.push("/auth/login");
      }
      console.log(err);
    }
  };
  const getAllMaterials = async () => {
    try {
      const response = await apiRequest({
        method: "get",
        url: "material",
      });
      if (response.status === 200) {
        const coll = [];
        response.data.files.map((elem) => {
          return coll.push({
            value: elem.id,
            label: elem.name,
          });
        });
        setMaterial([...coll]);
        // setDefaultMaterial({ value: coll[0].value, label: coll[0].label });
      }
    } catch (err) {
      if (err?.response?.status === 401) {
        history.push("/auth/login");
      }
      console.log(err);
    }
  };
  const getAllTypes = async () => {
    try {
      const response = await apiRequest({
        method: "get",
        url: "species",
      });
      if (response.status === 200) {
        const coll = [];
        response.data.files.map((elem) => {
          return coll.push({
            value: elem.id,
            label: elem.name,
          });
        });
        setType([...coll]);
        // setDefaultType({ value: coll[0].value, label: coll[0].label });
      }
    } catch (err) {
      if (err?.response?.status === 401) {
        history.push("/auth/login");
      }
      console.log(err);
    }
  };

  const createError = (msg = "fillAllFields") => {
    setError(<ErrorAlert errorText={msg} />);
    setTimeout(() => {
      setError(null);
    }, 3000);
  };

  const sendData = async () => {
    try {
      const response = await apiRequest({
        method: "post",
        url: "item",
        data: itemData,
      });
      if (response.status === 200) {
        history.push({
          pathname: `/admin/secondStep/${response.data.id}`,
          state: {
            collectionId: itemData?.collectionId,
            subCollectionId:
              defaultSubCollection?.value != null &&
              defaultSubCollection?.value,
          },
        });
      }
    } catch (err) {
      if (err?.response?.status === 401) {
        history.push({
          pathname: "/auth/login",
        });
      }
      console.log(err);
      createError(err.response.data.error);
    }
  };

  const checkForm = () => {
    let isValid = true;
    for (const key in itemData) {
      //console.log("==itemData==", itemData);
      if (
        key !== "ageId" &&
        key !== "url" &&
        key !== "where_made" &&
        key !== "origin" &&
        key !== "date_enrollment" &&
        key !== "restauration" &&
        key !== "note" &&
        key !== "speciesId" &&
        key !== "materialId" &&
        key !== "categoryId" &&
        key !== "age" &&
        key !== "Material" &&
        key !== "category" &&
        key !== "species"
      ) {
        if (itemData[key] === "" || itemData[key] === null) {
          isValid = false;
          break;
        }
      }
    }
    if (!isValid) {
      return createError();
    } else if (fromSecondStep) {
      return updateElement();
    }
    return sendData();
  };

  const updateElement = async () => {
    try {
      const response = await apiRequest({
        method: "put",
        url: `item/${location.state.id}`,
        data: itemData,
      });
      if (response.status === 200) {
        history.push(`/admin/secondStep/${location.state.id}`);
      }
    } catch (err) {
      if (err?.response?.status === 401) {
        history.push("/auth/login");
      }
      console.log(err);
    }
  };

  const checkForSubcategory = async (id) => {
    setDefaultSubCollection(null);
    try {
      const response = await apiRequest({
        method: "get",
        url: `collection/${id}`,
      });
      if (response.status === 200 && response.data.hasSubcollection) {
        findSubcategory(id);
        console.log("ID", id);
      } else {
        setDefaultSubCollection(null);
      }
    } catch (err) {
      if (err?.response?.status === 401) {
        history.push("/auth/login");
      }
      console.log(err);
    }
  };

  const changeCollection = (e) => {
    setItemData({ ...itemData, collectionId: e.value });
    checkForSubcategory(e.value);
  };

  const getEditData = async () => {
    try {
      const response = await apiRequest({
        method: "get",
        url: `item/${location.state.id}`,
      });
      if (response.status === 200) {
        setItemData(response.data);
        {
          response.data.ageId !== null &&
            setDefaultPeriod({
              value: response.data.ageId,
              label: response.data.age.name,
            });
        }
        {
          response.data.materialId !== null &&
            setDefaultMaterial({
              value: response.data.materialId,
              label: response.data.Material.name,
            });
        }
        {
          response.data.speciesId !== null &&
            setDefaultType({
              value: response.data.speciesId,
              label: response.data.species.name,
            });
        }
        {
          response.data.categoryId !== null &&
            setDefaultCategory({
              value: response.data.categoryId,
              label: response.data.category.name,
            });
        }

        setDefaultDate(moment.utc(response.data.date_enrollment)._d);
        setTextEditorValue(response.data.note);
      }
    } catch (err) {
      if (err?.response?.status === 401) {
        history.push("/auth/login");
      }
    }
  };

  useEffect(() => {
    if (!location?.state?.from) {
      getAllCollections();
      checkForSubcategory();
    } else {
      getEditData();
      setFromSecondStep(true);
    }
  }, []);

  useEffect(() => {
    getAllPeriods();
  }, [modalPeriodOpen]);
  useEffect(() => {
    getAllCategories();
  }, [modalCategoryOpen]);
  useEffect(() => {
    getAllMaterials();
  }, [modalMaterialOpen]);
  useEffect(() => {
    getAllTypes();
  }, [modalTypeOpen]);

  return (
    <div className="bg-blue-100 lg:h-full w-full pt-5 lg:pb-5 lg:overflow-hidden relative">
      {error}
      <AddPeriodModal
        open={modalPeriodOpen}
        confirmButtonText={"done"}
        onClose={() => {
          setModalPeriodOpen(false);
        }}
        onConfirm={() => {
          setModalPeriodOpen(false);
        }}
      />
      <AddCategoryModal
        open={modalCategoryOpen}
        confirmButtonText={"done"}
        onClose={() => {
          setModalCategoryOpen(false);
        }}
        onConfirm={() => {
          setModalCategoryOpen(false);
        }}
      />
      <AddMaterialModal
        open={modalMaterialOpen}
        confirmButtonText={"done"}
        onClose={() => {
          setModalMaterialOpen(false);
        }}
        onConfirm={() => {
          setModalMaterialOpen(false);
        }}
      />
      <AddTypeModal
        open={modalTypeOpen}
        confirmButtonText={"done"}
        onClose={() => {
          setModalTypeOpen(false);
        }}
        onConfirm={() => {
          setModalTypeOpen(false);
        }}
      />
      <div className=" bg-white rounded mx-5 h-full px-3 py-3 lg:px-10 lg:py-0  pb-12">
        <div className="lg:h-24  flex flex-col lg:flex-row  lg:items-center border-b-2 mb-10">
          <h2 className="text-4xl font-inter pb-3 lg:pb-0 lg:mb-0 border-b-2 lg:border-b-0 lg:w-1/3 ">
            {t("addNewItem")}
          </h2>
          <Step
            title="generalInfo"
            subtitle="generalSpecification"
            step="01"
            active
          />
          <Step title="multimedia" subtitle="picsSkechVideo" step="02" />
        </div>
        <div className="w-full h-full lg:h-screen lg:overflow-y-auto">
          <div className=" lg:w-3/4   lg:pb-60 ">
            <TextInput
              obligatory
              invalid={
                checking && (itemData.name === "" || itemData.name === null)
                  ? true
                  : false
              }
              title="itemName"
              placeholder="itemName"
              value={itemData.name}
              onChange={(e) =>
                setItemData({ ...itemData, name: e.target.value })
              }
            />
            <div className="grid lg:grid-cols-3 grid-flow-row gap-3 mt-5  ">
              <TextInput
                obligatory
                invalid={
                  checking &&
                  (itemData.invNumber === "" || itemData.invNumber === null)
                    ? true
                    : false
                }
                title="invItemNumber"
                placeholder="invItemNumber"
                value={itemData.invNumber}
                onChange={(e) =>
                  setItemData({ ...itemData, invNumber: e.target.value })
                }
              />
              <DatePicker
                selected={defaultDate}
                invalid={false}
                title="entryDate"
                onChange={(e) => {
                  setItemData({ ...itemData, date_enrollment: e });
                  setDefaultDate(e);
                }}
              />
              <div>
                <TextInput
                  obligatory
                  invalid={
                    checking &&
                    (itemData.accommodation === "" ||
                      itemData.accommodation === null)
                      ? true
                      : false
                  }
                  title="accommodation"
                  value={itemData.accommodation}
                  placeholder="accommodation"
                  onChange={(e) =>
                    setItemData({ ...itemData, accommodation: e.target.value })
                  }
                />
              </div>
            </div>
            {defaultCollection !== null && (
              <div className="mt-10 flex">
                <div className="lg:w-1/2">
                  <Dropdown
                    obligatory
                    addNew
                    onClick={() => history.push("/admin/addCollection")}
                    title="collection"
                    options={collection}
                    defaultCollection={defaultCollection}
                    onChange={(e) => changeCollection(e)}
                  />
                </div>
                {defaultSubCollection !== null && (
                  <div className="lg:w-1/2 ml-5">
                    <Dropdown
                      obligatory
                      title="subCollection"
                      options={subCollections}
                      defaultCollection={defaultSubCollection}
                      onChange={(e) =>
                        setItemData({ ...itemData, collectionId: e.value })
                      }
                    />
                  </div>
                )}
              </div>
            )}

            <div className="mt-5 flex">
              <div className="lg:w-1/2">
                <Dropdown
                  cancel
                  onCancelClick={() => {
                    setItemData({ ...itemData, ageId: null });
                    setDefaultPeriod(null);
                  }}
                  value={defaultPeriod}
                  addNew
                  onClick={() => setModalPeriodOpen(true)}
                  title="period"
                  options={period}
                  defaultCollection={defaultPeriod}
                  onChange={(e) => {
                    setItemData({ ...itemData, ageId: e.value });

                    setDefaultPeriod({ value: e.value, label: e.label });
                  }}
                />
              </div>

              <div className="lg:w-1/2 ml-5">
                <Dropdown
                  cancel
                  onCancelClick={() => {
                    setItemData({ ...itemData, categoryId: null });
                    setDefaultCategory(null);
                  }}
                  value={defaultCategory}
                  addNew
                  onClick={() => setModalCategoryOpen(true)}
                  title="categories"
                  options={category}
                  defaultCollection={defaultCategory}
                  onChange={(e) => {
                    setItemData({ ...itemData, categoryId: e.value });
                    setDefaultCategory({ value: e.value, label: e.label });
                  }}
                />
              </div>
            </div>
            <div className="mt-5 flex">
              <div className="lg:w-1/2">
                <Dropdown
                  cancel
                  onCancelClick={() => {
                    setItemData({ ...itemData, materialId: null });
                    setDefaultMaterial(null);
                  }}
                  value={defaultMaterial}
                  addNew
                  onClick={() => setModalMaterialOpen(true)}
                  title="material"
                  options={material}
                  defaultCollection={defaultMaterial}
                  onChange={(e) => {
                    setItemData({ ...itemData, materialId: e.value });
                    setDefaultMaterial({ value: e.value, label: e.label });
                  }}
                />
              </div>

              <div className="lg:w-1/2 ml-5">
                <Dropdown
                  cancel
                  onCancelClick={() => {
                    setItemData({ ...itemData, speciesId: null });
                    setDefaultType(null);
                  }}
                  value={defaultType}
                  addNew
                  onClick={() => setModalTypeOpen(true)}
                  title="types"
                  options={type}
                  defaultCollection={defaultType}
                  onChange={(e) => {
                    setItemData({ ...itemData, speciesId: e.value });
                    setDefaultType({ value: e.value, label: e.label });
                  }}
                />
              </div>
            </div>

            <div className="mt-10">
              <TextArea
                obligatory
                invalid={
                  checking &&
                  (itemData.description === "" || itemData.description === null)
                    ? true
                    : false
                }
                description
                title="itemDescription"
                placeholder="saySomethinBoutItem"
                value={itemData.description}
                onChange={(e) =>
                  setItemData({ ...itemData, description: e.target.value })
                }
              />
            </div>
            <div className="grid lg:grid-cols-2 grid-flow-row gap-3 mt-5  ">
              <TextInput
                obligatory
                invalid={
                  checking &&
                  (itemData.dimension === "" || itemData.dimension === null)
                    ? true
                    : false
                }
                title="dimension"
                placeholder="20x20cm"
                value={itemData.dimension}
                onChange={(e) =>
                  setItemData({ ...itemData, dimension: e.target.value })
                }
              />

              <div>
                <TextInput
                  obligatory
                  invalid={
                    checking &&
                    (itemData.preservation === "" ||
                      itemData.preservation === null)
                      ? true
                      : false
                  }
                  title="preservation"
                  value={itemData.preservation}
                  onChange={(e) =>
                    setItemData({ ...itemData, preservation: e.target.value })
                  }
                />
              </div>
            </div>

            <div className="grid lg:grid-cols-2 mt-5 gap-10">
              <TextArea
                obligatory
                invalid={
                  checking &&
                  (itemData.how_made === "" || itemData.how_made === null)
                    ? true
                    : false
                }
                title="whenAndHowFound"
                value={itemData.how_made}
                onChange={(e) =>
                  setItemData({ ...itemData, how_made: e.target.value })
                }
              />
              <TextArea
                invalid={false}
                title="whereFound"
                value={itemData.where_made}
                onChange={(e) =>
                  setItemData({ ...itemData, where_made: e.target.value })
                }
              />
            </div>

            <div className="mt-5">
              <TextInput
                invalid={false}
                title="origin"
                placeholder="Bosna i Hercegovina"
                value={itemData.origin}
                onChange={(e) =>
                  setItemData({ ...itemData, origin: e.target.value })
                }
              />
            </div>

            <div className="mt-5">
              <TextArea
                invalid={false}
                title="restauration"
                value={itemData.restauration}
                onChange={(e) =>
                  setItemData({ ...itemData, restauration: e.target.value })
                }
              />
            </div>

            <div className="mt-5">
              {/*<TextArea
                invalid={false}
                title="note"
                value={itemData.note}
                onChange={(e) =>
                  setItemData({ ...itemData, note: e.target.value })
                }
              />*/}
              <div className="mb-1">
                <Text>{t("note")}</Text>
              </div>

              <ReactQuill
                value={textEditorValue}
                onChange={(description, delta, source, editor) => {
                  setTextEditorValue(description);
                  setItemData({ ...itemData, note: description });
                }}
              />
            </div>
            <div className="h-20  flex row justify-end items-center border-t-2 mt-16">
              <Button
                text="Odustani"
                invert
                onClick={() => history.push("/admin/collections")}
              />
              <div className="ml-3">
                <Button
                  text="Dalje"
                  onClick={() => {
                    checkForm();
                    setChecking(true);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FirstStepContainer;
