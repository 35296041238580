/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import ReactPaginate from "react-paginate";

import { useTranslation } from "react-i18next";

const Pagination = ({ pages, changePage }) => {
  const { t } = useTranslation();

  return (
    <div class="bg-white py-3 flex items-center justify-between border-t border-gray-200">
      <div class=" sm:flex-1 sm:flex sm:items-center sm:justify-between cursor-pointer">
        <div>
          <nav
            className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px"
            aria-label="Pagination"
          >
            <ReactPaginate
              previousLabel={t("previous")}
              nextLabel={t("next")}
              breakLabel={"..."}
              breakClassName={
                "relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-700"
              }
              pageCount={pages.totalPages || 1}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              pageClassName={
                "bg-white  border-gray-300 text-gray-500  md:inline-flex relative items-center px-4 py-2 border text-sm font-medium"
              }
              previousClassName={
                "relative inline-flex items-center px-2 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 hover:shadow-lg"
              }
              nextClassName={
                "relative inline-flex items-center px-2 py-2  border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 hover:shadow-lg"
              }
              onPageChange={changePage}
              containerClassName={
                "relative inline-flex items-center py-2 rounded-l-md  bg-white text-sm font-medium text-gray-500"
              }
              activeClassName={
                "z-10 bg-indigo-50 border-blue-primary text-blue-primary relative inline-flex items-center px-4 py-2 border text-sm font-medium"
              }
            />
          </nav>
        </div>
      </div>
    </div>
  );
};

export default Pagination;
