/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router";

import { useTranslation } from "react-i18next";
import {
  ToggleButton,
  Button,
  Loader,
  SettingsPeriods,
  SettingsCategories,
  SettingsMaterials,
  SettingsType,
} from "components";
import ConfirmationModal from "components/ConfirmationModal";
import SuccessModal from "components/SuccessModal";
import Error from "components/ErrorAlert";
import apiRequest from "helpers/apiRequest";
import { Range, getTrackBackground } from "react-range";

const SettingsPage = () => {
  const [active, setActive] = useState(false);
  const [values, setValues] = useState([600]);
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [successModal, setSuccessModal] = useState(false);
  const [error, setError] = useState(null);
  const [width, setWidth] = useState(600);
  const [height, setHeight] = useState(width / 1.72);
  const [styleWidth, setStyleWidth] = useState(width / 6);
  const [styleHeight, setStyleHeight] = useState(styleWidth / 1.72);

  const { t } = useTranslation();
  const history = useHistory();

  const getVisibility = async () => {
    setLoading(true);
    try {
      const response = await apiRequest({
        method: "get",
        url: "visibility",
      });
      if (response.status === 200) {
        setActive(response.data.visibility);
        setLoading(false);
      }
    } catch (err) {
      if (err?.response?.status === 401) {
        history.push("/auth/login");
      }
      createError(err.message);
      setLoading(false);
    }
  };

  const changeVisibility = async () => {
    setLoading(true);
    try {
      const response = await apiRequest({
        method: "post",
        url: "visibility",
      });
      if (response.status === 200) {
        getVisibility();
      }
    } catch (err) {
      if (err?.response?.status === 401) {
        history.push("/auth/login");
      }
      createError(err.message);
      setLoading(false);
    }
  };

  const getDimensions = async () => {
    try {
      const response = await apiRequest({
        method: "get",
        url: "picture-property",
      });
      if (response.status === 200) {
        setHeight(response.data.dimensionY);
        setWidth(response.data.dimensionX);
        setStyleWidth(response.data.dimensionX / 3);
        setStyleHeight(response.data.dimensionX / 3 / 1.72);
        setValues([response.data.dimensionX]);
        setLoading(false);
      }
    } catch (err) {
      if (err?.response?.status === 401) {
        history.push("/auth/login");
      }
      createError(err.message);
      setLoading(false);
    }
  };
  const changeDimensions = async () => {
    setLoading(true);
    setOpenModal(false);
    try {
      const response = await apiRequest({
        method: "put",
        url: "picture-property",
        data: {
          dimensionX: width,
          dimensionY: height.toFixed(2),
        },
      });
      if (response.status === 200) {
        setSuccessModal(true);
        getDimensions();
      }
    } catch (err) {
      if (err?.response?.status === 401) {
        history.push("/auth/login");
      }
      createError(err.message);
      setLoading(false);
    }
  };
  const createError = (msg) => {
    setError(<Error errorText={msg} />);
    setTimeout(() => {
      setError(false);
    }, 3000);
  };

  useEffect(() => {
    getVisibility();
    getDimensions();
  }, []);
  return (
    <div className="bg-blue-100  h-full w-full pt-5 pb-5 overflow-hidden relative">
      {error}
      <ConfirmationModal
        info
        open={openModal}
        confirmationText={"sureToLeaveChanges"}
        confirmationDesc={"canChange"}
        confirmButtonText={"continue"}
        onConfirm={changeDimensions}
        onClose={() => setOpenModal(false)}
      />
      <SuccessModal
        open={successModal}
        title={"successChanges"}
        onConfirm={() => setSuccessModal(false)}
        onClose={() => setSuccessModal(false)}
      />
      <div
        className={` bg-white  rounded mx-5   px-10  pb-20 h-screen  pt-10 overflow-y-scroll ${
          loading && "flex items-center justify-center"
        }`}
      >
        {loading ? (
          <Loader />
        ) : (
          <>
            <div className="flex row space-x-10 items-center border-b pb-4">
              <h2 className="text-4xl font-inter  lg:w-1/2 mb-3 lg:mb-0 ">
                {t("settings")}
              </h2>
            </div>
            <div className="flex flex-col lg:flex-row">
              <div className="mt-10 w-full lg:w-2/6">
                <h2 className="text-2xl font-inter mb-3 lg:mb-0">
                  {t("appAvailable")}
                </h2>
                <p className="mt-5 text-smallGrey">{t("visibilityText")}</p>
              </div>
              <div className="w-full mt:10 lg:mt-0 lg:w-4/6 flex lg:items-center lg:justify-center">
                <ToggleButton
                  onClick={() => changeVisibility()}
                  active={active}
                />
              </div>
            </div>
            <div className="flex flex-col lg:flex-row mt-20">
              <div className="mt-10 w-full lg:w-2/6">
                <h2 className="text-2xl font-inter mb-3 lg:mb-0">
                  {t("imageDimensions")}
                </h2>
                <p className="mt-5 text-smallGrey">{t("chooseDimensions")}</p>
                <p className="mt-5 text-smallGrey">{t("maxPhotoSize")}</p>
              </div>
              <div className="w-full lg:w-3/6 flex flex-col items-center  pt-10 justify-center">
                <div
                  className="border-2 border-gray-300 border-dashed rounded-2x1 flex items-center justify-center"
                  style={{ width: 266, height: 155 }}
                >
                  <div
                    style={{
                      minHeight: 100,
                      minWidth: 200,
                      maxWidth: 266,
                      maxHeight: 155,
                      width: styleWidth,
                      height: styleHeight,
                      background: "#C4C4C4",
                    }}
                  />
                </div>
                <div className="w-3/6">
                  <Range
                    values={values}
                    step={1}
                    min={600}
                    max={800}
                    onChange={(values) => {
                      setValues(values);
                      setWidth(values[0]);
                      setHeight(values[0] / 1.72);
                      setStyleWidth(values[0] / 3);
                      setStyleHeight(values[0] / 3 / 1.72);
                    }}
                    renderTrack={({ props, children }) => (
                      <div
                        onMouseDown={props.onMouseDown}
                        onTouchStart={props.onTouchStart}
                        style={{
                          ...props.style,
                          height: "36px",
                          display: "flex",
                          width: "100%",
                        }}
                      >
                        <div
                          ref={props.ref}
                          style={{
                            height: "5px",
                            width: "100%",
                            borderRadius: "4px",
                            background: getTrackBackground({
                              values: values,
                              colors: ["#075985", "#CBD5E1"],
                              min: 600,
                              max: 800,
                            }),
                            alignSelf: "center",
                          }}
                        >
                          {children}
                        </div>
                      </div>
                    )}
                    renderThumb={({ props, isDragged }) => (
                      <div
                        {...props}
                        style={{
                          ...props.style,
                          height: "20px",
                          width: "20px",
                          borderRadius: "50%",
                          backgroundColor: "#075985",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          boxShadow: "0px 2px 6px #AAA",
                        }}
                      ></div>
                    )}
                  />
                </div>
                <div className="flex flex-row space-x-2">
                  <b>{width}</b> px <p className="mt-1 ">*</p>{" "}
                  <b>{height.toFixed(2)}</b> px
                </div>
              </div>

              <div className="mt-20 w-full lg:w-1/6 ">
                <Button
                  text="saveChanges"
                  onClick={() => {
                    setOpenModal(true);
                  }}
                />
              </div>
            </div>
            <SettingsPeriods />
            <SettingsCategories />
            <SettingsMaterials />
            <SettingsType />
          </>
        )}
      </div>
    </div>
  );
};

export default SettingsPage;
