/* eslint-disable jsx-a11y/img-redundant-alt */
import React from "react";
import { useTranslation } from "react-i18next";
import ImageUploading from "react-images-uploading";
import { IoClose } from "react-icons/io5";

const FileUpload = ({ images, setImages, multiple }) => {
  const { t } = useTranslation();

  const saveImages = (imageList) => {
    setImages(imageList);
  };

  return (
    <ImageUploading
      multiple={multiple}
      value={images}
      onChange={saveImages}
      dataURLKey="data_url"
    >
      {({ imageList, onImageUpload, onImageRemove, dragProps }) => (
        <div
          className={`w-full h-full ${
            images.length === 0 && "border-2 border-gray-300"
          } border-dashed rounded-md px-2`}
        >
          <div>
            {!images.length > 0 && (
              <div className="py-6" onClick={onImageUpload} {...dragProps}>
                <svg
                  className="mx-auto h-12 w-12 text-gray-400"
                  stroke="currentColor"
                  fill="none"
                  viewBox="0 0 48 48"
                  aria-hidden="true"
                >
                  <path
                    d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                    strokeWidth={2}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                <div className="flex justify-center text-sm text-gray-600">
                  <label className="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none">
                    <p>{t("uploadFile")}</p>
                  </label>
                </div>
                <p className="text-xs text-gray-500 text-center">
                  {t("fileType")}
                </p>
              </div>
            )}
            <div className="grid xl:grid-cols-4 md:grid-cols-2 gap-3 w-full justify-items-stretch">
              {imageList.map((image, index) => {
                return (
                  <div
                    className="w-full h-40 flex justify-center items-center relative"
                    key={index}
                  >
                    <img
                      src={image["data_url"]}
                      alt="image"
                      className="max-h-full"
                    />
                    <div
                      className="absolute top-0 right-0 flex w-8 h-8 rounded-full cursor-pointer bg-gray-400 items-center justify-center"
                      onClick={() => onImageRemove(index)}
                    >
                      <IoClose color="black" className="w-5 h-5" />
                    </div>
                  </div>
                );
              })}
              {imageList.length > 0 && (
                <div
                  className="w-full h-40 flex flex-col justify-center border-2 border-gray-300  border-dashed  rounded-md px-2 jusitfy-center items-center cursor-pointer"
                  onClick={onImageUpload}
                  {...dragProps}
                >
                  <svg
                    className="mx-auto h-12 w-12 text-gray-400"
                    stroke="currentColor"
                    fill="none"
                    viewBox="0 0 48 48"
                    aria-hidden="true"
                  >
                    <path
                      d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                      strokeWidth={2}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  <div className="flex justify-center text-sm text-gray-600">
                    <label className="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none">
                      <p>{t("uploadFile")}</p>
                    </label>
                  </div>
                  <p className="text-xs text-gray-500 text-center">
                    {t("fileType")}
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </ImageUploading>
  );
};

export default FileUpload;
