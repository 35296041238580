/* eslint-disable no-dupe-keys */
/* eslint-disable import/no-anonymous-default-export */
export default {
  /* SIDEBAR */

  home: "Početna",
  collections: "Zbirke",
  settings: "Postavke",
  analytics: "Analitika",
  itemsAddedPastCurrentYear:
    "Broj predmeta dodatih u sadašnjoj i prethodnoj godini",
  /* HEADER */
  search: "Pretraga...",
  searchCollections: "Pretraži zbirke",
  searchSubcollections: "Pretraži podzbirke",
  searchItems: "Pretraži predmete",
  logOut: "Odjavi se",
  logIn: "Prijavi se",
  agree: "Slažem se",
  policyMsg:
    "Svi materijali predstavljeni na digitalnoj platformi Zavičajnog muzeja Visoko, uključujući tekstove, slike, ilustracije, crteže, fotografije i ostalo, vlasništvo su Zavičajnog muzeja Visoko. Ponuđeni resursi dostupni su za vlastite, naučno-istraživačke ili edukativne potrebe poštujući izvore, odnosno autorska prava, i ne smiju se koristiti u komercijalne svrhe bez pismene saglasnosti Zavičajnog muzeja Visoko.",
  policyEmail: "Za dodatne informacije obratiti se na:",
  /* DASHBOARD */
  allCollections: "Sve zbirke",

  /* RIGHT SIDEBAR */
  addNewItem: "Dodaj novi predmet",
  addNewCollection: "Dodaj novu zbirku",
  filter: "Filtriranje",
  collection: "Zbirka",
  preservation: "Očuvanost",

  /* ADD NEW ITEM PAGE */
  generalInfo: "Opće informacije",
  multimedia: "Multimedija",
  generalSpecification: "Osnovne specifikacije predmeta",
  picsSkechVideo: "Slike, skice i videozapisi",
  itemName: "Naziv predmeta",
  museumInformation: "Muzejske informacije",
  invItemNumber: "Inv. broj predmeta",
  entryDate: "Datum upisa",
  accommodation: "Smještaj",
  itemDescription: "Opis predmeta",
  dimension: "Dimenzije",
  material: "Materijal",
  whenAndHowFound: "Kada i kako je nabavljen?",
  whereFound: "Gdje je nabavljen?",
  origin: "Porijeklo",
  restauration: "Konzervatorsko/restauratski zahvati",
  note: "Napomena",
  gallery: "Galerija",
  saySomethinBoutItem: "Napišite nekoliko rečenica o predmetu.",
  addImages: "Dodaj slike predmeta",
  addMainImage: "Naslovna slika predmeta",
  uploadFile: "Odaberi datoteku",
  fileType: "PNG, JPG, GIF do 10MB",
  addNew: "Dodaj novu",
  saveImages: "Spremi slike",
  chooseHead: "Odaberite naslovnu sliku",
  uploadingPhotos: "Dodavanje slika...",

  /* ITEM EDIT MODE */
  collectionName: "Naziv zbirke",

  /* LOGIN PAGE */
  logIn: "Prijavi se",
  emptyField: "Polje ne može biti prazno!",
  logInError: "Trenutno je neko prijavljen na ovaj račun!",
  logInErrorDesc: "Želite li ih odjaviti? ",

  /* BUTTONS */
  saveItem: "Spremi predmet",
  editItem: "Uredi predmet",
  deleteItem: "Obriši predmet",
  downloadPDF: "Ispiši predmet",
  downloadPDFs: "Ispiši predmete",
  preparingDownloadPDF: "Priprema pdf-a...",
  cancel: "Odustani",
  cancelTwo: "Poništi",
  goBack: "Vrati se",
  saveChanges: "Spremi promjene",
  doYouWannaDownload: "Da li želite ispisati predmet",
  withPhotos: "Sa fotografijama?",
  withoutPhotos: "Bez fotografija?",

  /*SETTINGS PAGE*/
  appAvailable: "Aplikacija dostupna za javnost",
  visibilityText:
    "Opcija omogućava zatvaranje platforme za javnost radi održavanja platforme ili postavljannja postavki/zbirki.",
  imageDimensions: "Dimenzije slika",
  chooseDimensions:
    "Odaberite odgovarajuću dimenziju slika dodanih u galeriju predmeta.",
  maxPhotoSize: "Maksimalna veličina slike je 25MB.",
  sureToLeaveChanges: "Da li ste sigurni da želite spremiti promjene?",
  canChange: "Postavke možete promijeniti kada god to želite!",
  successChanges: "Promjene uspješno spremljenje!",
  succesPhotoChange: "Nova naslovna slika izabrana!",
  successPhotoSubtitle: "Spremi promjene da bi slika bila promijenjena.",
  /* PERIODS */
  periodTitle: "Historijska doba/periodi",
  periodSubtitle:
    "Dodajte historijske periode koji će biti dodijeljeni predmetima. Doba/periode je moguće dodavati i brisati.",
  addNewPeriod: "Dodaj novo doba",
  addPeriod: "Dodaj doba",
  periodName: "Naziv doba",
  period: "Doba",
  additionalInfo: "Dodatne infromacije",
  /* CATEGORIES */
  categories: "Kategorije",
  categorySing: "Kategorija",
  categoriesSubtitle:
    "Dodajte kategorije koje će biti dodijeljene predmetima. Kategorije je moguće dodavati i brisati.",
  categoryName: "Naziv kategorije",
  addCategory: "Dodaj kategoriju",
  addNewCategory: "Dodaj novu kategoriju",
  /* MATERIALS */
  materials: "Materijali",
  materialsSubtitle:
    "Dodajte materijale koji će biti dodijeljeni predmetima. Materijale je moguće dodavati i brisati.",
  materialName: "Naziv materijala",
  addMaterial: "Dodaj materijal",
  addNewMaterial: "Dodaj novi materijal",
  /* TYPES */
  types: "Vrste",
  typeSing: "Vrsta",
  typesSubtitle:
    "Dodajte vrste koje će biti dodijeljeni predmetima. Vrste je moguće dodavati i brisati.",
  typesName: "Naziv vrste",
  addType: "Dodaj vrstu",
  addNewType: "Dodaj novu vrstu",

  /* ERROR MESSAGES */
  fillAllFields: "Molimo popunite sva polja!",
  "Wrong credential": "Šifra ili email nisu tačni!",
  chooseImages: "Molimo da odaberete minimalno jednu sliku!",

  /* ALERT MESSAGES */
  successItemSave: "Uspješno ste dodali predmet",
  successItemSaveAddition:
    "Nastavite sa dodavanjem novih predmeta ili pregledavanjem postojećih.",
  successCollectionSave: "Zbirka je uspješno spremljena! ",

  /*COLLECTION PAGE */
  collectionId: "ID ZBIRKE",
  category: "ZBIRKA",
  numOfItem: "BROJ PREDMETA",
  more: "Više",
  noItems: "Nema stavki",
  delete: "Obriši",
  countItem: "Broj predmeta",
  subCollectionCount: "Broj podzbirki",
  done: "Potvrdi izmjene",
  addNewSub: "Dodaj novu podzbirku",
  collectionPDF: "Ispiši zbirke",
  collAndSub: "Zbirke i podzbirke",
  /* SUB COLLECTIONS PAGE */
  subCollection: "Podzbirke",
  subCollectionId: "ID PODZBIRKE",
  subCollectionName: "Naziv podzbirke",
  edit: "Uredi",

  /* ADD COLLECTION PAGE */
  addCollection: "Dodaj novu zbirku",
  saveCollection: "Spremi zbirku",

  /*INFO MODAL */
  sureToProceed: "Da li ste sigurni da želite nastaviti?",
  continue: "Nastavi",
  sureToDelete: "Da li ste sigurni da želite obrisati?",
  sureToDeleteCollection: "Da li ste sigurni da želite obrisati zbirku",
  sureToDeleteSubcollection: "Da li ste sigurni da želite obrisati podzbirku ",
  delete: "Obriši",
  ifDelete:
    "Ukoliko obrišete stavku, nećete biti u mogućnosti vratiti je ponovno!",
  ifDeleteCollection:
    "Ukoliko obrišete zbirku, sve što je vezano za zbirku biti će obrisano i nećete biti u mogućnosti vratiti je ponovno!",
  ifDeleteSubcollection:
    "Ukoliko obrišete podzbirku, sve što je vezano za podzbirku biti će obrisano i nećete biti u mogućnosti vratiti je ponovno!",
  /*ADD SUBCATEGORY PAGE */
  addSubCategory: "Dodaj podzbirku",
  subCategoryName: "Naziv podzbirke",
  add: "Dodaj",
  addItems: "Nastavi na dodavanje predmeta",
  backToHome: "Nazad na zbirke",

  /*ITEMS PAGE*/
  items: "Predmeti",
  allItems: "Svi predmeti",
  seeMore: "Vidi više",

  /* MONTHS */
  january: "Januar",
  february: "Februar",
  march: "Mart",
  april: "April",
  may: "Maj",
  juny: "Juni",
  july: "Juli",
  august: "August",
  september: "Septembar",
  october: "Oktobar",
  november: "Novembar",
  december: "Decembar",

  /* ANALITYCS */
  numOfVisit: "Broj posjeta",
  name: "NAZIV",
  stats: "STATISTIKA",
  logs: "Logovanja",
  newUsers: "Novi korisnici",
  total: "Total",

  /* PAGINATION */
  previous: "Prethodna",
  next: "Sljedeća",
};
