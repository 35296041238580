/* eslint-disable no-dupe-keys */
/* eslint-disable import/no-anonymous-default-export */
export default {
  /* SIDEBAR */

  home: "Home",
  collections: "Collections",
  settings: "Settings",
  analytics: "Analytics",
  itemsAddedPastCurrentYear: "Number of Items added in current and past year",

  /* HEADER */
  search: "Search...",
  searchCollections: "Search collections",
  searchSubcollections: "Search subcollections",
  searchItems: "Search items",
  logOut: "Log out",
  agree: "I agree",
  policyMsg:
    "All materials presented on the digital platform of the Visoko Homeland Museum, including texts, images, illustrations, drawings, photographs and more, are the property of the Visoko Homeland Museum. The offered resources are available for own, scientific-research or educational needs respecting the sources, ie copyrights, and may not be used for commercial purposes without the written consent of the Homeland Museum Visoko.",
  policyEmail: "For additional information contact:",
  logIn: "Log in",
  logInError: "Someone is currently logged in to this account!",
  logInErrorDesc: "Do you want to log them out?",

  /* DASHBOARD */
  allCollections: "All collections",

  /* RIGHT SIDEBAR */
  addNewItem: "Add new item",
  addNewCollection: "Add new collection",
  filter: "Filter",
  collection: "Collection",
  preservation: "Preservation",

  /* ADD NEW ITEM PAGE */
  generalInfo: "General info",
  multimedia: "Multimedia",
  generalSpecification: "General item specification",
  picsSkechVideo: "Pictures, sketches and videos",
  itemName: "Item name",
  museumInformation: "Museum information",
  invItemNumber: "Item number",
  entryDate: "Entry date",
  accommodation: "Accommodation",
  itemDescription: "Item description",
  dimension: "Dimensions",
  material: "Material",
  whenAndHowFound: "When and how is it found?",
  whereFound: "Where is it found?",
  origin: "Origin",
  restauration: "Conservation/restoration works",
  note: "Note",
  gallery: "Gallery",
  saySomethinBoutItem: "Write something about the item.",
  addImages: "Add images of the item",
  addMainImage: "Main image of the item",
  uploadFile: "Upload a file",
  fileType: "PNG, JPG, GIF up to 10MB",
  addNew: "Add new",
  saveImages: "Save images",
  chooseHead: "Choose head image for item",
  uploadingPhotos: "Uploading photos...",

  /* ITEM EDIT MODE */
  collectionName: "Collection name",
  /* LOGIN PAGE */
  logIn: "Log in",
  emptyField: "Field cannot be empty!",

  /* BUTTONS */
  saveItem: "Save item",
  editItem: "Edit item",
  deleteItem: "Delete item",
  downloadPDF: "Download PDF",
  downloadPDFs: "Download PDF",
  preparingDownloadPDF: "Preparing pdf-a...",
  doYouWannaDownload: "Do you wanna download PDF",
  withPhotos: "With photos?",
  withoutPhotos: "Without photos?",
  cancel: "Cancel",
  cancelTwo: "Cancel",
  goBack: "Go back",
  saveChanges: "Save changes",

  /*SETTINGS PAGE*/
  appAvailable: "Application available to the public",
  visibilityText:
    "The option allows you to close the platform to the public to maintain the platform or set up settings/collections.",
  imageDimensions: "Image dimensions",
  maxPhotoSize: "Maximum image size is 25MB.",
  chooseDimensions:
    "Select the appropriate dimension of the images added to the item gallery.",
  sureToLeaveChanges: "Are you sure you want to save these changes?",
  canChange: "You can change these settings whenever you want!",
  successChanges: "Settings successfully changed!",
  succesPhotoChange: "New photo selected!",
  successPhotoSubtitle: "Save changes so photo can be saved.",
  /* PERIODS */
  periodTitle: "Historical age/period",
  periodSubtitle:
    "Add historical periods to be assigned to items. Age / periods can be added and deleted.",
  addNewPeriod: "Add new period",
  addPeriod: "Add period",
  periodName: "Period name",
  period: "Period",
  additionalInfo: "Additional information",
  /* CATEGORIES */
  categories: "Categories",
  categorySing: "Category",
  categoriesSubtitle:
    "Add categories to be assigned to items. Categories can be added and deleted",
  categoryName: "Category name",
  addCategory: "Add category",
  addNewCategory: "Add new category",
  /* MATERIALS */
  materials: "Materials",
  materialsSubtitle:
    "Add materials to be assigned to items. Materials can be added and deleted",
  materialName: "Material name",
  addMaterial: "Add material",
  addNewMaterial: "Add new material",
  /* TYPES */
  types: "Types",
  typeSing: "Type",
  typesSubtitle:
    "Add types to be assigned to items. Types can be added and deleted",
  typesName: "Type name",
  addType: "Add type",
  addNewType: "Add new type",

  /* ERROR MESSAGES */
  fillAllFields: "Please, fill in all fields!",
  "Wrong credential": "Password and email are incorrect",
  chooseImages: "Please, choose at least one picture!",

  /* ALERT MESSAGES */
  successItemSave: "Item has been successfully saved",
  successItemSaveAddition:
    "Continue adding new items or reviewing existing items.",
  successCollectionSave: "Collection has been successfully saved!",
  collAndSub: "Collections and subcollections",
  /*COLLECTION PAGE */
  collectionId: "COLLECTION ID",
  category: "CATEGORY",
  numOfItem: "NUM. OF ITEMS",
  more: "More",
  noItems: "No items found",
  delete: "Delete",
  countItem: "Number of items",
  subCollectionCount: "Number of subcategory",
  done: "Confirm changes",
  addNewSub: "Add new subcategory",
  collectionPDF: "Download collections",

  /* SUB COLLECTIONS PAGE */
  subCollection: "Subcategories",
  subCollectionId: "SUBCATEGORY ID",
  subCollectionName: "Subcategory name",
  edit: "Edit",

  /* ADD COLLECTION PAGE */
  addCollection: "Add new collection",
  saveCollection: "Save collection",

  /*INFO MODAL */
  sureToProceed: "Are you sure you want to proceed with this?",
  continue: "Continue",
  sureToDelete: "Are you sure you want to delete this",
  sureToDeleteCollection: "Are you sure you want to delete collection",
  sureToDeleteSubcollection: "Are you sure you want to delete subcollection ",
  delete: "Delete",
  ifDelete: "If you delete item, you will not be able to retrieve it again!",
  ifDeleteCollection:
    "If you delete collection, you will delete everything connected with it and you would not be able to retrieve it again!",
  ifDeleteSubcollection:
    "If you delete subcollection, you will delete everything connected with it and you would not be able to retrieve it again!",
  /*ADD SUBCATEGORY PAGE */
  addSubCategory: "Add subcategory",
  subCategoryName: "Subcategory name",
  add: "Add",
  addItems: "Continue on adding items",
  backToHome: "Back to collections",

  /*ITEMS PAGE*/
  items: "Items",
  allItems: "All items",
  seeMore: "See more",

  /* ANALITYCS */
  numOfVisit: "Number of visits",
  name: "NAME",
  stats: "STATISCTICS",
  logs: "Logs",
  newUsers: "New users",
  total: "Total",

  /* PAGINATION */
  previous: "Previous",
  next: "Next",
};
