/* eslint-disable no-unused-vars */
/* eslint-disable import/no-anonymous-default-export */
import React, { useState } from "react";

import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";

import { Modal } from "react-responsive-modal";
import "react-responsive-modal/styles.css";

import { Text } from "components";
import apiRequest from "helpers/apiRequest";
import ErrorAlert from "components/ErrorAlert";

const AddCategoryModal = ({ open, onClose }) => {
  const { t } = useTranslation();
  const history = useHistory();

  const [category, setCategory] = useState({
    value: "",
    invalidName: false,
    additionalInfo: "",
  });
  const [error, setError] = useState(null);

  const saveCategory = async () => {
    try {
      const response = await apiRequest({
        method: "post",
        url: "category",
        data: {
          name: category.value,
          additionalInfo: category.additionalInfo,
        },
      });
      if (response.status === 200) {
        setCategory({
          value: "",
          invalidName: false,
          additionalInfo: "",
        });
        onClose();
      }
    } catch (err) {
      if (err?.response?.status === 401) {
        history.push("/auth/login");
      }
      createError(err?.response?.data?.error);
    }
  };

  const checkText = () => {
    const data = { ...category };
    if (data.value === "") {
      data.invalidName = true;
      setCategory({ ...data });
    } else {
      saveCategory();
    }
  };
  const createError = (msg = "fillAllFields") => {
    setError(<ErrorAlert errorText={msg} />);
    setTimeout(() => {
      setError(null);
    }, 3000);
  };
  const clear = () => {
    setCategory(null);
  };

  return (
    <Modal open={open} onClose={onClose}>
      <div className="w-full   p-5 relative mx-auto my-auto ">
        {error}
        <div className="w-full ">
          <div className="mb-1">
            <Text>{t("categoryName")}</Text>
          </div>
          <div className="flex ">
            <div className="h-10 w-full">
              <input
                className={`h-full w-full px-5 rounded-lg border ${
                  category.invalidName && "border-2 border-red"
                }`}
                value={
                  category.value.charAt(0).toUpperCase() +
                  category.value.slice(1)
                }
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    checkText();
                  }
                }}
                onChange={(e) =>
                  setCategory({
                    ...category,
                    value: e.target.value,
                    invalidName: false,
                  })
                }
              />
            </div>
          </div>
        </div>
        <div className="w-full mt-4 ">
          <div className="mb-1">
            <Text>{t("additionalInfo")}</Text>
          </div>
          <div className="flex ">
            <div className="h-10 w-full">
              <input
                className={`h-full w-full px-5 rounded-lg border`}
                value={
                  category.additionalInfo.charAt(0).toUpperCase() +
                  category.additionalInfo.slice(1)
                }
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    checkText();
                  }
                }}
                onChange={(e) =>
                  setCategory({
                    ...category,
                    additionalInfo: e.target.value,
                  })
                }
              />
            </div>
          </div>
        </div>
        <div className="">
          <div className="p-3  mt-2 text-center space-x-4 md:block">
            <button
              className="mb-2 md:mb-0 bg-white px-5 py-2 text-sm shadow-sm font-medium tracking-wider border text-gray-600 rounded-lg hover:shadow-lg hover:bg-gray-100"
              onClick={onClose}
            >
              {t("cancel")}
            </button>
            <button
              className="mb-2 md:mb-0 bg-white px-5 py-2 text-sm shadow-sm font-medium tracking-wider border border-blue-primary text-blue-primary rounded-lg hover:shadow-lg hover:bg-gray-100"
              onClick={checkText}
            >
              {t("addCategory")}
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default AddCategoryModal;
