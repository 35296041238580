/* eslint-disable import/no-anonymous-default-export */
import { setInitialConfig } from './initialConfig'

const APP_API_URL = process.env.REACT_APP_APP_API_URL
const REACT_APP_TRACKING_ID = process.env.REACT_APP_TRACKING_ID

export default {
  setInitialConfig,
  APP_API_URL,
  REACT_APP_TRACKING_ID
}
