import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  Font,
} from "@react-pdf/renderer";
import moment from "moment";
import { Html } from "react-pdf-html";
import WhiteBlock from "../assets/background.jpg";

import font from "../pdfFonts/fonts/Mulish-Bold.ttf";
Font.register({
  family: "Mulish-Bold",
  src: font,
  fontStyle: "normal",
  fontWeight: "normal",
});

const FullField = ({ info, name }) => {
  const { t } = useTranslation();

  return (
    <View style={styles.fullField}>
      <View style={styles.fieldNameContainer}>
        <Text style={styles.fieldName}>{t(name)}</Text>
      </View>
      <View style={styles.fieldInfoContainer}>
        <Text style={styles.fieldInfo}>{info}</Text>
      </View>
    </View>
  );
};
const Note = ({ info, name, html }) => {
  const { t } = useTranslation();
  return (
    <View style={styles.fullField}>
      <View style={styles.fieldNameContainer}>
        <Text style={styles.fieldName}>{t(name)}</Text>
      </View>
      <View style={styles.fieldInfoContainer}>
        <Html style={{ fontSize: 12 }}>{`<html>${html}</html>`}</Html>
      </View>
    </View>
  );
};

const MyDocument = ({ item, photos, without, imageUrl }) => {
  const { t } = useTranslation();
  const html = item?.note;
  const imageUri = item.url;

  return (
    <Document>
      <Page size="A4" wrap style={styles.page}>
        <View style={styles.imageNameContainer}>
          <View style={styles.nameContainer}>
            <Text style={styles.itemName}>{item?.name}</Text>
            <Text style={styles.collection}>{item?.collection.name}</Text>
          </View>

          <View style={styles.imageContainer}>
            {!without ? (
              <Image src={item?.url} />
            ) : (
              <Image src={WhiteBlock} style={{ width: 0.1, height: 0.1 }} />
            )}
          </View>
        </View>
        <View
          style={{
            borderWidth: 1,
            borderColor: "#D1D5DB",
            width: "100%",
            padding: 5,
            marginTop: 5,
          }}
        >
          <Text>{t("itemDescription")}</Text>
          {item?.description !== null && item?.description !== "" && (
            <Text style={styles.description}>{item?.description}</Text>
          )}
        </View>
        <View style={styles.about1}>
          {item?.dimension !== null && item?.dimension !== "" && (
            <FullField info={item?.dimension} name="dimension" />
          )}
          {item?.preservation !== null && item?.preservation !== "" && (
            <FullField info={item?.preservation} name="preservation" />
          )}
          {item?.origin !== "" && item?.origin !== null && (
            <FullField info={item?.origin} name="origin" />
          )}
          {item?.how_made !== null && item?.how_made !== "" && (
            <FullField info={item?.how_made} name="whenAndHowFound" />
          )}
          {item?.where_made !== "" && item?.where_made !== null && (
            <FullField info={item?.where_made} name="whereFound" />
          )}
          {item?.ageId !== null && item?.ageId !== "" && (
            <FullField name="period" info={item?.age?.name} />
          )}
          {item?.categoryId !== null && item?.categoryId !== "" && (
            <FullField name="categorySing" info={item?.category?.name} />
          )}
          {item?.materialId !== null && item?.materialId !== "" && (
            <FullField name="material" info={item?.Material?.name} />
          )}
          {item?.speciesId !== null && item?.speciesId !== "" && (
            <FullField name="typeSing" info={item?.species?.name} />
          )}
          <FullField info={item?.invNumber} name="invItemNumber" />

          {item?.date_enrollment !== "" && item?.date_enrollment !== null && (
            <FullField
              info={moment(item?.date_enrollment).format("DD.MM.YYYY")}
              name="entryDate"
            />
          )}
          {item?.accommodation !== null && item?.accommodation !== "" && (
            <FullField info={item?.accommodation} name="accommodation" />
          )}
          {item?.restauration !== "" && item?.restauration !== null && (
            <FullField info={item?.restauration} name="restauration" />
          )}
          {item?.note !== "" && item?.note !== null && (
            <Note info={item?.note} html={html} name="note" />
          )}
        </View>
      </Page>
      {!without && (
        <Page size="A4" wrap style={styles.page}>
          <View
            style={{
              marginTop: 15,
              flexDirection: "row",
              justifyContent: "space-between",
              flexWrap: "wrap",
            }}
          >
            {!without &&
              photos?.map((item, id) => (
                <Image
                  key={id}
                  src={item?.url}
                  style={{ marginTop: 15, width: "48%" }}
                />
              ))}
          </View>
        </Page>
      )}
    </Document>
  );
};

const styles = StyleSheet.create({
  page: {
    backgroundColor: "#FFFFFF",
    padding: 15,

    paddingBottom: 15,
  },
  itemName: {
    fontSize: 24,
    fontFamily: "Mulish-Bold",
  },
  imageNameContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: 15,
  },
  imageContainer: {
    width: "40%",
  },
  nameContainer: {
    width: "50%",
    marginLeft: 10,
  },
  collection: {
    color: "#0369A1",
    fontSize: 15,
    fontFamily: "Mulish-Bold",
  },
  description: {
    fontSize: 12,
    marginTop: 10,
    fontFamily: "Mulish-Bold",
  },
  about1: {
    marginTop: 20,
    display: "flex",
    width: "100%",
    borderWidth: 1,
    borderColor: "#D1D5DB",
    borderBottom: "none",
  },
  fieldName: {
    color: "#000",
    fontSize: 12,
    fontFamily: "Mulish-Bold",
  },
  fieldNameContainer: {
    width: "50%",
    height: "100%",
    borderRightWidth: 1,
    borderRightColor: "#D1D5DB",
    justifyContent: "center",
  },
  fieldInfo: {
    color: "#000",
    fontSize: 12,
    fontFamily: "Mulish-Bold",
  },
  fieldInfoContainer: {
    width: "50%",
    height: "100%",
    justifyContent: "center",
    paddingHorizontal: 4,
  },
  fullField: {
    width: "100%",
    borderBottomWidth: 1,
    borderBottomColor: "#D1D5DB",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    minHeight: 30,
    paddingHorizontal: 4,
  },
});
export default MyDocument;
