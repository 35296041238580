/* eslint-disable no-unused-vars */
/* eslint-disable import/no-anonymous-default-export */
import React, { useState } from "react";

import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";

import { Modal } from "react-responsive-modal";
import "react-responsive-modal/styles.css";

import { Text } from "components";
import apiRequest from "helpers/apiRequest";
import ErrorAlert from "components/ErrorAlert";

const AddSubcategoryModal = ({ open, collectionId, onClose }) => {
  const { t } = useTranslation();
  const history = useHistory();

  const [collectionData, setCollectionData] = useState([]);
  const [collection, setCollection] = useState({
    value: "",
    invalid: false,
  });
  const [error, setError] = useState(null);

  const getSubCollections = async () => {
    try {
      const response = await apiRequest({
        method: "get",
        url: `collection/list/${collectionId}`,
      });
      if (response.status === 200) {
        setCollectionData([...response.data.files]);
      }
    } catch (err) {
      if (err?.response?.status === 401) {
        history.push("/auth/login");
      }
      console.log(err);
    }
  };

  const checkText = () => {
    const data = { ...collection };
    if (data.value === "") {
      data.invalid = true;
      setCollection({ ...data });
    } else {
      saveCollection();
    }
  };
  const createError = (msg = "fillAllFields") => {
    setError(<ErrorAlert errorText={msg} />);
    setTimeout(() => {
      setError(null);
    }, 3000);
  };

  const saveCollection = async () => {
    try {
      const response = await apiRequest({
        method: "post",
        url: "subcollection",
        data: {
          collectionId: collectionId,
          name: collection.value,
        },
      });
      if (response.status === 200) {
        setCollection({ value: "", invalid: false });
        getSubCollections();
        onClose();
      }
    } catch (err) {
      if (err?.response?.status === 401) {
        history.push("/auth/login");
      }
      createError(err.response.data.error);
    }
  };

  return (
    <Modal open={open} onClose={onClose}>
      <div className="w-full   p-5 relative mx-auto my-auto bg-white ">
        {error}
        <div className="w-full ">
          <div className="mb-1">
            <Text>{t("addSubCategory")}</Text>
          </div>
          <div className="flex ">
            <div className="h-10 w-full">
              <input
                className={`h-full w-full px-5 rounded-lg border ${
                  collection.invalid && "border-2 border-red"
                }`}
                value={
                  collection.value.charAt(0).toUpperCase() +
                  collection.value.slice(1)
                }
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    checkText();
                  }
                }}
                onChange={(e) =>
                  setCollection({
                    ...collection,
                    value: e.target.value,
                    invalid: false,
                  })
                }
              />
            </div>
          </div>
        </div>
        <div className="">
          <div className="p-3  mt-2 text-center space-x-4 md:block">
            <button
              className="mb-2 md:mb-0 bg-white px-5 py-2 text-sm shadow-sm font-medium tracking-wider border border-blue-primary text-blue-primary rounded-lg hover:shadow-lg hover:bg-gray-100"
              onClick={checkText}
            >
              {t("addSubCategory")}
            </button>
            <button
              className="mb-2 md:mb-0 bg-white px-5 py-2 text-sm shadow-sm font-medium tracking-wider border text-gray-600 rounded-lg hover:shadow-lg hover:bg-gray-100"
              onClick={onClose}
            >
              {t("cancel")}
            </button>
            {/* <button className="mb-2 md:mb-0 bg-red border border-red-500 px-5 py-2 text-sm shadow-sm font-medium tracking-wider text-white rounded-full hover:shadow-lg hover:bg-red-600" onClick={onConfirm}>{t(confirmButtonText)}</button> */}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default AddSubcategoryModal;
