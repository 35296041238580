import React from "react";
import { useTranslation } from "react-i18next";

const ErrorAlert = ({ errorText }) => {
  const { t } = useTranslation();

  return (
    <div
    className="bg-red-light border-l-4 border-red text-white p-4  absolute top-5 right-0 min-w-1/4 z-10"
      role="alert"
    >
      <p className="font-bold">Error</p>
      <p>{t(errorText)}</p>
    </div>
  );
};

export default ErrorAlert;
