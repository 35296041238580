/* eslint-disable react-hooks/exhaustive-deps */
/*Here we will implement all logic for Login and Register, and when user is logged in, we will redirect him to Admin layout
where he will be able to get access to dashboard */
import React, { useState, useEffect } from "react";
import { Switch, Route, Redirect, useHistory } from "react-router-dom";
import { Sidebar, Header } from "containers";
import Config from "../helpers/index";

import jwt_decode from "jwt-decode";
import apiRequest from "helpers/apiRequest";
import Loader from "components/Loader";
import ErrorAlert from "components/ErrorAlert";
import routes from "../routes";

import translationBa from "languages/_ba";
import translationEn from "languages/_en";

import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import { useSelector } from "react-redux";
import ReactGA from "react-ga";

const Admin = () => {
  const language = useSelector((state) => state.language.selectedLanguage);
  const isErrorOpen = useSelector((state) => state.error.isOpen);
  const errorMessage = useSelector((state) => state.error.message);

  const history = useHistory();

  const [redirect, setRedirect] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [closeLanguageDropdown, setCloseLanguageDropdown] = useState(false);
  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/admin") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };

  i18next.use(initReactI18next).init({
    resources: {
      en: { translation: translationEn },
      ba: { translation: translationBa },
    },
    lng: language,
    fallbackLng: "ba",
    interpolation: { escapeValue: false },
  });

  const checkTokenValidity = () => {
    const token = localStorage.getItem("userToken");
    if (token) {
      let decodedToken = jwt_decode(token);
      let currentDate = new Date();

      // JWT exp is in seconds
      if (decodedToken.exp * 1000 < currentDate.getTime()) {
        //if token expired
        localStorage.removeItem("userToken");
        window.location.reload();
        // setRedirect(<Redirect to='/admin/collections'/>)
      } else {
        //setRedirect(<Redirect to="/admin/analytics" />);
        setIsLoaded(true);
      }
    } else {
      checkVisibility();
    }
  };

  const checkVisibility = async () => {
    try {
      const response = await apiRequest({
        method: "get",
        url: "visibility",
      });
      if (response.status === 200) {
        if (localStorage.getItem("userToken") || response.data.visibility)
          return setIsLoaded(true);
        return setRedirect(<Redirect to="/auth/login" />);
      }
    } catch (err) {
      if (err?.response?.status === 401) {
        history.push("/auth/login");
      }
      console.log(err);
    }
  };
  useEffect(() => {
    checkTokenValidity();
    // const buttonEl = document.getElementById("languageDropdown");
    // console.log("button el", buttonEl);
    // buttonEl.addEventListener("click", () => alert("Hi user!"));
    // ReactGA.initialize(Config.REACT_APP_TRACKING_ID);
    // window.addEventListener("click", (e) => {
    //   console.log(e.target);
    // });
    //ReactGA.pageview(window.location.pathname + window.location.search);
    // console.log('PATH', window.location.pathname)

    window.addEventListener("click", (e) => {
      if (
        e.target.nodeName !== "BUTTON" &&
        e.target.nodeName !== "svg" &&
        e.target.nodeName !== "img"
      ) {
        //console.log("OVO JE ID", e.target.id);
        setCloseLanguageDropdown(true);
      }
    });
  }, []);

  return (
    <div className="flex row">
      {!isLoaded && (
        <div className="h-full w-full absolute bg-white z-10 flex justify-center items-center">
          <Loader />
        </div>
      )}
      {redirect}
      <div id="sidebar" className="hidden w-1/5 sm:block">
        <Sidebar />
      </div>

      <div className="flex-col w-full h-screen overflow-hidden relative">
        {isErrorOpen && <ErrorAlert errorText={errorMessage} />}
        <Header />
        <Switch>{getRoutes(routes)}</Switch>
      </div>
    </div>
  );
};

export default Admin;
