import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { TextInput, Button, Alert } from "components";
import apiRequest from "helpers/apiRequest";
import ConfimationModal from "components/InfoModal";
import ErrorAlert from "components/ErrorAlert";

const AddCollection = () => {
  const [showAlert, setShowAlert] = useState(false);
  const [confirmationModal, setConfrimationModal] = useState(false);
  const [collectionName, setCollectionName] = useState({
    value: "",
    invalid: false,
  });
  const history = useHistory();
  const { t } = useTranslation();
  const [error, setError] = useState(null);

  const createError = (msg = "fillAllFields") => {
    setError(<ErrorAlert errorText={msg} />);
    setTimeout(() => {
      setError(null);
    }, 3000);
  };

  const saveCollection = async () => {
    setConfrimationModal(null);
    try {
      const response = await apiRequest({
        method: "post",
        url: "collection",
        data: {
          name: collectionName.value,
        },
      });
      if (response.status === 200) {
        history.push(`/admin/addSubCollection/${response.data.id}`);
      }
    } catch (err) {
      if (err?.response?.status === 401) {
        history.push("/auth/login");
      }
      createError(err.response.data.error);
    }
  };

  const checkValid = () => {
    if (collectionName.value === "")
      return setCollectionName({ ...collectionName, invalid: true });
    return setConfrimationModal(true);
  };

  return (
    <div className="bg-blue-100 h-screen w-full pt-5 pb-5 overflow-hidden">
      <ConfimationModal
        open={confirmationModal}
        confirmButtonText="continue"
        confirmationDesc="sureToProceed"
        onClose={() => setConfrimationModal(false)}
        onConfirm={saveCollection}
        onCancel={() => setConfrimationModal(false)}
      />
      {error}
      <div className=" bg-white rounded mx-5 h-screen px-10 pt-5 pb-12">
        <div className="h-20 lg:w-3/4 flex row justify-between items-center border-b-2 mb-16">
          <h2 className="text-2xl md:text-4xl font-inter  lg:w-1/2 ">
            {t("addCollection")}
          </h2>
        </div>
        <div className="lg:w-1/2">
          <div className="mt-10 mb-10">
            <TextInput
              title="addCollection"
              placeholder="collectionName"
              value={collectionName.value}
              onEnter={checkValid}
              invalid={collectionName.invalid}
              onChange={(e) => {
                setCollectionName({
                  ...collectionName,
                  value: e.target.value,
                  invalid: false,
                });
              }}
            />
            {collectionName.invalid && (
              <p className="text-red font-bold text-xs mb-5 ml-2">
                {t("emptyField")}
              </p>
            )}
          </div>
        </div>
        <div className="h-20 lg:w-3/4 flex row justify-end items-center border-t-2 mt-16">
          <Button
            text="cancel"
            invert
            onClick={() => history.push("/admin/collections")}
          />
          <div className="ml-3">
            <Button
              text="saveCollection"
              onClick={() => {
                checkValid();
              }}
            />
          </div>
        </div>
      </div>
      {showAlert ? (
        <div className="w-full flex justify-center">
          <Alert
            text="Zbirka je uspješno spremljena!"
            onClick={() => setShowAlert(false)}
          />
        </div>
      ) : null}
    </div>
  );
};

export default AddCollection;
