/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";

import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { PDFDownloadLink } from "@react-pdf/renderer";
import Config from "../../config/index";

import { RiArrowLeftSLine } from "react-icons/ri";
import { AiOutlineSearch } from "react-icons/ai";

import { AddButton, Loader, Button } from "components";
import { openError, closeError } from "redux/actions/errorAction";
import List from "./components/List";
import apiRequest from "helpers/apiRequest";
import Pagination from "components/Pagination";
import Modal from "components/ConfirmationModal";
import CategoryTablePDF from "components/CategoryTablePDF";
import ReactGA from "react-ga";

const CollectionsPage = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [collections, setCollections] = useState(null);
  const [pagination, setPagination] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [allCollections, setAllCollections] = useState(null);
  const [editColName, setEditColName] = useState("");

  /* Filter */
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(5);
  const [orderBy, setOrderBy] = useState("createdAt");
  const [orderType, setOrderType] = useState("DESC");
  const [openModal, setOpenModal] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);
  const [idNavigationCheck, setIdNavigationCheck] = useState(null);
  const [itemToDeleteName, setItemToDeleteName] = useState("");
  const getAllCollections = async () => {
    try {
      const response = await apiRequest({
        method: "get",
        url: `collection?page=${page}&pageSize=${pageSize}&orderBy=${orderBy}&orderType=${orderType}&search=${searchTerm}`,
      });
      if (response.status === 200) {
        let col = [];
        response.data.files.map((elem) => {
          return col.push({ ...elem, edit: false });
        });
        setCollections([...col]);
        setPagination(response.data);
      }
    } catch (err) {
      if (err?.response?.status === 401) {
        history.push("/auth/login");
      }
      dispatch(openError(err.message));
      setTimeout(() => {
        dispatch(closeError());
      }, 3000);
    }
  };

  const confirmDelete = (elem) => {
    setItemToDelete(elem.id);
    setItemToDeleteName(elem.name);
    setOpenModal(true);
  };
  const getCollectionsWithoutPagination = async () => {
    try {
      const response = await apiRequest({
        method: "get",
        url: `collection?page=${page}&pageSize=${1000000}&orderBy=${orderBy}&orderType=${orderType}`,
      });
      if (response.status === 200) {
        setAllCollections(response.data);
      }
    } catch (err) {
      if (err?.response?.status === 401) {
        history.push("/auth/login");
      }
      dispatch(openError(err.message));
      setTimeout(() => {
        dispatch(closeError());
      }, 3000);
    }
  };

  const deleteItem = async () => {
    setOpenModal(false);
    try {
      const response = await apiRequest({
        method: "delete",
        url: `collection/${itemToDelete}`,
      });
      if (response.status === 200) {
        getAllCollections();
      }
    } catch (err) {
      if (err?.response?.status === 401) {
        history.push("/auth/login");
      }
      dispatch(openError(err.response.data.message || err.message));
      setTimeout(() => {
        dispatch(closeError());
      }, 3000);
    }
  };

  const changePage = (page) => {
    setPage(page.selected + 1);
  };

  const editItem = (e) => {
    let col = [...collections];
    col.forEach((elem) => {
      if (elem.id === e.id) {
        elem.edit = !elem.edit;
        setEditColName(elem.name);
      }
    });
    setCollections([...col]);
  };

  const saveEdit = async (elem) => {
    try {
      const response = await apiRequest({
        method: "put",
        url: `collection/${elem.id}`,
        data: {
          name: editColName,
        },
      });
      if (response.status === 200) {
        editItem(elem);
        getAllCollections();
      }
    } catch (err) {
      if (err?.response?.status === 401) {
        history.push("/auth/login");
      }
      dispatch(openError(err.response.data.message || err.message));
      setTimeout(() => {
        dispatch(closeError());
      }, 3000);
    }
  };
  const getItemsWithoutPagination = async () => {
    try {
      const response = await apiRequest({
        method: "get",
        url: `item`,
      });
      if (response.status === 200) {
        setIdNavigationCheck(response?.data?.files[0]?.id);
      }
    } catch (err) {
      if (err?.response?.status === 401) {
        history.push("/auth/login");
      }
      console.log(err);
    }
  };

  useEffect(() => {
    getAllCollections();
  }, [page, pageSize, orderBy, orderType, searchTerm]);

  useEffect(() => {
    getCollectionsWithoutPagination();
    getItemsWithoutPagination();
    ReactGA.initialize(Config.REACT_APP_TRACKING_ID);
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  useEffect(() => {
    return () => {
      if (history.action === "POP") {
        if (
          history.location.pathname === `/admin/secondStep/${idNavigationCheck}`
        )
          return history.push("/admin/collections");
      }
    };
  }, [history, idNavigationCheck]);

  //console.log("=item to delete==", itemToDelete);
  return (
    <div className="bg-blue-100 h-screen w-full pt-5 pb-5 overflow-hidden">
      <Modal
        open={openModal}
        confirmationText={"sureToDeleteCollection"}
        itemToDelete={itemToDeleteName}
        confirmationDesc={"ifDeleteCollection"}
        confirmButtonText={"delete"}
        onClose={() => setOpenModal(false)}
        onConfirm={() => deleteItem()}
      />
      <div className=" bg-white  rounded mx-5 h-screen overflow-y-auto md:overflow-y-hidden md:h-full lg:px-10 px-3 pt-5 pb-12">
        <div className="lg:h-20 border-b-2 flex flex-col lg:flex-row lg:justify-between lg:items-center ">
          <h2 className="text-4xl font-inter  lg:w-1/2 mb-3 lg:mb-0 ">
            {t("collections")}
          </h2>

          {localStorage.getItem("userToken") && (
            <div className=" lg:w-1/2 h-full flex md:flex-row flex-col md:space-x-5 lg:space-x-0 lg:justify-end lg:items-center  ">
              <div className="lg:mr-12">
                <AddButton
                  text="addNewCollection"
                  invert
                  limitedWidth
                  onClick={() => history.push("/admin/addCollection")}
                />
              </div>
              {allCollections != null ? (
                <PDFDownloadLink
                  document={<CategoryTablePDF data={allCollections.files} />}
                  fileName={`${t("collections")}.pdf`}
                >
                  {({ blob, url, loading, error }) =>
                    loading ? (
                      <div>
                        <Loader small />
                      </div>
                    ) : (
                      <AddButton text="collectionPDF" file limitedWidth />
                    )
                  }
                </PDFDownloadLink>
              ) : (
                <Button text="preparingDownloadPDF" />
              )}
            </div>
          )}
        </div>
        <div className="flex flex-row  w-1/2 h-10 items-center mb-5 mt-5 pl-2 overflow-hidden border rounded-md border-gray-300  ">
          <AiOutlineSearch className="mr-2 w-8 h-6" color="gray" />
          <input
            value={searchTerm.charAt(0).toUpperCase() + searchTerm.slice(1)}
            onChange={(e) => setSearchTerm(e.target.value)}
            placeholder={t("searchCollections")}
            className="w-full h-full focus:outline-none"
          />
        </div>
        {collections && pagination != null ? (
          <div className="sm:h-screen md:h-full overflow-y-auto">
            <List
              collections={collections}
              deleteElem={confirmDelete}
              edit={editItem}
              editColName={editColName}
              setEditColName={setEditColName}
              saveEdit={saveEdit}
            />

            <div className="mt-5">
              <Pagination pages={pagination} changePage={changePage} />
            </div>
          </div>
        ) : (
          <div className="w-full h-full flex justify-center items-center">
            <Loader />
          </div>
        )}
      </div>
    </div>
  );
};

export default CollectionsPage;
