/*Here we will implement all logic for Login and Register, and when user is logged in, we will redirect him to Admin layout
where he will be able to get access to dashboards */
import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import routes from "../routes";

const Auth = () => {

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/auth") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      }else{
        return null
      }
    });
  };

  return (
    <div>
      <div className="wrapper wrapper-full-page">
        <div className="content">
          <Switch>
            {getRoutes(routes)}
            <Route render={() => <Redirect to="/admin/analytics" />} />
          </Switch>
        </div>
        <div
          className="full-page-background"
          style={{
            backgroundColor:
              "linear-gradient(180deg, rgba(0, 153, 131, 0.7) 0%, rgba(102, 194, 181, 0.7) 100%);",
          }}
        />
      </div>
    </div>
  );
};

export default Auth;
