//This file would contain all app routes
import LoginPage from "./views/LoginPage/LoginPage";
import Items from "views/Dashboard/Items";
import CollectionsPage from "./views/CollectionsPage/CollectionsPage";
import SettingsPage from "./views/SettingsPage/SettingsPage";
import UsersPage from "./views/UsersPage/UsersPage";
import AddCollection from "views/AddCollection/AddCollection";
import AddSubCollection from "views/AddSubCollection";
import FirstStepContainer from "views/AddItem/FirstStepContainer";
import SecondStepContainer from "views/AddItem/SecondStepContainer";
import ItemInfo from "views/ItemInfo/ItemInfo";
import CollectionInfo from "views/CollectionInfo/CollectionInfo";
import SubCollections from "views/SubCollections";
import Analitycs from "views/Analitycs";
import AllItemsDashboard from "views/AllItemsDashboard";

const routes = [
  {
    name: "Login",
    path: "/login",
    layout: "/auth",
    exact: true,
    component: LoginPage,
  },
  {
    name: "Analitycs",
    path: "/analytics",
    layout: "/admin",
    exact: true,
    component: Analitycs,
  },
  {
    name: "Items",
    path: "/items/:categoryId/:subCategoryId",
    layout: "/admin",
    exact: true,
    component: Items,
  },
  {
    name: "Collections",
    path: "/collections",
    layout: "/admin",
    exact: true,
    component: CollectionsPage,
  },

  {
    name: "Settings",
    path: "/settings",
    layout: "/admin",
    exact: true,
    component: SettingsPage,
  },
  {
    name: "Users",
    path: "/users",
    layout: "/admin",
    exact: true,
    component: UsersPage,
  },
  {
    name: "AddItem",
    path: "/firstStep/:categoryId/:subCategoryId",
    layout: "/admin",
    exact: true,
    component: FirstStepContainer,
  },
  {
    name: "AddItem",
    path: "/secondStep/:itemId",
    layout: "/admin",
    exact: true,
    component: SecondStepContainer,
  },
  {
    name: "AddCollection",
    path: "/addCollection",
    layout: "/admin",
    exact: true,
    component: AddCollection,
  },
  {
    name: "Add subCollection",
    path: "/addSubCollection/:categoryId",
    layout: "/admin",
    exact: true,
    component: AddSubCollection,
  },
  {
    name: "ItemInfo",
    path: "/itemInfo/:itemId",
    layout: "/admin",
    exact: true,
    component: ItemInfo,
  },
  {
    name: "CollectionInfo",
    path: "/collectionInfo/:collectionId",
    layout: "/admin",
    exact: true,
    component: CollectionInfo,
  },
  {
    name: "Sub Collections",
    path: "/subCollections/:collectionId",
    layout: "/admin",
    exact: true,
    component: SubCollections,
  },
  {
    name: "AllItemsDahsboard",
    path: "/allItems",
    layout: "/admin",
    exact: true,
    component: AllItemsDashboard,
  },
];
export default routes;
