import React from "react";
import { useTranslation } from "react-i18next";
import { Text } from "components";

const InfoContainer = ({ title, info, long }) => {
  const { t } = useTranslation();
  return (
    <div>
      <div className="mb-1">
        <Text>{t(title)}</Text>
      </div>

      <div
        className={` flex  rounded-lg px-5 border overflow-auto ${
          long ? "h-28 py-2" : "h-20 md:h-10 items-center"
        }`}
      >
        {info}
      </div>
    </div>
  );
};

export default InfoContainer;
