import React from 'react'
import { useTranslation } from 'react-i18next'

const Tooltip = ({element, tooltipText}) => {

    const { t } = useTranslation()

    return (
        <div className="tooltip">
            <span className="tooltiptext">{t(tooltipText)}</span>
            <div className='tooltip-child'>{element}</div>
        </div>
    )
}

export default Tooltip
