/* eslint-disable default-case */
import React, { useState } from "react";

import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { TextInput } from "components";
import { FiEdit } from "react-icons/fi";
import { RiDeleteBin6Line } from "react-icons/ri";
import { IoIosArrowForward } from "react-icons/io";
import Tooltip from "components/Tooltip";

import NoItems from "assets/no-items.png";

export const List = ({
  collections,
  deleteElem,
  edit,
  saveEdit,
  editColName,
  setEditColName,
}) => {
  const history = useHistory();
  const { t } = useTranslation();
  const [invalidEdit, setInvalidEdit] = useState(false);

  const goToNext = (elem) => {
    // If collection has any sub collections, we will go to subcollections page, else if collection does not have subCollections
    // but has items, we will go to items
    switch (elem.hasSubcollection) {
      case true:
        return history.push(`/admin/subCollections/${elem.id}`);
      case false:
        return history.push(`/admin/items/${elem.id}/0`);
      case null:
        return history.push(`/admin/subCollections/${elem.id}`);
      default:
        return history.push(`/admin/subCollections/${elem.id}`);
    }
  };

  const checkValue = (elem) => {
    if (editColName === "") {
      return setInvalidEdit(true);
    }
    return saveEdit(elem);
  };
  return (
    <div class="flex h-3/6 flex-col relative">
      <div class="-my-2 overflow-y-auto sm:-mx-6 lg:-mx-8">
        <div class="py-2 align-middle inline-block w-full sm:px-6 lg:px-8">
          <div class="shadow overflow-auto border-gray-200  sm:rounded-lg">
            {collections.length > 0 ? (
              <table className="w-full divide-y divide-gray-200 ">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      {t("collectionName")}
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      {t("subCollectionCount")}
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      {t("countItem")}
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    ></th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200 ">
                  {collections.map((elem) => (
                    <tr className="cursor-pointer hover:bg-blue-50">
                      <td
                        onClick={() => !elem.edit && goToNext(elem)}
                        className="px-6 py-4 whitespace-nowrap"
                      >
                        {!elem.edit ? (
                          <div className="text-sm text-gray-900 ">
                            {elem.name}
                          </div>
                        ) : (
                          <div className="text-sm text-gray-900">
                            <TextInput
                              value={editColName}
                              invalid={invalidEdit}
                              onChange={(e) => {
                                setEditColName(e.target.value);
                                setInvalidEdit(false);
                              }}
                            />
                          </div>
                        )}
                      </td>
                      <td
                        onClick={() => goToNext(elem)}
                        className="px-6 py-4 whitespace-nowrap"
                      >
                        <div className="text-sm text-gray-900">
                          {elem.collectionCount}
                        </div>
                      </td>
                      <td
                        onClick={() => goToNext(elem)}
                        className="px-6 py-4 whitespace-nowrap "
                      >
                        <div className="text-sm text-gray-900  ">
                          {elem.itemCount}
                        </div>
                      </td>
                      {!elem.edit ? (
                        <td className=" flex justify-end space-x-3  whitespace-nowrap">
                          {localStorage.getItem("userToken") ? (
                            <>
                              <div
                                onClick={() => goToNext(elem)}
                                className=" w-full "
                              ></div>
                              <div className=" py-4" onClick={() => edit(elem)}>
                                <Tooltip
                                  tooltipText="edit"
                                  element={
                                    <FiEdit
                                      color="#0369A1"
                                      className="w-5 h-5 bg-transparent cursor-pointer "
                                    />
                                  }
                                />
                              </div>
                              <div
                                className="pr-6 py-4"
                                onClick={() => deleteElem(elem)}
                              >
                                <Tooltip
                                  tooltipText="delete"
                                  element={
                                    <RiDeleteBin6Line
                                      color="#EF4444"
                                      className="w-5 h-5 bg-transparent cursor-pointer "
                                    />
                                  }
                                />
                              </div>
                            </>
                          ) : (
                            <div
                              onClick={() => goToNext(elem)}
                              className="py-4 w-full flex justify-end pr-6"
                            >
                              <IoIosArrowForward
                                color="#0369A1"
                                className="w-5 h-5 bg-transparent cursor-pointer "
                              />
                            </div>
                          )}
                        </td>
                      ) : (
                        <td className="px-6  flex h-20 justify-end items-center space-x-3 whitespace-nowrap">
                          <div
                            className="text-sm text-blue-primary opacity-70 cursor-pointer hover:opacity-90 "
                            onClick={() => checkValue(elem)}
                          >
                            {t("done")}
                          </div>
                          {localStorage.getItem("userToken") && (
                            <div
                              className="text-sm text-gray-400 opacity-50 cursor-pointer hover:opacity-90"
                              onClick={() => edit(elem)}
                            >
                              {t("cancel")}
                            </div>
                          )}
                        </td>
                      )}
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <div className="flex flex-col w-full justify-center items-center my-10">
                <img src={NoItems} alt="no-items" className="w-40 h-40" />
                <h2>{t("noItems")}</h2>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default List;
