/* eslint-disable jsx-a11y/anchor-is-valid */
import { LazyImage } from "components";
import React from "react";
import { useTranslation } from "react-i18next";

import Logo from "../assets/muzejLogo.svg";
import { Loader } from "components";

export default function LoginContainer({
  checkValues,
  setValues,
  data,
  loader,
}) {
  const { t } = useTranslation();

  return (
    <div className="w-full sm:w-1/3 flex items-center rounded justify-center bg-gray-50 py-12 px-4 mx-6 sm:mx-0 sm:px-6 lg:px-8">
      <div className="max-w-md w-full space-y-8">
        <div>
          <div className="flex row justify-center items-center">
            <LazyImage className=" h-8 w-auto mr-3" src={Logo} alt="logo" />
            <h2 className="text-blue-primary font-inter font-bold">
              JU Zavičajni muzej
            </h2>
          </div>

          <h2 className="font-inter mt-6 text-center text-3xl font-extrabold text-gray-900">
            {t("logIn")}
          </h2>
        </div>
        <form className="mt-8 space-y-6">
          <div className="rounded-md shadow-sm -space-y-px">
            {data.map((elem) => (
              <>
                <label className="font-inter sr-only">{elem.name}</label>
                <input
                  style={{
                    marginBottom: "10px",
                    border: elem.invalid && "1px solid red",
                  }}
                  type={elem.name === "Email" ? "email" : "password"}
                  className={`font-inter appearance-none rounded-md relative block w-full px-3 py-2 border border-gray-300  border-red-400 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none  focus:border-gray-200 focus:z-10 sm:text-sm`}
                  placeholder={elem.name}
                  value={elem.value}
                  onChange={(e) => setValues(e, elem.name)}
                />
                {elem.invalid && (
                  <p
                    className="text-red font-bold text-xs mb-5"
                    style={{ marginBottom: "10px" }}
                  >
                    {t("emptyField")}
                  </p>
                )}
              </>
            ))}
          </div>
          {/* <div className="flex items-center justify-between">
            <div className="flex items-center">
              <input
                id="remember-me"
                name="remember-me"
                type="checkbox"
                className="h-4 w-4 text-blue-primary  border-gray-300 rounded-sm"
              />
              <label
                htmlFor="remember-me"
                className="ml-2 block font-inter text-sm text-gray-900"
              >
                Zapamti prijavu
              </label>
            </div>
          </div> */}

          <div>
            <button
              onClick={(e) => {
                checkValues(e);
              }}
              className=" font-inter group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-primary hover:bg-blue-secondary focus:outline-none focus:ring-2 focus:ring-offset-2 "
            >
              {loader ? <Loader xs gray /> : <p>{t("logIn")}</p>}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
