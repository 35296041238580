import React from "react";
import { RiCloseCircleFill } from "react-icons/ri";
import { IoWarning } from "react-icons/io5";
import { MdDone } from "react-icons/md";
import { HiInformationCircle } from "react-icons/hi";
import { BiRightArrowAlt } from "react-icons/bi";
import { useTranslation } from "react-i18next";
import { Modal } from "react-responsive-modal";
//import "react-responsive-modal/styles.css";

const Error = ({ error, success, warning, open, close, onClose }) => {
  const { t } = useTranslation();
  return (
    <Modal
      onClose={() => console.log("closing")}
      open={open}
      showCloseIcon={false}
    >
      <div className="h-full w-full flex space-x-5 ">
        {error ? (
          <RiCloseCircleFill color="#F87171" className="w-5 h-5" />
        ) : success ? (
          <div className="w-5 h-5 bg-green-300 rounded-full flex justify-center items-center ">
            <MdDone color="white" className="w-3 h-3" />
          </div>
        ) : warning ? (
          <IoWarning color="#FBBF24" className="w-5 h-5" />
        ) : (
          <HiInformationCircle color="#60A5FA" className="w-5 h-5" />
        )}

        <div className="flex flex-col space-y-3 w-full ">
          <div className="flex justify-between  w-full">
            <p
              className={`text-sm  font-medium font-inter ${
                error
                  ? "text-red-800"
                  : success
                  ? "text-green-800"
                  : warning
                  ? "text-yellow-800 "
                  : "text-blue-800"
              } `}
            >
              {t("policyMsg")}
            </p>
            {!error && !success && !warning ? (
              <div className="flex">
                <p className="text-sm text-blue-700 font-inter">
                  Pogledaj detaljnije
                </p>
                <BiRightArrowAlt color="1D4ED8" className="w-5 h-5" />
              </div>
            ) : null}
          </div>

          {error || success || warning ? (
            <div className="flex items-center space-x-2 ">
              {error ? (
                <div className="w-2 h-2 rounded-full bg-red-800"></div>
              ) : null}
              <p
                className={`text-sm font-inter  ${
                  error
                    ? "text-red-700"
                    : success
                    ? "text-green-700"
                    : "text-yellow-700 "
                }`}
              >
                {t("policyEmail")}
                <a
                  className="text-blue-primary ml-2"
                  href="mailto:zavicajnimuzejvisoko@gmail.com"
                >
                  zavicajnimuzejvisoko@gmail.com
                </a>
              </p>
            </div>
          ) : null}

          {!success ? (
            <div onClick={onClose} className="flex  justify-end ">
              <p className="text-sm font-inter bg-gray-200 px-3 py-2 text-green-800 font-medium cursor-pointer">
                {t("agree")}
              </p>
            </div>
          ) : null}
        </div>
      </div>
    </Modal>
  );
};

export default Error;
