/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";

import { AllItems } from "../../containers";
import { Loader } from "components";
import apiRequest from "helpers/apiRequest";

const Items = ({ match, location }) => {
  const history = useHistory();
  const [items, setItems] = useState(null);
  const [allItems, setAllItems] = useState(null);
  const [page, setPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");

  const getAllItems = async () => {
    const collectionId =
      match.params.subCategoryId != 0
        ? match.params.subCategoryId
        : match.params.categoryId;
    try {
      const response = await apiRequest({
        method: "get",
        url: `item?page=${page}&pageSize=12&orderBy=createdAt&orderType=ASC&search=${searchTerm}&subcollectionId=${collectionId}`,
      });
      if (response.status === 200) {
        setItems(response.data);
      }
    } catch (err) {
      if (err?.response?.status === 401) {
        history.push("/auth/login");
      }
      console.log(err);
    }
  };

  const getItemsWithoutPagination = async () => {
    const collectionId =
      match.params.subCategoryId != 0
        ? match.params.subCategoryId
        : match.params.categoryId;
    try {
      const response = await apiRequest({
        method: "get",
        url: `item?page=${page}&pageSize=1000000&orderBy=createdAt&orderType=ASC&search=&subcollectionId=${collectionId}`,
      });
      if (response.status === 200) {
        //console.log("ALL ITEMS U ITEMS", response.data);
        setAllItems(response.data);
      }
    } catch (err) {
      if (err?.response?.status === 401) {
        history.push("/auth/login");
      }
      console.log(err);
    }
  };

  const changePage = (e) => {
    setPage(e.selected + 1);
  };

  useEffect(() => {
    getAllItems();
  }, [page, searchTerm]);
  useEffect(() => {
    getItemsWithoutPagination();
  }, []);

  return (
    <div className="flex row  h-screen overflow-hidden relative">
      <div className="w-full h-full bg-blue-100 overflow-hidden">
        <div className=" flex flex-col lg:flex-row pb-96   overflow-hidden ">
          {items != null ? (
            <AllItems
              location={location?.state?.fromSecondStep}
              items={items}
              allItems={allItems}
              changePage={changePage}
              category={match.params.categoryId}
              subcategory={match.params.subCategoryId}
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          ) : (
            <div className="bg-blue-100 h-screen w-full pt-5 pb-5 overflow-hidden">
              <div className=" bg-white rounded mx-5 h-screen px-10 pt-5 pb-12 flex justify-center items-center ">
                <Loader />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Items;
