import React from "react";
import { useTranslation } from "react-i18next";
import { HiDocumentDownload, HiOutlineTrash } from "react-icons/hi";
import { IoArrowBackOutline } from "react-icons/io5";
import { FiEdit } from "react-icons/fi";
import { Text } from ".";

const Button = ({
  text,
  invert,
  onClick,
  file,
  withoutMargin,
  remove,
  back,
  edit,
}) => {
  const { t } = useTranslation();
  return (
    <div
      className={` h-12  2xl:px-5 px-3 flex justify-center items-center rounded-lg   cursor-pointer hover:shadow-md 
        ${invert ? "bg-transparent border-2" : "bg-blue-primary"}
        ${withoutMargin ? null : "mb-4"}
         `}
      onClick={onClick}
    >
      {file ? (
        <HiDocumentDownload
          color={invert ? "#0369A1" : "white"}
          className="w-5 h-5 mr-3"
        />
      ) : remove ? (
        <HiOutlineTrash
          color={invert ? "#0369A1" : "white"}
          className="w-5 h-5 mr-3"
        />
      ) : back ? (
        <IoArrowBackOutline
          color={invert ? "#0369A1" : "white"}
          className="w-5 h-5 mr-3"
        />
      ) : edit ? (
        <FiEdit color={invert ? "#0369A1" : "white"} className="w-5 h-5 mr-3" />
      ) : null}
      {invert ? (
        <Text small>{t(text)}</Text>
      ) : (
        <Text white small>
          {t(text)}
        </Text>
      )}
    </div>
  );
};

export default Button;
