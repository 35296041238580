/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import { RiArrowLeftSLine } from "react-icons/ri";
import { Item, FilterContainer } from "containers";
import { AddButton, Loader } from "components";
import apiRequest from "helpers/apiRequest";

const CollectionInfo = (props) => {
  const items = [
    {
      item: "Lorem",
      collection: "Lorem",
      description:
        "Dio izvučen iz opisa predmeta, lorem ispum sit dolor sit amet. Ante victoria ne canas ante ne portas triumppfum. Homo homini lupus....",
    },
    {
      item: "Lorem",
      collection: "Lorem",
      description:
        "Dio izvučen iz opisa predmeta, lorem ispum sit dolor sit amet. Ante victoria ne canas ante ne portas triumppfum. Homo homini lupus....",
    },
    {
      item: "Lorem",
      collection: "Lorem",
      description:
        "Dio izvučen iz opisa predmeta, lorem ispum sit dolor sit amet. Ante victoria ne canas ante ne portas triumppfum. Homo homini lupus....",
    },
    {
      item: "Lorem",
      collection: "Lorem",
      description:
        "Dio izvučen iz opisa predmeta, lorem ispum sit dolor sit amet. Ante victoria ne canas ante ne portas triumppfum. Homo homini lupus....",
    },
    {
      item: "Lorem",
      collection: "Lorem",
      description:
        "Dio izvučen iz opisa predmeta, lorem ispum sit dolor sit amet. Ante victoria ne canas ante ne portas triumppfum. Homo homini lupus....",
    },
    {
      item: "Lorem",
      collection: "Lorem",
      description:
        "Dio izvučen iz opisa predmeta, lorem ispum sit dolor sit amet. Ante victoria ne canas ante ne portas triumppfum. Homo homini lupus....",
    },
    {
      item: "Lorem",
      collection: "Lorem",
      description:
        "Dio izvučen iz opisa predmeta, lorem ispum sit dolor sit amet. Ante victoria ne canas ante ne portas triumppfum. Homo homini lupus....",
    },
    {
      item: "Lorem",
      collection: "Lorem",
      description:
        "Dio izvučen iz opisa predmeta, lorem ispum sit dolor sit amet. Ante victoria ne canas ante ne portas triumppfum. Homo homini lupus....",
    },
    {
      item: "Lorem",
      collection: "Lorem",
      description:
        "Dio izvučen iz opisa predmeta, lorem ispum sit dolor sit amet. Ante victoria ne canas ante ne portas triumppfum. Homo homini lupus....",
    },
    {
      item: "Lorem",
      collection: "Lorem",
      description:
        "Dio izvučen iz opisa predmeta, lorem ispum sit dolor sit amet. Ante victoria ne canas ante ne portas triumppfum. Homo homini lupus....",
    },
    {
      item: "Lorem",
      collection: "Lorem",
      description:
        "Dio izvučen iz opisa predmeta, lorem ispum sit dolor sit amet. Ante victoria ne canas ante ne portas triumppfum. Homo homini lupus....",
    },
    {
      item: "Lorem",
      collection: "Lorem",
      description:
        "Dio izvučen iz opisa predmeta, lorem ispum sit dolor sit amet. Ante victoria ne canas ante ne portas triumppfum. Homo homini lupus....",
    },
    {
      item: "Lorem",
      collection: "Lorem",
      description:
        "Dio izvučen iz opisa predmeta, lorem ispum sit dolor sit amet. Ante victoria ne canas ante ne portas triumppfum. Homo homini lupus....",
    },
    {
      item: "Lorem",
      collection: "Lorem",
      description:
        "Dio izvučen iz opisa predmeta, lorem ispum sit dolor sit amet. Ante victoria ne canas ante ne portas triumppfum. Homo homini lupus....",
    },
  ];
  const [collection, setCollection] = useState(null);
  const history = useHistory();

  const getCollectionById = async () => {
    try {
      const response = await apiRequest({
        method: "get",
        url: `todos/${props.match.params.collectionId}`,
      });
      if (response.status === 200) {
        setCollection(response.data);
      }
    } catch (err) {
      if (err?.response?.status === 401) {
        history.push("/auth/login");
      }
      console.log(err);
    }
  };

  useEffect(() => {
    getCollectionById();
  }, []);

  return (
    <div className="flex row h-full lg:h-screen lg:overflow-hidden ">
      <div className="w-full h-full bg-blue-100 overflow-y-auto lg:overflow-hidden">
        <div className=" flex flex-col lg:flex-row h-screen ">
          <div className=" block lg:hidden  lg:w-3/12 mx-3 xl:mx-5 mt-5 rounded px-2 xl:px-4 pt-6 ">
            <AddButton text="Ispiši predmete" file />
            <AddButton
              text="Dodaj novi predmet"
              invert
              onClick={() => history.push("/admin/firstStep")}
            />
            <FilterContainer />
          </div>
          {collection != null ? (
            <div className="lg:h-full lg:w-9/12   bg-white ml-5 mr-5 lg:mr-0 mt-5 rounded lg:px-10 px-3 pb-36 py-6 ">
              <div className="w-full flex row">
                <div
                  className="h-20 w-16 flex items-center cursor-pointer"
                  onClick={() => history.push("/admin/collections")}
                >
                  <RiArrowLeftSLine color="black" className="w-10 h-10" />
                </div>
                <div className="h-20  w-full flex row justify-between items-center border-b-2 mb-10">
                  <h2 className="text-4xl font-inter   ">{collection.title}</h2>
                </div>
              </div>
              <div className="h-full grid grid-cols-1 lg:grid-cols-2   xl:grid-cols-3 grid-flow-row gap-10 pt-10 lg:pr-5 px-5 lg:px-0  lg:overflow-y-scroll ">
                {items.map((myItem) => (
                  <Item
                    description={myItem.description.substring(0, 130)}
                    item={myItem.item}
                    collection={myItem.collection}
                    onClick={() => history.push(`/admin/itemInfo/${myItem.id}`)}
                  />
                ))}
              </div>
            </div>
          ) : (
            <div className="h-full w-9/12  bg-white ml-5 mt-5 rounded px-10 pb-36 py-6 flex justify-center items-center ">
              <Loader />
            </div>
          )}
          <div className=" hidden lg:block  w-3/12 mx-3 xl:mx-5 mt-5 rounded px-2 xl:px-4 pt-6 ">
            <AddButton text="Ispiši predmete" file />
            <AddButton
              text="Dodaj novi predmet"
              invert
              onClick={() => history.push("/admin/firstStep")}
            />
            <FilterContainer />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CollectionInfo;
