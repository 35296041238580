import React from "react";
import { HashRouter, Route, Switch, Redirect } from "react-router-dom";

import { Provider } from "react-redux";
import store from "redux/store/store";

import Admin from "./layouts/Admin";
import Auth from "./layouts/Auth";

const App = () => {
  return (
    <Provider store={store}>
      <HashRouter>
        <Switch>
          <Route path="/admin" render={(props) => <Admin {...props} />} />
          <Route path="/auth" render={(props) => <Auth {...props} />} />
          <Redirect from="/" to="/admin/allItems" />
        </Switch>
      </HashRouter>
    </Provider>
  );
};

export default App;
