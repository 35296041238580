import React, { useState, useEffect } from "react";

import { SidebarItem, Text, LazyImage } from "../components";
import Logo from "../assets/muzejLogo.svg";
import { useHistory } from "react-router";
import { useLocation } from "react-router-dom";

const Sidebar = () => {
  const history = useHistory();
  const location = useLocation();
  const [items, setItems] = useState([
    {
      icon: "AiOutlineHome",
      text: "analytics",
      // active: false,
      active:
        localStorage.getItem("activeSidebarItem") === "analytics"
          ? true
          : false,
      route: "analytics",
      visible: localStorage.getItem("userToken"),
    },
    {
      icon: "RiCollageFill",
      text: "allItems",
      // active: localStorage.getItem("userToken"),
      active:
        localStorage.getItem("activeSidebarItem") === "" ||
        localStorage.getItem("activeSidebarItem") === "allItems" ||
        !localStorage.getItem("activeSidebarItem")
          ? true
          : false,
      route: "allItems",
      visible: true,
    },
    {
      icon: "BsBook",
      text: "collections",
      //active: false,
      active:
        localStorage.getItem("activeSidebarItem") === "collections"
          ? true
          : false,
      route: "collections",
      visible: true,
    },
    {
      icon: "FiSettings",
      text: "settings",
      //active: false,
      active:
        localStorage.getItem("activeSidebarItem") === "settings" ? true : false,
      route: "settings",
      visible: localStorage.getItem("userToken"),
    },
  ]);

  const handleClick = (text, route) => {
    const itemsCopy = [...items];
    itemsCopy.forEach((elem) => {
      elem.active = false;
      if (elem.text === text) {
        elem.active = true;
      }
    });
    setItems([...itemsCopy]);
    history.push(`/admin/${route}`);
  };
  useEffect(() => {
    (location.pathname === "/admin/allItems" || location.pathname === "/") &&
      localStorage.setItem("activeSidebarItem", "allItems");
  }, [location.pathname]);

  return (
    <div
      className=" h-screen bg-blue-primary p-2 flex flex-col justify-between "
      style={{ zIndex: 1 }}
    >
      <div>
        <div className="h-16 flex row items-center justify-center">
          <LazyImage
            src={Logo}
            alt="logo"
            className="w-auto h-10 inline-block mr-4"
          />
          <Text white>JU Zavičajni Muzej</Text>
        </div>
        {items.map(
          (item, id) =>
            item.visible && (
              <SidebarItem
                active={item.active}
                key={id}
                icon={item.icon}
                text={item.text}
                onClick={() => {
                  handleClick(item.text, item.route);
                  localStorage.setItem("activeSidebarItem", item.text);
                }} //needs to be fixed
              />
            )
        )}
      </div>
    </div>
  );
};

export default Sidebar;
