
const INITAL_STATE = {
    isOpen: false,
    message: ''
}

export default function errorReducer( state = INITAL_STATE, action ) {
    switch(action.type){
        case 'SET_OPEN' : {
            return { ...state, isOpen: true, message: action.message }
        }
        case 'SET_CLOSE' : {
            return { ...state, isOpen: false, message: '' }
        }
        default : {
            return {...state}
        }
    }
}