import React from "react";
import { Text } from "components";

const ToggleButton = ({ onClick, active }) => {
  return (
    <div className="cursor-pointer">
      {active ? (
        <div
          onClick={onClick}
          className="w-20 h-7 bg-blue-primary flex row justify-between items-center rounded border px-2"
        >
          <Text white small></Text>
          <div className="h-4 w-4 bg-white rounded-full cursor-pointer "></div>
        </div>
      ) : (
        <div
          onClick={onClick}
          className="w-20 h-7 bg-gray-300 rounded border flex row justify-between items-center px-2 "
        >
          <div className="h-4 w-4 bg-white rounded-full cursor-pointer"></div>
          <Text white small></Text>
        </div>
      )}
    </div>
  );
};

export default ToggleButton;
