import React, { useState } from "react";
import { useHistory } from "react-router";

import apiRequest from "../../helpers/apiRequest";

import { LoginContainer } from "../../containers";
import ErrorAlert from "components/ErrorAlert";
import ConfirmationModal from "components/ConfirmationModal";

const LoginPage = () => {
  const history = useHistory();

  const [error, setError] = useState(null);
  const [data, setData] = useState([
    { name: "Email", value: "", invalid: false },
    { name: "Password", value: "", invalid: false },
  ]);
  const [logOutModal, setLogOutModal] = useState(false);
  const [showLoader, setShowLoader] = useState(false);

  const createError = (msg) => {
    setError(<ErrorAlert errorText={msg} />);
    setTimeout(() => {
      setError(null);
    }, 3000);
  };

  const loginUser = async () => {
    try {
      setShowLoader(true);
      const response = await apiRequest({
        method: "post",
        url: `auth/login`,
        data: {
          email: data[0].value,
          password: data[1].value,
        },
      });
      if (response.status === 200) {
        localStorage.setItem("userToken", response.data.token);
        history.push("/admin/allItems");
        setShowLoader(false);
      }
    } catch (err) {
      if (err?.response?.status === 401) {
        history.push("/auth/login");
      }
      if (err?.response?.status === 404) {
        console.log("==error 404 je ovo==");
        localStorage.setItem("userToken", err.response.data.token);
        setLogOutModal(true);
      } else createError(err?.response?.data?.error);
      setShowLoader(false);
    }
  };
  const forceLogOutUser = async () => {
    try {
      const response = await apiRequest({
        method: "post",
        url: `auth/forse-login`,
        data: {
          email: data[0].value,
          password: data[1].value,
        },
      });
      if (response.status === 200) {
        localStorage.setItem("userToken", response.data.token);
        history.push("/admin/allItems");
      }
    } catch (err) {
      if (err?.response?.status === 401) {
        history.push("/auth/login");
      }

      createError(err?.response?.data?.error);
    }
  };

  const checkValues = (event) => {
    let isValid = true;
    event.preventDefault();
    const localData = [...data];
    localData.forEach((elem) => {
      if (elem.value === "") {
        elem.invalid = true;
        isValid = false;
      }
    });
    setData([...localData]);
    if (isValid) return loginUser();
  };

  const setValues = (e, name) => {
    const localData = [...data];
    localData.forEach((elem) => {
      if (elem.name === name) {
        elem.value = e.target.value;
        elem.invalid = false;
      }
    });
    setData([...localData]);
  };

  return (
    <div className="relative h-screen flex justify-center items-center bg-login-background bg-center bg-cover overflow-hidden">
      {error}

      <ConfirmationModal
        info
        onClose={() => setLogOutModal(false)}
        open={logOutModal}
        confirmationText="Trenutno je neko prijavljen na ovaj račun!"
        confirmationDesc="Želite li ih odjaviti?"
        confirmButtonText="Da"
        cancelText="Ne"
        onConfirm={() => forceLogOutUser()}
      />

      <LoginContainer
        loader={showLoader}
        data={data}
        checkValues={checkValues}
        setValues={setValues}
      />
    </div>
  );
};

export default LoginPage;
