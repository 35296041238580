import React from "react";
import { useTranslation } from "react-i18next";
import Pagination from "./Pagination";

const CollectionTable = ({ collectionData, setPage }) => {
  const { t } = useTranslation();
  console.log("collection data", collectionData.files);
  return (
    <div className="py-2 align-middle inline-block w-full">
      <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
        <table className="w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                {t("collectionName")}
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                {t("subCollectionCount")}
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                {t("countItem")}
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {collectionData.length !== 0 && collectionData?.files ? (
              collectionData.files.map((elem) => (
                <tr key={elem.id}>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="text-sm text-gray-900">{elem.name}</div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="text-sm text-gray-900">
                      {elem.collectionCount}
                    </div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="text-sm text-gray-900">
                      {elem.itemCount}
                    </div>
                  </td>
                </tr>
              ))
            ) : (
              <p className="p-5">{t("noItems")}</p>
            )}
          </tbody>
        </table>
      </div>
      <Pagination
        pages={collectionData}
        changePage={(page) => setPage(page.selected + 1)}
      />
    </div>
  );
};

export default CollectionTable;
