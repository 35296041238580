import React from "react";
import { useTranslation } from "react-i18next";

import Border from "./Border";
import { Text } from "components";
import { FiCheck } from "react-icons/fi";

const Step = ({
  title,
  subtitle,
  step,
  active,
  completed,
  onClick,
  clickable,
}) => {
  const { t } = useTranslation();
  return (
    <div
      onClick={onClick}
      className={`lg:w-1/3 border-r-2 lg:border-r-0  flex row relative  ${
        active && "border-b-4 border-blue-primary"
      } ${clickable && "cursor-pointer"}`}
    >
      <div className="mr-4">
        <Border />
      </div>
      <div className={`flex items-center ${clickable && "cursor-pointer"}`}>
        {completed ? (
          <div className="w-10 h-10 mr-4 rounded-full  flex items-center justify-center border-2 border-blue-primary bg-blue-primary ">
            <FiCheck color="white" className="w-5 h-5" />
          </div>
        ) : (
          <div
            className={`w-10 h-10 mr-4 rounded-full  flex items-center justify-center border-2 y ${
              active ? "border-blue-primary" : "broder-gray-200"
            }`}
          >
            {active ? <Text blue>{step}</Text> : <Text gray>{step}</Text>}
          </div>
        )}

        <div>
          {active ? (
            <div>
              <Text blue>{t(title)}</Text>
            </div>
          ) : (
            <div>
              <Text gray>{t(title)}</Text>
            </div>
          )}

          <div>
            <Text small gray>
              {t(subtitle)}
            </Text>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Step;
