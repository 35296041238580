import React, { useState } from "react";

import { Text } from "components";

const Item = ({ item, collection, description, onClick, img }) => {
  return (
    <div
      style={{ minHeight: 80 }}
      className=" border rounded-xl p-2 cursor-pointer hover:shadow-md "
      onClick={onClick}
    >
      <div className="2xl:h-52  w-full rounded-xl overflow-hidden md:flex-shrink-0">
        <img
          className="w-full object-cover h-52"
          src={img}
          alt="slika"
          onError={(e) => {
            e.target.onerror = null;
            e.target.src =
              "https://e-muzej-bucket.s3.eu-central-1.amazonaws.com/Default37-15-10-2021-2-58-09/15-10-2021-2-58-58No_Image.png";
          }}
        />
      </div>

      <div className="column mt-1 flex flex-col justify-between space-y-1 ">
        <Text blue>{collection}</Text>
        <Text black>{item}</Text>
        {description.length > 90 ? (
          item.length < 60 ? (
            <p className="text-gray-500 text-sm xl:text-md break-words">
              {description.substring(0, 350)}...
            </p>
          ) : (
            <p className="text-gray-500 text-sm xl:text-md break-words">
              {description.substring(0, 90)}...
            </p>
          )
        ) : (
          <p className="text-gray-500 break-words">{description}</p>
        )}
      </div>
    </div>
  );
};
export default Item;
