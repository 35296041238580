/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { LazyImage } from "components";
import ba from "assets/bosnia-and-herzegovina.png";
import en from "assets/united-kingdom.png";

const LanguageDropdown = ({ onClick }) => {
  const language = useSelector((state) => state.language.selectedLanguage);
  const [isVisible, setIsVisible] = useState(false);

  return (
    <div className="relative inline-block text-left">
      <div>
        <button
          onClick={() => setIsVisible(!isVisible)}
          type="button"
          className="inline-flex justify-center items-center w-full rounded-md border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none"
          id="menu-button"
          aria-expanded="true"
          aria-haspopup="true"
        >
          {language === "ba" ? (
            <LazyImage src={ba} alt="flag" className="max-h-5 max-w-5" />
          ) : (
            <LazyImage src={en} alt="flag" className="max-h-5 max-w-5" />
          )}
          <svg
            className="-mr-1 ml-2 h-5 w-5"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
          >
            <path
              fillRule="evenodd"
              d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
              clipRule="evenodd"
            />
          </svg>
        </button>
      </div>
      {isVisible ? (
        <div
          className="z-10 origin-top-right absolute right-0 mt-2 w-16 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="menu-button"
          tabindex="-1"
        >
          <div className="py-1" role="none">
            <div
              onClick={() => {
                onClick("ba");
                setIsVisible(false);
              }}
              className="flex justify-center items-center p-2 cursor-pointer "
            >
              <LazyImage src={ba} alt="flag" className="mr-1 max-h-5 max-w-5" />
              <p className={`${language === "ba" && "font-extrabold "}`}>BA</p>
            </div>
            <div
              onClick={() => {
                onClick("en");
                setIsVisible(false);
              }}
              className="flex justify-center items-center cursor-pointer"
            >
              <LazyImage src={en} alt="flag" className="mr-1 max-h-5 max-w-5" />
              <p className={`${language === "en" && "font-extrabold "}`}>EN</p>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default LanguageDropdown;
