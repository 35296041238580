/* eslint-disable import/no-anonymous-default-export */
import { Modal } from "react-responsive-modal";
import SuccessImage from "../assets/success-svgrepo-com.svg";
import SuccessIcon from "../assets/successIcon.svg";
import "react-responsive-modal/styles.css";
import { LazyImage } from "components";
import { useTranslation } from "react-i18next";

const CustomModal = ({ open, onClose, onConfirm, title, subtitle = "" }) => {
  const { t } = useTranslation();
  return (
    <Modal open={open} onClose={onClose}>
      <div className="w-full  max-w-lg  px-5 py-5 relative mx-auto my-auto bg-white ">
        <div>
          <div className="text-center  flex flex-col justify-center items-center">
            <div className="bg-green-100 w-10 h-10 rounded-full flex justify-center items-center ">
              <LazyImage
                src={SuccessIcon}
                alt="success"
                className="w-4 h-4 flex "
              />
            </div>

            <p className="text-lg font-medium  mt-5 ">{t(title)}</p>
            <p className="text-sm text-gray-400  mt-3 mb-5 ">{t(subtitle)}</p>
          </div>
          <div className="  mt-2 text-center space-x-4 md:block">
            <button
              className=" w-full bg-blue-primary border border-red-500 px-5 py-2 text-sm shadow-sm font-medium tracking-wider text-white rounded-lg hover:shadow-lg hover:bg-blue-secondary"
              onClick={onConfirm}
            >
              {t("continue")}
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default CustomModal;
