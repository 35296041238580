import { createStore, combineReducers } from "redux";

import languageReducer from "redux/reducers/languageReducer";
import errorReducer from 'redux/reducers/errorReducer'

const rootReducer = combineReducers({language: languageReducer, error: errorReducer})

const store = createStore(rootReducer)

export default store;
