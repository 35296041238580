/* eslint-disable import/no-anonymous-default-export */
import { Modal } from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import { useTranslation } from "react-i18next";
import infoImg from "assets/info.svg";

const InfoModal = ({
  open,
  onClose,
  onCancel,
  onConfirm,
  confirmationText,
  confirmationDesc,
  confirmButtonText,
}) => {
  const { t } = useTranslation();

  return (
    <Modal open={open} onClose={onClose}>
      <div class="w-52 md:w-full  max-w-lg p-5 relative mx-auto my-auto bg-white ">
        <div class="">
          <div class="text-center md:p-5 flex-auto justify-center">
            <img
              src={infoImg}
              alt="Info"
              class="w-10 h-10 md:w-12 md:h-12 flex items-center text-red-500 mx-auto"
              viewBox="0 0 52 52"
              fill="currentColor"
            />
            <h2 class="text-xl font-bold py-4 ">{t(confirmationText)}</h2>
            <p class="text-sm text-gray-500 px-8">{t(confirmationDesc)}</p>
          </div>
          <div class="p-3  mt-2 text-center space-y-2 md:block">
            <button
              class="mb-2 md:mb-0 w-full bg-blue-primary border border-red-500 px-5 py-2 text-sm shadow-sm font-medium tracking-wider text-white rounded-lg hover:shadow-lg hover:bg-red-600"
              onClick={onConfirm}
            >
              {t(confirmButtonText)}
            </button>
            <button
              class="mb-2 md:mb-0 bg-white w-full px-5 py-2 text-sm shadow-sm font-medium tracking-wider border text-gray-600 rounded-lg hover:shadow-lg hover:bg-gray-100"
              onClick={onCancel}
            >
              {t("cancel")}
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default InfoModal;
