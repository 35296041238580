import React from "react";
import { useTranslation } from "react-i18next";

import { Text } from "components";

const Alert = ({ text, onClick }) => {
  const { t } = useTranslation()
  return (
    <div
      onClick={onClick}
      className="absolute animate-bounce duration-200 inset-x-94 top-6 bg-green-400 rounded justify-between 2xl:px-10 px-5  lg:w-1/4 h-14 flex items-center"
    >
      <div>
        <Text white>{t(text)}</Text>
      </div>
      <div className="ml-10 lg:ml-0 cursor-pointer">
        <Text white>x</Text>
      </div>
    </div>
  );
};

export default Alert;
