import React from "react";
import { IoIosArrowForward, IoIosArrowBack } from "react-icons/io";
import { GrClose } from "react-icons/gr";

const Gallery = ({
  onClick,
  src,
  onRightArrowClick,
  onLeftArrowClick,
  initialPhotoId,
  photosLength,
}) => {
  return (
    <div className=" w-full h-full bg-gray-200 bg-opacity-70 absolute inset-x-0 bottom-14 z-10 flex flex-col items-center justify-center">
      <div className="w-full md:w-1/2 flex justify-end ">
        <div
          onClick={onClick}
          className="w-10 h-10 mb-2 cursor-pointer rounded-lg bg-gray-200 flex justify-center items-center hover:bg-gray-300"
        >
          <GrClose className="w-5 h-8" color="white" />
        </div>
      </div>
      <div className="w-full md:w-1/2 h-1/2   flex justify-center items-center  ">
        <img
          className="w-full object-contain h-full"
          src={src}
          style={{ borderRadius: "8px" }}
        />
        {photosLength > 1 && (
          <div className="w-full md:w-1/2 px-2 absolute flex flex-row justify-between">
            <IoIosArrowBack
              onClick={initialPhotoId === 0 ? null : onLeftArrowClick}
              className="w-10 h-10 md:w-20 md:h-20 bg-gray-800 cursor-pointer "
              color="white"
            />
            <IoIosArrowForward
              onClick={
                initialPhotoId === photosLength - 1 ? null : onRightArrowClick
              }
              className="w-10 h-10 md:w-20 md:h-20  bg-gray-800 cursor-pointer "
              color="white"
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default Gallery;
