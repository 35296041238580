export const closeError = () => {
    return {
        type: 'SET_CLOSE',
}}

export const openError = (msg) => {
    setTimeout(() => {
        closeError()
    }, 3000)
    return {
        type: 'SET_OPEN',
        message: msg
}}
