/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";

import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import moment from "moment";
import ImageUploading from "react-images-uploading";
import { RiArrowLeftSLine } from "react-icons/ri";
import { IoClose } from "react-icons/io5";
import RichTextEditor from "react-rte";
import SuccessModal from "components/SuccessModal";
import apiRequest from "helpers/apiRequest";

import {
  Button,
  Text,
  TextInput,
  TextArea,
  Loader,
  Dropdown,
} from "components";
import { DatePicker } from "containers";
import ErrorAlert from "components/ErrorAlert";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const EditMode = ({ item, setEditMode, photos, getItemById, match }) => {
  const { t } = useTranslation();
  const [productData, setProductData] = useState({ ...item });
  const history = useHistory();
  const [error, setError] = useState(null);
  const [checking, setChecking] = useState(false);
  const [period, setPeriods] = useState([]);
  const [defaultPeriod, setDefaultPeriod] = useState({
    value: item?.age?.id,
    label: item?.age?.name,
  });
  const [itemCategory, setItemCategory] = useState([]);
  const [defaultCategory, setDefaultCategory] = useState({
    value: item?.category?.id,
    label: item?.category?.name,
  });
  const [materials, setMaterials] = useState([]);
  const [defaultMaterial, setDefaultMaterial] = useState({
    value: item?.Material?.id,
    label: item?.Material?.name,
  });
  const [types, setTypes] = useState([]);
  const [defaultType, setDefaultType] = useState({
    value: item?.species?.id,
    label: item?.species?.name,
  });
  const [imageUploading, setImageUploading] = useState(false);
  const [textEditorValue, setTextEditorValue] = useState(item?.note);
  const [successModal, setSuccessModal] = useState(false);
  const [defaultDate, setDefaultDate] = useState(null);
  const [collections, setCollections] = useState([]);
  const [subcollections, setSubcollections] = useState([]);
  const [defaultCollection, setDefaultCollection] = useState(
    item?.collection?.Parent === null
      ? {
          value: item?.collectionId,
          label: item?.collection?.name,
          hasSubColl: item?.collection?.hasSubcollection,
        }
      : {
          value: item?.collection?.Parent?.id,
          label: item?.collection?.Parent?.name,
          hasSubColl: item?.collection?.Parent?.hasSubcollection,
        }
  );
  const [defaultSubcollection, setDefaultSubcollection] = useState(
    item?.collection?.Parent === null
      ? {
          value: null,
          label: null,
        }
      : {
          value: item?.collectionId,
          label: item?.collection?.name,
        }
  );

  const deleteImage = async (id) => {
    let images = [];
    images.push(id);
    try {
      const response = await apiRequest({
        method: "delete",
        url: "item/delete-pictures",
        data: {
          pictureId: images,
        },
      });
      if (response.status === 200) {
        getItemById();
      }
    } catch (err) {
      if (err?.response?.status === 401) {
        history.push("/auth/login");
      }
      console.log(err);
    }
  };
  const checkForm = () => {
    let isValid = true;
    for (const key in productData) {
      console.log("==productData==", productData);
      if (
        key !== "ageId" &&
        key !== "url" &&
        key !== "where_made" &&
        key !== "origin" &&
        key !== "date_enrollment" &&
        key !== "restauration" &&
        key !== "note" &&
        key !== "speciesId" &&
        key !== "materialId" &&
        key !== "categoryId" &&
        key !== "age" &&
        key !== "Material" &&
        key !== "category" &&
        key !== "species"
      ) {
        if (productData[key] === "" || productData[key] === null) {
          isValid = false;
          break;
        }
      }
    }
    if (!isValid) {
      return createError();
    } else return saveInfo();
  };

  const saveInfo = async () => {
    try {
      const response = await apiRequest({
        method: "put",
        url: `item/${item.id}`,
        data: productData,
      });
      if (response.status === 200) {
        //console.log("====put response====", response);
        setEditMode();
        getItemById();
      }
    } catch (err) {
      if (err?.response?.status === 401) {
        history.push("/auth/login");
      }
      console.log(err);
    }
  };
  const getAllPeriods = async () => {
    try {
      const response = await apiRequest({
        method: "get",
        url: "age",
      });
      if (response.status === 200) {
        const coll = [];
        response.data.files.map((elem) => {
          return coll.push({
            value: elem.id,
            label: elem.name,
          });
        });
        setPeriods([...coll]);
        // setDefaultPeriod({ value: coll[0].value, label: coll[0].label });
      }
    } catch (err) {
      if (err?.response?.status === 401) {
        history.push("/auth/login");
      }
      console.log(err);
    }
  };
  const getAllCategories = async () => {
    try {
      const response = await apiRequest({
        method: "get",
        url: "category",
      });
      if (response.status === 200) {
        const coll = [];
        response.data.files.map((elem) => {
          return coll.push({
            value: elem.id,
            label: elem.name,
          });
        });
        setItemCategory([...coll]);
        // setDefaultPeriod({ value: coll[0].value, label: coll[0].label });
      }
    } catch (err) {
      if (err?.response?.status === 401) {
        history.push("/auth/login");
      }
      console.log(err);
    }
  };
  const getAllMaterials = async () => {
    try {
      const response = await apiRequest({
        method: "get",
        url: "material",
      });
      if (response.status === 200) {
        const coll = [];
        response.data.files.map((elem) => {
          return coll.push({
            value: elem.id,
            label: elem.name,
          });
        });
        setMaterials([...coll]);
        // setDefaultPeriod({ value: coll[0].value, label: coll[0].label });
      }
    } catch (err) {
      if (err?.response?.status === 401) {
        history.push("/auth/login");
      }
      console.log(err);
    }
  };
  const getAllTypes = async () => {
    try {
      const response = await apiRequest({
        method: "get",
        url: "species",
      });
      if (response.status === 200) {
        const coll = [];
        response.data.files.map((elem) => {
          return coll.push({
            value: elem.id,
            label: elem.name,
          });
        });
        setTypes([...coll]);
        // setDefaultPeriod({ value: coll[0].value, label: coll[0].label });
      }
    } catch (err) {
      if (err?.response?.status === 401) {
        history.push("/auth/login");
      }
      console.log(err);
    }
  };

  const createError = (msg = "fillAllFields") => {
    setError(<ErrorAlert errorText={msg} />);
    setTimeout(() => {
      setError(null);
    }, 3000);
  };

  const addImages = async (imageList) => {
    const formData = new FormData();

    formData.append("itemId", parseInt(item.id));
    imageList.forEach((elem) => {
      formData.append("data", elem.file);
    });

    try {
      setImageUploading(true);
      const response = await apiRequest({
        method: "post",
        url: "item/upload-picture",
        data: formData,
      });
      if (response.status === 200) {
        getItemById();
      }
      setImageUploading(false);
    } catch (err) {
      if (err?.response?.status === 401) {
        setImageUploading(false);
        history.push("/auth/login");
      }
      setImageUploading(false);
      createError(err.response.data.error);
    }
  };

  const getAllCollections = async () => {
    try {
      const response = await apiRequest({
        method: "get",
        url: "collection",
      });
      if (response.status === 200) {
        const coll = [];
        response.data.files.map((elem) => {
          return coll.push({
            value: elem.id,
            label: elem.name,
            hasSubColl: elem.hasSubcollection,
          });
        });
        setCollections([...coll]);
        //console.log("RESPONSE COLL", response.data);

        //setDefaultPeriod({ value: coll[0].value, label: coll[0].label });
      }
    } catch (err) {
      if (err?.response?.status === 401) {
        history.push("/auth/login");
      }
      console.log(err);
    }
  };
  const getSubcollections = async (id) => {
    try {
      const response = await apiRequest({
        method: "get",
        url: `collection/list/${id}`,
      });
      if (response.status === 200) {
        const coll = [];
        response.data.files.map((elem) => {
          return coll.push({
            value: elem.id,
            label: elem.name,
          });
        });
        //console.log("SUBKOLEKIJE RESPONSE", response.data);
        setSubcollections([...coll]);

        // setDefaultPeriod({ value: coll[0].value, label: coll[0].label });
      }
    } catch (err) {
      if (err?.response?.status === 401) {
        history.push("/auth/login");
      }
      console.log(err);
    }
  };
  const checkForSubcategory = () => {};

  useEffect(() => {
    getAllPeriods();
    getAllCategories();
    getAllMaterials();
    getAllTypes();
    getAllCollections();
    item?.collection?.Parent !== null &&
      getSubcollections(item?.collection?.Parent?.id);
    item?.date_enrollment !== null &&
      item?.date_enrollment !== "" &&
      setDefaultDate(moment.utc(item?.date_enrollment)._d);
  }, []);

  return (
    <div className=" bg-white rounded mx-5 h-screen lg:px-10 pt-5 pb-24   ">
      {error}
      <SuccessModal
        open={successModal}
        title={"succesPhotoChange"}
        subtitle={"successPhotoSubtitle"}
        onConfirm={() => setSuccessModal(false)}
        onClose={() => setSuccessModal(false)}
      />
      {item != null ? (
        <div className="h-screen">
          <div className="w-full  flex row ">
            <div
              className="h-20 w-16 flex items-center cursor-pointer"
              onClick={() => setEditMode(false)}
            >
              <RiArrowLeftSLine color="black" className="w-10 h-10" />
            </div>
            <div className="lg:h-20 mt-5 mr-4 lg:mr-0 lg:mt-0  w-full flex flex-col lg:flex-row justify-end lg:items-center border-b-2 ">
              <div className="flex row mt-5 lg:mt-0">
                <div className="mr-5">
                  <Button onClick={setEditMode} text="cancel" invert />
                </div>
                <Button
                  text="saveChanges"
                  onClick={() => {
                    checkForm();
                    setChecking(true);
                  }}
                />
              </div>
            </div>
          </div>
          <div className="lg:pl-16 h-screen overflow-y-auto pt-10 lg:pr-5 px-4 lg:px-0 ">
            <div className=" flex flex-col lg:flex-row">
              <div className=" lg:w-4/5 mt-5 space-y-3 lg:mt-0">
                <TextInput
                  title="itemName"
                  obligatory
                  invalid={
                    checking &&
                    (productData.name === "" || productData.name === null)
                      ? true
                      : false
                  }
                  placeholder="Naziv predmeta"
                  value={productData.name}
                  onChange={(e) =>
                    setProductData({ ...productData, name: e.target.value })
                  }
                />
                <TextInput
                  obligatory
                  invalid={
                    checking &&
                    (productData.invNumber === "" ||
                      productData.invNumber === null)
                      ? true
                      : false
                  }
                  title="invItemNumber"
                  placeholder="6B7280"
                  value={productData.invNumber}
                  onChange={(e) =>
                    setProductData({
                      ...productData,
                      invNumber: e.target.value,
                    })
                  }
                />
                <div className="mt-5 flex">
                  <div className="lg:w-1/2">
                    <Dropdown
                      //onClick={() => setModalPeriodOpen(true)}

                      value={defaultCollection}
                      title="collections"
                      options={collections}
                      //defaultCollection={defaultPeriod}
                      onChange={(e) => {
                        setProductData(
                          !e.hasSubColl
                            ? {
                                ...productData,
                                collectionId: e.value,
                              }
                            : { ...productData }
                        );
                        setDefaultCollection({
                          value: e.value,
                          label: e.label,
                          hasSubColl: e.hasSubColl,
                        });
                        e.hasSubColl && getSubcollections(e.value);
                        console.log("VALUE", e);
                      }}
                    />
                  </div>

                  {defaultCollection.hasSubColl && (
                    <div className="lg:w-1/2 ml-5">
                      <Dropdown
                        //onClick={() => setModalPeriodOpen(true)}
                        title="subCollection"
                        options={subcollections}
                        value={defaultSubcollection}
                        onChange={(e) => {
                          setProductData(
                            defaultCollection.hasSubColl
                              ? {
                                  ...productData,
                                  collectionId: e.value,
                                }
                              : { ...productData }
                          );
                          setDefaultSubcollection({
                            value: e.value,
                            label: e.label,
                          });
                        }}
                      />
                    </div>
                  )}
                </div>

                <div className="mt-5">
                  <TextArea
                    description
                    obligatory
                    invalid={
                      checking &&
                      (productData.description === "" ||
                        productData.description === null)
                        ? true
                        : false
                    }
                    title="itemDescription"
                    placeholder="Opis predmeta"
                    value={productData.description}
                    onChange={(e) =>
                      setProductData({
                        ...productData,
                        description: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
            </div>

            <div className="grid lg:grid-cols-2 gap-5 mt-10">
              <TextInput
                invalid={
                  checking &&
                  (productData.dimension === "" ||
                    productData.dimension === null)
                    ? true
                    : false
                }
                obligatory
                title="dimension"
                placeholder="20x20cm"
                value={productData.dimension}
                onChange={(e) =>
                  setProductData({ ...productData, dimension: e.target.value })
                }
              />

              <TextInput
                obligatory
                title="preservation"
                invalid={
                  checking &&
                  (productData.preservation === "" ||
                    productData.preservation === null)
                    ? true
                    : false
                }
                value={productData.preservation}
                onChange={(e) =>
                  setProductData({
                    ...productData,
                    preservation: e.target.value,
                  })
                }
              />
            </div>

            <div className="mt-10">
              <div className="mb-5">
                <Text large black>
                  {t("origin")}
                </Text>
              </div>

              <TextInput
                placeholder="Bosna i Hercegovina"
                value={productData.origin}
                onChange={(e) =>
                  setProductData({ ...productData, origin: e.target.value })
                }
              />
            </div>

            <div className="grid lg:grid-cols-2 gap-5 mt-5">
              <TextArea
                obligatory
                title="whenAndHowFound"
                invalid={
                  checking &&
                  (productData.how_made === "" || productData.how_made === null)
                    ? true
                    : false
                }
                long
                value={productData.how_made}
                onChange={(e) =>
                  setProductData({
                    ...productData,
                    how_made: e.target.value,
                  })
                }
              />
              <TextArea
                title="whereFound"
                long
                value={productData.where_made}
                onChange={(e) =>
                  setProductData({
                    ...productData,
                    where_made: e.target.value,
                  })
                }
              />
            </div>

            <div className="mt-5 flex">
              <div className="lg:w-1/2">
                <Dropdown
                  //onClick={() => setModalPeriodOpen(true)}
                  cancel
                  onCancelClick={() => {
                    setProductData({ ...productData, ageId: null });
                    setDefaultPeriod(null);
                  }}
                  value={defaultPeriod}
                  title="period"
                  options={period}
                  //defaultCollection={defaultPeriod}
                  onChange={(e) => {
                    setProductData({ ...productData, ageId: e.value });
                    setDefaultPeriod({ value: e.value, label: e.label });
                  }}
                />
              </div>
              <div className="lg:w-1/2 ml-5">
                <Dropdown
                  cancel
                  onCancelClick={() => {
                    setProductData({ ...productData, categoryId: null });
                    setDefaultCategory(null);
                  }}
                  //onClick={() => setModalPeriodOpen(true)}
                  title="categories"
                  options={itemCategory}
                  value={defaultCategory}
                  onChange={(e) => {
                    setProductData({ ...productData, categoryId: e.value });
                    setDefaultCategory({ value: e.value, label: e.label });
                  }}
                />
              </div>
            </div>
            <div className="mt-5 flex">
              <div className="lg:w-1/2">
                <Dropdown
                  //onClick={() => setModalPeriodOpen(true)}
                  cancel
                  onCancelClick={() => {
                    setProductData({ ...productData, materialId: null });
                    setDefaultMaterial(null);
                  }}
                  title="materials"
                  options={materials}
                  value={defaultMaterial}
                  onChange={(e) => {
                    setProductData({ ...productData, materialId: e.value });
                    setDefaultMaterial({ value: e.value, label: e.label });
                  }}
                />
              </div>
              <div className="lg:w-1/2 ml-5">
                <Dropdown
                  //onClick={() => setModalPeriodOpen(true)}
                  cancel
                  onCancelClick={() => {
                    setProductData({ ...productData, speciesId: null });
                    setDefaultType(null);
                  }}
                  title="types"
                  options={types}
                  value={defaultType}
                  onChange={(e) => {
                    setProductData({ ...productData, speciesId: e.value });
                    setDefaultType({ value: e.value, label: e.label });
                  }}
                />
              </div>
            </div>

            <div className="mt-10">
              <Text large black>
                {t("museumInformation")}
              </Text>

              <div className="grid lg:grid-cols-2 gap-5 mt-5 mb-5">
                <DatePicker
                  selected={defaultDate}
                  title="entryDate"
                  newStartDate={item?.date_enrollment}
                  onChange={(e) => {
                    console.log("==DATE", e);
                    setProductData({
                      ...productData,
                      date_enrollment: e,
                    });
                    setDefaultDate(e);
                  }}
                />
                <TextInput
                  obligatory
                  invalid={
                    checking &&
                    (productData.accommodation === "" ||
                      productData.accommodation === null)
                      ? true
                      : false
                  }
                  title="accommodation"
                  placeholder="Muzej"
                  value={productData.accommodation}
                  onChange={(e) =>
                    setProductData({
                      ...productData,
                      accommodation: e.target.value,
                    })
                  }
                />
              </div>
            </div>

            <div className="mt-5">
              <TextArea
                title="restauration"
                value={productData.restauration}
                onChange={(e) =>
                  setProductData({
                    ...productData,
                    restauration: e.target.value,
                  })
                }
              />
            </div>
            <div className="mt-5">
              <div className="mb-1">
                <Text>{t("note")}</Text>
              </div>
              {/*<RichTextEditor
                value={textEditorValue}
                onChange={(e) =>
                  // setItemData({ ...itemData, note: e.toString("html") })
                  {
                    setTextEditorValue(e);
                    setProductData({
                      ...productData,
                      note: e.toString("html"),
                    });
                    //setTextEditorValue(e.toString("html"));
                  }
                }
              />*/}
              <ReactQuill
                value={textEditorValue}
                onChange={(description, delta, source, editor) => {
                  setTextEditorValue(description);
                  setProductData({ ...productData, note: description });
                }}
              />
            </div>
            <div className="mt-10 h-full">
              <Text large black>
                {t("gallery")}
              </Text>
              <div className="grid mt-8  h-48 grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-5 xl:gap-3 w-full xl:justify-items-stretch">
                {photos.length > 0 &&
                  photos.map((elem) => (
                    <div className="group xl:mt-0 w-full h-48  flex justify-center items-center relative">
                      <img
                        src={elem?.url}
                        className=" w-full object-cover h-48"
                      />

                      <div
                        className=" px-2 py-2 rounded-md  cursor-pointer opacity-0 group-hover:opacity-100 group-hover:bg-gray-400 bg-gray-200 absolute   items-center justify-center "
                        onClick={() => {
                          setProductData({ ...productData, url: elem?.url });
                          setSuccessModal(true);
                        }}
                      >
                        <Text white small>
                          Postavi kao naslovnu
                        </Text>
                      </div>
                      <div
                        className="w-6 h-6 rounded-full cursor-pointer bg-gray-400 absolute  flex items-center justify-center right-3 top-1"
                        onClick={() => deleteImage(elem.id)}
                      >
                        <IoClose color="black" className="w-5 h-5" />
                      </div>
                    </div>
                  ))}
                {imageUploading && (
                  <div className=" xl:mt-0 w-full h-48 space-y-3  flex flex-col justify-center items-center relative border-2 border-gray-300 border-dashed">
                    <Text small>Dodavanje slika...</Text>
                    <Loader small />
                  </div>
                )}
                <ImageUploading
                  multiple
                  onChange={addImages}
                  dataURLKey="data_url"
                >
                  {({ onImageUpload }) => (
                    <div
                      className="max-w-2xl rounded-lg  "
                      onClick={onImageUpload}
                    >
                      <div
                        className={`  ${
                          photos.length === 0 || photos.length % 2 === 0
                            ? " h-48"
                            : "h-full"
                        }`}
                      >
                        <div className="flex  h-full  items-center justify-center w-full">
                          <label className="flex flex-col w-full  h-full justify-center  border-2 border-gray-300 border-dashed hover:bg-gray-100 hover:border-gray-300">
                            <div className="flex flex-col items-center justify-center ">
                              <svg
                                className="mx-auto h-12 w-12 text-gray-400"
                                stroke="currentColor"
                                fill="none"
                                viewBox="0 0 48 48"
                                aria-hidden="true"
                              >
                                <path
                                  d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                                  strokeWidth={2}
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                              <div className="flex justify-center text-sm text-gray-600">
                                <label className="relative cursor-pointer  rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none">
                                  <p>{t("uploadFile")}</p>
                                </label>
                              </div>
                              <p className="text-xs text-gray-500 text-center">
                                {t("fileType")}
                              </p>
                            </div>
                          </label>
                        </div>
                      </div>
                    </div>
                  )}
                </ImageUploading>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className=" bg-white rounded mx-5 h-screen px-10 pt-5 pb-12 flex justify-center items-center ">
          <Loader />
        </div>
      )}
    </div>
  );
};

export default EditMode;
