/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { useDispatch } from "react-redux";
import { AllItems } from "containers";
import apiRequest from "helpers/apiRequest";
import { Text, Dropdown } from "components";

import { Loader, Popup } from "components";

const AllItemsDashboard = () => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const [items, setItems] = useState(null);
  const [allItems, setAllItems] = useState(null);

  const [page, setPage] = useState(1);
  const [pagination, setPagination] = useState(null);
  const [pageSize, setPageSize] = useState(20);

  const [searchTerm, setSearchTerm] = useState("");
  const [collections, setCollections] = useState([]);
  const [selectedCollection, setSelectedCollection] = useState(null);
  const [selectedCollHasSub, setSelectedCollHasSub] = useState(null);
  const [subcollections, setSubcollections] = useState([]);
  const [selectedSubcollection, setSelectedSubcollection] = useState(null);
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [periods, setPeriods] = useState([]);
  const [selectedPeriod, setSelectedPeriod] = useState(null);
  const [materials, setMaterials] = useState([]);
  const [selectedMaterial, setSelectedMaterial] = useState(null);
  const [types, setTypes] = useState([]);
  const [selectedType, setSelectedType] = useState(null);
  const [defaultCollection, setDefaultCollection] = useState(null);
  const [defaultSubcollection, setDefaultSubcollection] = useState(null);
  const [defaultCategory, setDefaultCategory] = useState(null);
  const [defaultPeriod, setDefaultPeriod] = useState(null);
  const [defaultMaterial, setDefaultMaterial] = useState(null);
  const [defaultType, setDefaultType] = useState(null);

  const [showPolicyModal, setShowPolicyModal] = useState(
    localStorage.getItem("userToken") || localStorage.getItem("agreedPrivacy")
      ? false
      : true
  );

  const getAllItems = async () => {
    try {
      const response = await apiRequest({
        method: "get",
        url: `item?page=${page}&pageSize=${pageSize}&orderBy=createdAt&orderType=DESC&search=${searchTerm}&${
          selectedPeriod !== null ? `ageId=${selectedPeriod}` : ""
        }&${
          selectedMaterial !== null ? `materialId=${selectedMaterial}` : ""
        }&${selectedType !== null ? `speciesId=${selectedType}` : ""}&${
          selectedCategory !== null ? `categoryId=${selectedCategory}` : ""
        }&${
          selectedCollection !== null
            ? `collectionId=${selectedCollection}`
            : ""
        }&${
          selectedSubcollection !== null
            ? `subcollectionId=${selectedSubcollection}`
            : ""
        }`,
      });
      if (response.status === 200) {
        setItems(response.data);

        //console.log("==ALL ITEMS RESPONSE==", response.data);
      }
    } catch (err) {
      if (err?.response?.status === 401) {
        history.push("/auth/login");
      }
      console.log(err);
    }
  };

  const getItemsWithoutPagination = async () => {
    try {
      const response = await apiRequest({
        method: "get",
        url: `item?page=1&pageSize=4&orderBy=createdAt&orderType=ASC&search=&collectionId=2&ageId=22`,
      });
      if (response.status === 200) {
        setAllItems(response.data);
      }
    } catch (err) {
      if (err?.response?.status === 401) {
        history.push("/auth/login");
      }
      console.log(err);
    }
  };

  const changePage = (e) => {
    setPage(e.selected + 1);
  };

  const getAllCollections = async () => {
    try {
      const response = await apiRequest({
        method: "get",
        url: "collection?page=1&pageSize=1000000",
      });
      if (response.status === 200) {
        const coll = [];
        response.data.files.map((elem) => {
          return coll.push({
            value: elem.id,
            label: elem.name,
            hasSubColl: elem.hasSubcollection,
          });
        });
        setCollections([...coll]);
      }
    } catch (err) {
      if (err?.response?.status === 401) {
        history.push("/auth/login");
      }
      console.log(err);
    }
  };
  const getSubcollectionsOfSingleCollection = async () => {
    try {
      const response = await apiRequest({
        method: "get",
        url: `collection/list/${selectedCollection}`,
      });
      if (response.status === 200) {
        const coll = [];
        response.data.files.map((elem) => {
          return coll.push({
            value: elem.id,
            label: elem.name,
          });
        });
        setSubcollections([...coll]);
      }
    } catch (err) {
      if (err?.response?.status === 401) {
        history.push("/auth/login");
      }
      console.log(err);
    }
  };

  const getAllCategories = async () => {
    try {
      const response = await apiRequest({
        method: "get",
        url: "category",
      });
      if (response.status === 200) {
        const coll = [];
        response.data.files.map((elem) => {
          return coll.push({
            value: elem.id,
            label: elem.name,
          });
        });
        setCategories([...coll]);
      }
    } catch (err) {
      if (err?.response?.status === 401) {
        history.push("/auth/login");
      }
      console.log(err);
    }
  };
  const getAllPeriods = async () => {
    try {
      const response = await apiRequest({
        method: "get",
        url: "age",
      });
      if (response.status === 200) {
        const coll = [];
        response.data.files.map((elem) => {
          return coll.push({
            value: elem.id,
            label: elem.name,
          });
        });
        setPeriods([...coll]);
      }
    } catch (err) {
      if (err?.response?.status === 401) {
        history.push("/auth/login");
      }
      console.log(err);
    }
  };
  const getAllMaterials = async () => {
    try {
      const response = await apiRequest({
        method: "get",
        url: "material",
      });
      if (response.status === 200) {
        const coll = [];
        response.data.files.map((elem) => {
          return coll.push({
            value: elem.id,
            label: elem.name,
          });
        });
        setMaterials([...coll]);
      }
    } catch (err) {
      if (err?.response?.status === 401) {
        history.push("/auth/login");
      }
      console.log(err);
    }
  };
  const getAllTypes = async () => {
    try {
      const response = await apiRequest({
        method: "get",
        url: "species",
      });
      if (response.status === 200) {
        const coll = [];
        response.data.files.map((elem) => {
          return coll.push({
            value: elem.id,
            label: elem.name,
          });
        });
        setTypes([...coll]);
      }
    } catch (err) {
      if (err?.response?.status === 401) {
        history.push("/auth/login");
      }
      console.log(err);
    }
  };
  //console.log("=== collection==", collections);

  useEffect(() => {
    getAllItems();
  }, [
    page,
    searchTerm,
    selectedCategory,
    selectedMaterial,
    selectedPeriod,
    selectedType,
    selectedCollection,
    searchTerm,
    selectedSubcollection,
  ]);

  useEffect(() => {
    selectedCollHasSub && getSubcollectionsOfSingleCollection();
  }, [selectedCollection, defaultCollection]);

  useEffect(() => {
    getItemsWithoutPagination();
    getAllCategories();
    getAllMaterials();
    getAllPeriods();
    getAllTypes();
    getAllCollections();
  }, []);

  //useEffect(() => {setShowPolicyModal(true)},[])

  return (
    <div
      className={`bg-blue-100 h-screen w-full pt-5 pb-5 overflow-hidden relative ${
        loading && "flex items-center justify-center"
      }`}
    >
      {showPolicyModal && (
        <Popup
          open={showPolicyModal}
          onClose={() => {
            localStorage.setItem("agreedPrivacy", true);
            setShowPolicyModal(false);
          }}
          warning
        />
      )}
      {loading ? (
        <Loader />
      ) : (
        <div className="flex row  h-full md:h-screen overflow-y-auto md:overflow-hidden relative">
          <div className="w-full h-full bg-blue-100 overflow-y-auto md:overflow-hidden">
            <div className=" flex md:space-x-1 flex-col md:flex-row md:pb-96 h-full mx-5 md:mx-0 md:h-screen overflow-x-hidden overflow-y-auto md:overflow-hidden ">
              <div className="hidden md:block lg:pr-10 w-full ">
                <AllItems
                  dashboard
                  items={items}
                  allItems={allItems}
                  changePage={changePage}
                  //category={2}
                  // subcategory={1}
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </div>

              <div className=" hidden md:flex flex-col  pr-5 w-1/3 h-screen mx-2 mt-5 rounded overflow-y-auto ">
                <div className=" h-full bg-white rounded pb-20  py-5  px-6 pt-6">
                  <Text>{t("filter")}</Text>
                  <div className="mt-8 mb-3 relative z-1">
                    <Dropdown
                      cancel
                      onCancelClick={() => {
                        setSelectedCollection(null);
                        setDefaultCollection(null);
                        setSelectedSubcollection(null);
                        setDefaultSubcollection(null);
                      }}
                      value={defaultCollection}
                      title="collections"
                      options={collections}
                      onChange={(e) => {
                        setSelectedCollection(e.value);
                        setSelectedCollHasSub(e.hasSubColl);
                        setDefaultCollection({
                          value: e.value,
                          label: e.label,
                          hasSubColl: e.hasSubcollection,
                        });
                      }}
                    />
                  </div>
                  {selectedCollHasSub &&
                    selectedCollection !== null &&
                    defaultCollection !== null && (
                      <div className=" mb-3 relative z-1">
                        <Dropdown
                          cancel
                          onCancelClick={() => {
                            setSelectedSubcollection(null);
                            setDefaultSubcollection(null);
                          }}
                          value={defaultSubcollection}
                          title="subCollection"
                          options={subcollections}
                          onChange={(e) => {
                            setSelectedSubcollection(e.value);
                            setDefaultSubcollection({
                              value: e.value,
                              label: e.label,
                            });
                          }}
                        />
                      </div>
                    )}
                  <div className=" mb-3 relative z-1">
                    <Dropdown
                      cancel
                      onCancelClick={() => {
                        setSelectedCategory(null);
                        setDefaultCategory(null);
                      }}
                      value={defaultCategory}
                      title="categories"
                      options={categories}
                      onChange={(e) => {
                        setSelectedCategory(e.value);
                        setDefaultCategory({
                          value: e.value,
                          label: e.label,
                        });
                      }}
                    />
                  </div>
                  <div className="mb-3 relative">
                    <Dropdown
                      cancel
                      onCancelClick={() => {
                        setSelectedPeriod(null);
                        setDefaultPeriod(null);
                      }}
                      value={defaultPeriod}
                      title="period"
                      options={periods}
                      onChange={(e) => {
                        setSelectedPeriod(e.value);
                        setDefaultPeriod({
                          value: e.value,
                          label: e.label,
                        });
                      }}
                    />
                  </div>
                  <div className="mb-3 relative">
                    <Dropdown
                      cancel
                      onCancelClick={() => {
                        setSelectedMaterial(null);
                        setDefaultMaterial(null);
                      }}
                      value={defaultMaterial}
                      title="materials"
                      options={materials}
                      onChange={(e) => {
                        setSelectedMaterial(e.value);
                        setDefaultMaterial({
                          value: e.value,
                          label: e.label,
                        });
                      }}
                    />
                  </div>
                  <div className="mb-3 relative">
                    <Dropdown
                      cancel
                      onCancelClick={() => {
                        setSelectedType(null);
                        setDefaultType(null);
                      }}
                      value={defaultType}
                      title="types"
                      options={types}
                      onChange={(e) => {
                        setSelectedType(e.value);
                        setDefaultType({
                          value: e.value,
                          label: e.label,
                        });
                      }}
                    />
                  </div>
                </div>
              </div>
              {/**THESE ITEMS WILL HIDE WHEN SCREEN IS BIGGER THAN MD AND SHOW OTHERVISE */}
              <div className="flex md:hidden   sm:pr-0 md:pr-6 md:pl-5  flex-col w-full md:w-1/3 h-screen md:mx-2 xl:mt-5 rounded ">
                <div className="h-auto xl:h-full bg-white rounded px-5 pt-2   md:px-6 ">
                  <Text>{t("filter")}</Text>
                  <div className="mt-4 mb-3 relative z-1">
                    <Dropdown
                      cancel
                      onCancelClick={() => {
                        setSelectedCollection(null);
                        setDefaultCollection(null);
                        setSelectedSubcollection(null);
                        setDefaultSubcollection(null);
                      }}
                      value={defaultCollection}
                      title="collections"
                      options={collections}
                      onChange={(e) => {
                        setSelectedCollection(e.value);
                        setSelectedCollHasSub(e.hasSubColl);
                        setDefaultCollection({
                          value: e.value,
                          label: e.label,
                          hasSubColl: e.hasSubcollection,
                        });
                      }}
                    />
                  </div>
                  {selectedCollHasSub &&
                    selectedCollection !== null &&
                    defaultCollection !== null && (
                      <div className=" mb-3 relative z-1">
                        <Dropdown
                          cancel
                          onCancelClick={() => {
                            setSelectedSubcollection(null);
                            setDefaultSubcollection(null);
                          }}
                          value={defaultSubcollection}
                          title="subCollection"
                          options={subcollections}
                          onChange={(e) => {
                            setSelectedSubcollection(e.value);
                            setDefaultSubcollection({
                              value: e.value,
                              label: e.label,
                            });
                          }}
                        />
                      </div>
                    )}
                  <div className=" mb-3 relative z-1">
                    <Dropdown
                      cancel
                      onCancelClick={() => {
                        setSelectedCategory(null);
                        setDefaultCategory(null);
                      }}
                      value={defaultCategory}
                      title="categories"
                      options={categories}
                      onChange={(e) => {
                        setSelectedCategory(e.value);
                        setDefaultCategory({
                          value: e.value,
                          label: e.label,
                        });
                      }}
                    />
                  </div>
                  <div className="mb-3 relative">
                    <Dropdown
                      cancel
                      onCancelClick={() => {
                        setSelectedPeriod(null);
                        setDefaultPeriod(null);
                      }}
                      value={defaultPeriod}
                      title="period"
                      options={periods}
                      onChange={(e) => {
                        setSelectedPeriod(e.value);
                        setDefaultPeriod({
                          value: e.value,
                          label: e.label,
                        });
                      }}
                    />
                  </div>
                  <div className="mb-3 relative">
                    <Dropdown
                      cancel
                      onCancelClick={() => {
                        setSelectedMaterial(null);
                        setDefaultMaterial(null);
                      }}
                      value={defaultMaterial}
                      title="materials"
                      options={materials}
                      onChange={(e) => {
                        setSelectedMaterial(e.value);
                        setDefaultMaterial({
                          value: e.value,
                          label: e.label,
                        });
                      }}
                    />
                  </div>
                  <div className="mb-3 relative">
                    <Dropdown
                      cancel
                      onCancelClick={() => {
                        setSelectedType(null);
                        setDefaultType(null);
                      }}
                      value={defaultType}
                      title="types"
                      options={types}
                      onChange={(e) => {
                        setSelectedType(e.value);
                        setDefaultType({
                          value: e.value,
                          label: e.label,
                        });
                      }}
                    />
                  </div>
                </div>
              </div>

              <div className="md:hidden md:pr-10 ">
                <AllItems
                  dashboard
                  items={items}
                  allItems={allItems}
                  changePage={changePage}
                  //category={2}
                  // subcategory={1}
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AllItemsDashboard;
