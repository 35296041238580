import React from "react";
import { Bar } from "react-chartjs-2";

const BarChart = ({ yearStats, currentYear, pastYear }) => {
  const firstYear = [];
  const secondYear = [];

  for (var key in yearStats) {
    for (var i = 0; i < yearStats[key].length; i++) {
      if (key === "year2") {
        secondYear[yearStats[key][i].month - 1] = yearStats[key][i].countitem;
      }
      firstYear[yearStats[key][i].month - 1] = yearStats[key][i].countitem;
    }
  }

  console.log("YEAR STATS", yearStats);
  const data = {
    labels: [
      "JAN",
      "FEB",
      "MAR",
      "APR",
      "MAJ",
      "JUN",
      "JUL",
      "AUG",
      "SEP",
      "OKT",
      "NOV",
      "DEC",
    ],
    datasets: [
      {
        label: currentYear,
        data: firstYear,
        backgroundColor: "#EE63A6",
      },
      {
        label: pastYear,
        data: secondYear,
        backgroundColor: "#4C51C0",
      },
    ],
  };

  const options = {
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    },
    legend: {
      display: false,
    },
  };

  return (
    <div>
      <Bar data={data} options={options} />
    </div>
  );
};

export default BarChart;
