/* eslint-disable jsx-a11y/img-redundant-alt */
import React from "react";
import { useTranslation } from "react-i18next";
import { MdCollectionsBookmark } from "react-icons/md";
import { BsCollectionFill } from "react-icons/bs";
import { AiFillGolden } from "react-icons/ai";
import moment from "moment";
import "moment/locale/bs";

const UserStatCard = ({
  cardName,
  bigNumber,
  collections,
  subcollections,
  up,
  percents,
  onClick,
}) => {
  const { t } = useTranslation();

  return (
    <div
      onClick={onClick}
      className={`flex flex-col p-2 w-full rounded-3x1 m-0  ${
        !subcollections && "cursor-pointer hover:opacity-80"
      } `}
      style={{
        borderRadius: "4px",
        boxShadow:
          "0px 10px 15px rgba(0, 0, 0, 0.1), 0px 4px 6px rgba(0, 0, 0, 0.05)",
      }}
    >
      <div className="flex justify-between">
        <div className="m-0 p-0">
          <p className="text-xs font-bold text-grayish">
            {t(cardName).toUpperCase()}
          </p>
          <p className="text-lg lg:text-xl">{bigNumber}</p>
        </div>
        <div className="w-10 h-10 rounded-full bg-blue-primary flex justify-center items-center shadow-md">
          {collections ? (
            <MdCollectionsBookmark className="w-5 h-5" color="white" />
          ) : subcollections ? (
            <BsCollectionFill className="w-5 h-5" color="white" />
          ) : (
            <AiFillGolden className="w-5 h-5" color="white" />
          )}
        </div>
      </div>
      <div className="flex justify-between">
        {/* <div className='flex items-center'>
                    <img src={up ? ArrowUp : ArrowDown} alt='arrow-up'/>
                    <p className={`${up ? 'text-greenCard' : 'text-redCard'} text-sm ml-1`}>{percents}</p>
                </div> */}
        <div>
          <p className="text-grayish text-sm capitalize">
            {(moment.locale("bs"), moment().format("MMMM YYYY."))}
          </p>
        </div>
      </div>
    </div>
  );
};

export default UserStatCard;
