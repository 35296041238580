import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";
import { AddButton } from "components";
import Error from "components/ErrorAlert";
import apiRequest from "helpers/apiRequest";
import Tooltip from "components/Tooltip";
import { RiDeleteBin6Line } from "react-icons/ri";
import Pagination from "components/Pagination";
import AddMaterialModal from "components/AddMaterialModal";
import { Modal } from "react-responsive-modal";
const SettingsMaterials = ({}) => {
  const [materials, setMaterials] = useState([]);
  const [pagination, setPagination] = useState(null);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(3);
  const [orderType, setOrderType] = useState("DESC");
  const [orderBy, setOrderBy] = useState("createdAt");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [modalMaterialOpen, setModalMaterialOpen] = useState(false);
  const { t } = useTranslation();
  const history = useHistory();
  const [openErrorModal, setOpenErrorModal] = useState(false);

  const getMaterials = async () => {
    try {
      const response = await apiRequest({
        method: "get",
        url: `material?page=${page}&pageSize=${pageSize}&orderBy=${orderBy}&orderType=${orderType}`,
      });
      if (response.status === 200) {
        // console.log("materials", response.data.files);
        setMaterials(response.data.files);
        setPagination(response.data);
        //console.log(response.data.files);
        setLoading(false);
      }
    } catch (err) {
      if (err?.response?.status === 401) {
        history.push("/auth/login");
      }
      createError(err.message);
      setLoading(false);
    }
  };

  const changePage = (page) => {
    setPage(page.selected + 1);
  };

  const deleteMaterial = async (id) => {
    try {
      const response = await apiRequest({
        method: "delete",
        url: `material/${id}`,
      });
      if (response.status === 200) {
        getMaterials();
      }
    } catch (err) {
      if (err?.response?.status === 401) {
        history.push("/auth/login");
      }
      if (err?.response?.status === 500) {
        setOpenErrorModal(true);
      }
      setOpenErrorModal(true);
      //console.log(err);
    }
    //console.log(id);
  };
  const createError = (msg) => {
    setError(<Error errorText={msg} />);
    setTimeout(() => {
      setError(false);
    }, 3000);
  };

  useEffect(() => {
    getMaterials();
  }, [page, pageSize, orderBy, orderType]);

  useEffect(() => {
    materials?.length === 0 && page > 0 && setPage(page - 1);
  }, [materials]);
  return (
    <>
      <AddMaterialModal
        open={modalMaterialOpen}
        confirmButtonText={"done"}
        onClose={() => {
          setModalMaterialOpen(false);
          getMaterials();
        }}
        onConfirm={() => {
          setModalMaterialOpen(false);
          getMaterials();
        }}
      />
      <Modal open={openErrorModal} onClose={() => setOpenErrorModal(false)}>
        <div className="w-full  max-w-lg  px-5 py-5 relative mx-auto my-auto bg-white ">
          <div>
            <div className="text-center  flex flex-col justify-center items-center">
              <p className="text-lg font-medium  mt-5 ">
                Nije moguce obrisati materijal!
              </p>
              <p className="text-sm text-gray-400  mt-3 mb-5 ">
                Ovaj materijal je već pridružen predmetu.
              </p>
            </div>
            <div className="  mt-2 text-center space-x-4 md:block">
              <button
                className=" w-full bg-blue-primary border border-red-500 px-5 py-2 text-sm shadow-sm font-medium tracking-wider text-white rounded-lg hover:shadow-lg hover:bg-blue-secondary"
                onClick={() => setOpenErrorModal(false)}
              >
                {t("continue")}
              </button>
            </div>
          </div>
        </div>
      </Modal>
      <div className="flex flex-col lg:flex-row">
        <div className="mt-10 w-full lg:w-2/6">
          <h2 className="text-2xl font-inter mb-3 lg:mb-0">{t("materials")}</h2>
          <p className="mt-5 text-smallGrey">{t("materialsSubtitle")}</p>
        </div>
        <div className="w-full pt-10 lg:pl-40 flex lg:items-center lg:justify-center">
          <AddButton
            onClick={() => setModalMaterialOpen(true)}
            text="addNewMaterial"
            invert
            limitedWidth
          />
        </div>
      </div>
      {materials?.length > 0 && (
        <div>
          <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8 mt-10">
            <div className="py-2 align-middle inline-block w-full sm:px-6 lg:px-8">
              <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                <table className="w-full divide-y divide-gray-200">
                  <thead className="bg-gray-50">
                    <tr>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        {t("periodName")}
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        {t("additionalInfo")}
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      ></th>
                    </tr>
                  </thead>
                  {materials.map((elem) => (
                    <tbody className="bg-white divide-y divide-gray-200">
                      <tr className=" cursor-pointer hover:bg-blue-50">
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="text-sm text-gray-900">
                            {elem.name}
                          </div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="text-sm text-gray-900">
                            {elem.additionalInfo}
                          </div>
                        </td>
                        {localStorage.getItem("userToken") && (
                          <td className="px-6 py-4 whitespace-nowrap flex justify-end">
                            <div
                              className=""
                              onClick={() => {
                                deleteMaterial(elem.id);
                              }}
                            >
                              <Tooltip
                                tooltipText="delete"
                                element={
                                  <RiDeleteBin6Line
                                    color="red"
                                    className="w-5 h-5 cursor-pointer bc-transparent "
                                  />
                                }
                              />
                            </div>
                          </td>
                        )}
                      </tr>
                    </tbody>
                  ))}
                </table>
              </div>
            </div>
          </div>
          <div className="mt-5">
            <Pagination
              pages={pagination !== null && pagination}
              changePage={changePage}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default SettingsMaterials;
