/* eslint-disable import/no-anonymous-default-export */
import { Modal } from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import { useTranslation } from "react-i18next";
import infoImg from "assets/info.svg";
const CustomModal = ({
  open,
  onClose,
  onConfirm,
  confirmationText,
  confirmationDesc,
  confirmButtonText,
  info,
  cancelText,
  itemToDelete,
}) => {
  const { t } = useTranslation();

  return (
    <Modal open={open} onClose={onClose}>
      <div className="w-full  max-w-lg p-5 relative mx-auto my-auto bg-white ">
        <div className="">
          <div className="text-center p-5 flex-auto justify-center">
            {info ? (
              <img
                src={infoImg}
                alt="Info"
                class="w-10 h-10 md:w-12 md:h-12 flex items-center text-red-500 mx-auto"
                viewBox="0 0 52 52"
                fill="currentColor"
              />
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="w-16 h-16 flex items-center text-red-500 mx-auto"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                  clipRule="evenodd"
                />
              </svg>
            )}

            <h2 className="text-xl font-bold py-4 px-2 ">
              {t(confirmationText)}
              {""} {itemToDelete} {!!itemToDelete && "?"}
            </h2>
            <p className="text-sm text-gray-500 px-8">{t(confirmationDesc)}</p>
          </div>
          <div className="p-3   space-y-2  mt-2 text-center  md:block">
            <button
              className="mb-2 md:mb-0 w-full   px-5 py-2 text-sm shadow-sm font-medium tracking-wider  rounded-lg hover:shadow-lg bg-blue-primary text-white"
              onClick={onConfirm}
            >
              {t(confirmButtonText)}
            </button>
            <button
              className="mb-2 md:mb-0 w-full bg-white px-5 py-2 text-sm shadow-sm font-medium tracking-wider border text-gray-600 rounded-lg hover:shadow-lg hover:bg-gray-100"
              onClick={onClose}
            >
              {cancelText ? cancelText : t("cancel")}
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default CustomModal;
