import React from "react";
import { useTranslation } from "react-i18next";

import { Text } from "components";

const TextInput = ({
  title,
  placeholder,
  value,
  onChange,
  invalid,
  disabled,
  onEnter,
  obligatory,
}) => {
  const { t } = useTranslation();
  return (
    <div className="px-1">
      <div className="mb-1 flex flex-row">
        <Text>{t(title)}</Text>
        {obligatory && <p className="ml-4 text-red text-lg">*</p>}
      </div>

      <div
        className={` h-10 flex items-center rounded-lg  border ${
          invalid && "border-red"
        }`}
      >
        <input
          autoComplete="off"
          id="inputID"
          placeholder={t(placeholder)}
          className="h-full w-full px-5   rounded-lg"
          value={value?.charAt(0).toUpperCase() + value?.slice(1)}
          disabled={disabled}
          onChange={onChange}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              onEnter();
            }
          }}
        />
      </div>
    </div>
  );
};

export default TextInput;
