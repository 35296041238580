// file deepcode ignore DuplicateCaseBody: <comment the reason here>
import React from "react";
import { Page, Text, View, Document, StyleSheet } from "@react-pdf/renderer";
import { useTranslation } from "react-i18next";
import moment from "moment";

const BORDER_COLOR = "#E5E7EB";
const BORDER_STYLE = "solid";
const COL1_WIDTH = 30;
const COLN_WIDTH = (100 - COL1_WIDTH) / 2;
const styles = StyleSheet.create({
  body: {
    padding: 10,
  },
  table: {
    display: "table",
    width: "auto",
  },
  tableRow: {
    margin: "auto",
    flexDirection: "row",
  },
  tableCol1Header: {
    width: COL1_WIDTH + "%",
    borderStyle: BORDER_STYLE,
    borderColor: BORDER_COLOR,
    borderBottomColor: "#E5E7EB",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    backgroundColor: "#F9FAFB",
    padding: "5 0",
  },
  tableColHeader: {
    width: COLN_WIDTH + "%",
    borderStyle: BORDER_STYLE,
    borderColor: BORDER_COLOR,
    borderBottomColor: "#E5E7EB",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    backgroundColor: "#F9FAFB",
    padding: "5 0",
  },
  tableCol1: {
    width: COL1_WIDTH + "%",
    borderStyle: BORDER_STYLE,
    borderColor: BORDER_COLOR,
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableCol: {
    width: COLN_WIDTH + "%",
    borderStyle: BORDER_STYLE,
    borderColor: BORDER_COLOR,
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableCellHeader: {
    margin: 5,
    fontSize: 10,
    fontWeight: 500,
    color: "#6B7280",
  },
  tableCell: {
    margin: 5,
    fontSize: 10,
  },
  heading: {
    textAlign: "center",
    marginBottom: 20,
  },
  headingText: {
    fontWeight: 700,
  },
});

const ItemsTablePDF = ({ data }) => {
  const { t } = useTranslation();
  return (
    <Document>
      <Page style={styles.body}>
        <View style={styles.heading}>
          <Text style={styles.headingText}>{t("items").toUpperCase()}</Text>
        </View>
        <View style={styles.table}>
          <View style={styles.tableRow}>
            <View style={styles.tableColHeader}>
              <Text style={styles.tableCellHeader}>
                {t("itemName").toUpperCase()}
              </Text>
            </View>
            <View style={styles.tableColHeader}>
              <Text style={styles.tableCellHeader}>
                {t("entryDate").toUpperCase()}
              </Text>
            </View>
            <View style={styles.tableColHeader}>
              <Text style={styles.tableCellHeader}>
                {" "}
                {t("invItemNumber").toUpperCase()}
              </Text>
            </View>
          </View>
          {data?.map((elem) => (
            <View style={styles.tableRow}>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{elem.name}</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>
                  {elem.date_enrollment !== ""
                    ? moment(elem.date_enrollment).format("DD.MM.YYYY")
                    : "/"}
                </Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{elem.invNumber}</Text>
              </View>
            </View>
          ))}
        </View>
      </Page>
    </Document>
  );
};

export default ItemsTablePDF;
