/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { useDispatch } from "react-redux";

import apiRequest from "helpers/apiRequest";

import Chart from "components/LineChart";
import BarChart from "components/BarChart";
import CollectionTable from "components/CollectionTable";
import UserStatCard from "components/UserStatCard";
import { Loader } from "components";
import { openError, closeError } from "redux/actions/errorAction";

import StatsImage from "assets/stats.png";
import UserImage from "assets/users.png";
import Error from "components/ErrorAlert";
import moment from "moment";
const Analitycs = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(true);
  const [statsCount, setStatsCount] = useState(null);
  const [yearStats, setYearStats] = useState([]);
  const [collectionData, setCollectionData] = useState([]);
  const [error, setError] = useState(null);

  const previousYear = moment()?.subtract(1, "years")?.format("YYYY");
  const currentYear = moment()?.format("YYYY");
  console.log("CURRENT YEAR", previousYear, currentYear);

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(5);

  const getAnalitycData = async () => {
    try {
      const statisticCount = await apiRequest({
        method: "get",
        url: "statistic/count",
      });
      const yearStatistic = await apiRequest({
        method: "get",
        url: `statistic`,
      });
      const collectionData = await apiRequest({
        method: "get",
        url: `collection?page=${page}&pageSize=${pageSize}&orderBy=${"createdAt"}&orderType=${"DESC"}`,
      });
      if (
        statisticCount.status ||
        yearStatistic.status === 200 ||
        collectionData.status === 200
      ) {
        setStatsCount(statisticCount.data);
        setYearStats(yearStatistic.data);
        setCollectionData(collectionData.data);
        setLoading(false);
      }
    } catch (err) {
      if (err?.response?.status === 401) {
        history.push("/auth/login");
      }
      setLoading(false);
      createError(err.message);
    }
  };

  const createError = (msg) => {
    setError(<Error errorText={msg} />);

    setTimeout(() => {
      setError(null);
    }, 3000);
  };
  console.log("=collection data===", collectionData);
  useEffect(() => {
    getAnalitycData();
  }, [page]);
  return (
    <div
      className={`bg-blue-100 h-screen w-full pt-5 pb-5 overflow-hidden relative ${
        loading && "flex items-center justify-center"
      }`}
    >
      {loading ? (
        <Loader />
      ) : (
        <div className="bg-white mt-2 rounded mx-5 h-screen px-5 lg:px-10 overflow-y-scroll pt-10 pb-12 relative">
          {error}
          <div
            className="
                        flex 
                        flex-col 
                        space-y-5 
                        lg:space-y-0
                        mb-10 
                        lg:grid 
                        lg:grid-cols-2 
                        lg:gap-x-4 
                        lg:gap-y-4
                        xl:flex
                        xl:flex-row"
          >
            <UserStatCard
              onClick={() => {
                history.push("/admin/collections");
                localStorage.setItem("activeSidebarItem", "collections");
              }}
              cardName="collections"
              bigNumber={statsCount?.numberOfCollection}
              collections
            />
            <UserStatCard
              cardName="subCollection"
              bigNumber={statsCount?.numberOfSubcollection}
              subcollections
            />
            <UserStatCard
              onClick={() => {
                history.push("/admin/allItems");
                localStorage.setItem("activeSidebarItem", "allItems");
              }}
              cardName="items"
              bigNumber={statsCount?.numberOfItem}
            />
          </div>
          {/*<div
            className="w-full p-4"
            style={{ backgroundColor: "#0369A1", borderRadius: "10px" }}
          >
            <div className="text-white">
              <p className="text-xs">{t("stats")}</p>
              <p className="text-lg">{t("numOfVisit").toUpperCase()}</p>
            </div>
            <Chart />
         </div>*/}
          <div className="flex flex-col lg:flex-row mt-10 space-y-4 lg:space-y-0 lg:space-x-4 ">
            <div
              className="w-full xl:w-1/2 p-2"
              style={{
                boxShadow:
                  "0px 10px 15px rgba(0, 0, 0, 0.1), 0px 4px 6px rgba(0, 0, 0, 0.05)",
                borderRadius: "5px",
              }}
            >
              <div>
                <p className="text-xs text-grayish">
                  {t("total").toUpperCase()}
                </p>
                <p className="text-lg lg:text-xl mb-5">
                  {t("itemsAddedPastCurrentYear")}
                </p>
              </div>
              <BarChart
                yearStats={yearStats}
                currentYear={currentYear.toString()}
                pastYear={previousYear.toString()}
              />
            </div>
            <div
              onClick={() => {
                history.push("/admin/collections");
                localStorage.setItem("activeSidebarItem", "collections");
              }}
              className="w-full xl:w-1/2 p-4 m-0 cursor-pointer hover:opacity-80"
              style={{
                boxShadow:
                  "0px 10px 15px rgba(0, 0, 0, 0.1), 0px 4px 6px rgba(0, 0, 0, 0.05)",
                borderRadius: "5px",
              }}
            >
              <div>
                <p className="text-xs text-grayish">
                  {t("total").toUpperCase()}
                </p>
                <p className="text-lg lg:text-xl">{t("collections")}</p>
              </div>
              {collectionData?.files?.length === 0 ? (
                <div className="w-full  flex justify-center items-center ">
                  <p className="text-gray-300 mt-36"> {t("noItems")}</p>
                </div>
              ) : (
                <CollectionTable
                  collectionData={collectionData}
                  setPage={setPage}
                />
              )}
            </div>
          </div>
          <div style={{ height: "100px" }}></div>
        </div>
      )}
    </div>
  );
};

export default Analitycs;
