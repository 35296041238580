import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Pagination from "components/Pagination";
import { AddButton, Loader, Button } from "components";
import { Item } from ".";
import { RiArrowLeftSLine } from "react-icons/ri";
import { PDFDownloadLink } from "@react-pdf/renderer";
import ItemsTablePDF from "components/ItemsTablePDF";
import { AiOutlineSearch } from "react-icons/ai";

const AllItems = ({
  items,
  changePage,
  category,
  subcategory,
  allItems,
  onChange,
  value,
  location,
  dashboard,
}) => {
  const history = useHistory();
  const { t } = useTranslation();
  const [itemsCopy, setItemsCopy] = useState([]);

  //console.log("all items files", allItems?.files);

  useEffect(() => {
    setItemsCopy(items?.files?.filter((e) => e.url !== null));
  }, [items]);

  useEffect(() => {
    return () => {
      if (history.action === "POP") {
        if (
          history.location.pathname ===
          `/admin/secondStep/${items?.files[items?.files?.length - 1]?.id}`
        )
          return history.push("/admin/collections");
      }
    };
  }, [history]);

  return (
    <div
      className={` lg:w-full   bg-white mx-0  mt-5 rounded lg:px-10 pb-36 py-6 overflow-scroll   ${
        dashboard ? "h-full md:h-screen md:mx-5" : "h-screen mx-5"
      }`}
    >
      <div className="lg:h-20  flex flex-col lg:flex-row lg:justify-between lg:items-center border-b-2 ">
        <h2 className="text-4xl font-inter  lg:w-1/2 mb-3 lg:mb-0 flex items-center">
          <div
            className="h-20 w-16 flex items-center cursor-pointer"
            onClick={() => {
              console.log(
                "history.location.pathname",
                history.location.pathname
              );
              if (history.action === "POP") {
                if (
                  history.location.pathname ===
                  `/admin/secondStep/${
                    items?.files[items?.files?.length - 1]?.id
                  }`
                )
                  return history.push("/admin/collections");
                else return history.goBack();
              } else history.goBack();
            }}
          >
            <RiArrowLeftSLine color="black" className="w-10 h-10" />
          </div>
          {t("items")}
        </h2>
        {localStorage.getItem("userToken") && !dashboard && (
          <div className=" lg:w-1/2 h-full flex flex-col pl-5  md:pl-0 md:flex-row md:space-x-5 lg:space-x-0 lg:justify-end lg:items-center  ">
            <div className="lg:mr-12 md:ml-5 lg:md-0">
              <AddButton
                text="addNewItem"
                invert
                limitedWidth
                onClick={() =>
                  history.push({
                    pathname: `/admin/firstStep/${category}/${subcategory}`,
                    state: { subCollectionId: subcategory },
                  })
                }
              />
            </div>
            {allItems != null ? (
              <PDFDownloadLink
                document={<ItemsTablePDF data={allItems?.files} />}
                fileName={`${t("items")}.pdf`}
              >
                {({ blob, url, loading, error }) =>
                  loading ? (
                    <div>
                      <Loader small />
                    </div>
                  ) : (
                    <AddButton text="downloadPDFs" file limitedWidth />
                  )
                }
              </PDFDownloadLink>
            ) : (
              <Button text="preparingDownloadPDF" />
            )}
          </div>
        )}
      </div>
      <div className="flex flex-row w-4/5  md:w-1/2 h-10 items-center mb-5 mt-5 ml-5  overflow-hidden pl-2 border rounded-md border-gray-300 ">
        <AiOutlineSearch className="mr-2 w-8 h-6" color="gray" />
        <input
          value={value?.charAt(0).toUpperCase() + value?.slice(1)}
          onChange={onChange}
          placeholder={t("searchItems")}
          className="w-full h-full focus:outline-none"
        />
      </div>
      {items?.files?.length > 0 ? (
        <div
          className={` ${
            dashboard
              ? "h-auto  md:pb-20 md:overflow-y-auto md:justify-start"
              : " pb-20   overflow-y-auto justify-start"
          }`}
        >
          <div className="grid grid-cols-1 lg:grid-cols-2 px-10  xl:grid-cols-3 grid-flow-row gap-4 lg:gap-5 pt-10  ">
            {items?.files?.map((myItem, id) => (
              <Item
                key={id}
                img={
                  myItem.url === null
                    ? "https://i.pinimg.com/564x/ec/14/7c/ec147c4c53abfe86df2bc7e70c0181ff.jpg"
                    : myItem.url
                }
                description={myItem.description.substring(0, 130)}
                item={myItem.name}
                onClick={() =>
                  history.push({
                    pathname: `/admin/itemInfo/${myItem.id}`,
                    state: { subCollectionId: subcategory },
                  })
                }
              />
            ))}
          </div>
          <div className="px-10 mb-10 mt-5">
            <Pagination pages={items} changePage={changePage} />
          </div>
        </div>
      ) : (
        <div className="h-full w-full flex items-center justify-center">
          {t("noItems")}
        </div>
      )}
    </div>
  );
};

export default AllItems;
