/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { PDFDownloadLink } from "@react-pdf/renderer";
import moment from "moment";
import { RiArrowLeftSLine } from "react-icons/ri";
import CustomModal from "components/ConfirmationModal";
import ItemPDF from "components/ItemPDF";
import Gallery from "components/Gallery";
import { Modal } from "react-responsive-modal";
import { Button, Text, Alert, Loader, LazyImage } from "components";
import InfoContainer from "./components/InfoContainer";
import FileUpload from "views/AddItem/components/FileUpload";
import { PhotoContainer } from "containers";
import apiRequest from "helpers/apiRequest";
import EditMode from "./components/EditMode";
import DownloadModal from "components/DownloadModal";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

export const ItemInfo = (props) => {
  const [photos, setPhotos] = useState([]);
  const { t } = useTranslation();
  const [editMode, setEditMode] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [hide, setHide] = useState(false);
  const [loadingImages, setLoadingImages] = useState(true);
  const [item, setItem] = useState(null);
  const [isReady, setIsReady] = useState(false);

  const [showModal, setShowModal] = useState(false);
  const history = useHistory();
  const [showGallery, setShowGallery] = useState(false);
  const [imageSrc, setImageSrc] = useState(null);
  const [initialPhotoId, setInitialPhotoId] = useState(0);
  const [showDownloadModal, setShowDownloadModal] = useState(false);
  const [downloadWithoutPhotos, setDownloadWithoutPhotos] = useState(false);
  const [isSubcollection, setIsSubcollection] = useState(false);
  const [showDescriptionModal, setShowDescriptionModal] = useState(false);

  //assert.equal(reactHtml, htmlInput);

  const hideAlert = () => {
    const timeId = setTimeout(() => {
      // After 3 seconds set the show value to false
      setShowAlert(false);
    }, 2000);

    return () => {
      clearTimeout(timeId);
    };
  };

  const getImages = async () => {
    try {
      const response = await apiRequest({
        method: "get",
        url: `item/get-pictures/${props.match.params.itemId}`,
      });
      if (response.status === 200) {
        setPhotos(response.data);
        setLoadingImages(false);
      }
    } catch (err) {
      if (err?.response?.status === 401) {
        // history.push("/auth/login");
        console.log("----pictures error-----");
      }
      console.log(err);
    }
  };

  const getItemById = async () => {
    try {
      const response = await apiRequest({
        method: "get",
        url: `item/${props.match.params.itemId}`,
      });
      if (response.status === 200) {
        setItem(response.data);

        // console.log("==ITEM INFO DETAILS==", response.data);
        getCollectionsWithoutPagination(response.data.collectionId);
        getImages();
      }
    } catch (err) {
      if (err?.response?.status === 401) {
        history.push("/auth/login");
      }
      console.log(err);
    }
  };
  console.log("------item------", item);
  const confirmModal = () => {
    setShowModal(true);
  };

  const deleteItem = async () => {
    try {
      const response = await apiRequest({
        method: "delete",
        url: `item/${item.id}`,
      });
      if (response.status === 200) {
        setShowModal(false);
        history.goBack();
      }
    } catch (err) {
      if (err?.response?.status === 401) {
        history.push("/auth/login");
      }
      console.log(err);
    }
  };

  const getCollectionsWithoutPagination = async (id) => {
    try {
      const response = await apiRequest({
        method: "get",
        url: `collection/${id}`,
      });
      if (response.status === 200) {
        if (response.data === null) {
          try {
            const response = await apiRequest({
              method: "get",
              url: `collection/list/${id}`,
            });
            if (response.status === 200) {
              setIsSubcollection(true);
            }
          } catch (err) {
            if (err?.response?.status === 401) {
              history.push("/auth/login");
            }
          }
        } else response.data.hasSubcollection && setIsSubcollection(true);
      }
    } catch (err) {
      if (err?.response?.status === 401) {
        history.push("/auth/login");
      }
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setIsReady(true);
    }, 3000);
    getItemById();
  }, []);

  return (
    <div className="bg-blue-100 h-screen w-full pt-5 pb-5 overflow-hidden relative">
      <CustomModal
        open={showModal}
        onClose={() => setShowModal(false)}
        onCancel={() => setShowModal(false)}
        onConfirm={() => deleteItem()}
        confirmationText={"sureToDelete"}
        confirmationDesc={"ifDelete"}
        confirmButtonText="delete"
      />
      <DownloadModal
        download
        open={showDownloadModal}
        onClose={() => setShowDownloadModal(false)}
        onCancel={() => setShowDownloadModal(false)}
        confirmationText={"doYouWannaDownload"}
        confirmButtonText={"withPhotos"}
        confirmationWithoutPhotosText={"withoutPhotos"}
        item={item}
        photos={photos}
      />
      <Modal
        open={showDescriptionModal}
        onClose={() => setShowDescriptionModal(false)}
        blockScroll={false}
        styles={{
          modal: {
            backgroundColor: "transparent",
            boxShadow: "none",
          },
          modalContainer: {
            overflow: "hidden",
          },
        }}
      >
        <div className="w-full  max-w-2xl h-screen   p-5 relative mx-auto my-auto ">
          <div className="h-3/4 bg-white mx-auto overflow-y-scroll my-auto  p-8 ">
            <p className="text-2xl  font-bold text-center w-full  py-2">
              {item?.name}
            </p>
            <p className="w-full text-sm text-center font-bold text-gray-300  mt-5 mb-5 ">
              {t("itemDescription").toUpperCase()}
            </p>
            <p className="text-md">{item?.description}</p>
          </div>
        </div>
      </Modal>
      {editMode ? (
        <EditMode
          item={item}
          hide={hide}
          setHide={() => setHide(true)}
          setEditMode={() => setEditMode(false)}
          editMode={editMode}
          setShowAlert={() => setShowAlert(true)}
          hideAlert={hideAlert()}
          photos={photos}
          setPhotos={setPhotos}
          getItemById={getItemById}
        />
      ) : (
        <div className=" bg-white overflow-y-scroll rounded mx-5 h-full px-5 lg:px-10 pt-5 pb-12 ">
          {item != null ? (
            <div className="h-full  ">
              <div className="w-full  flex flex-col md:flex-row">
                <div
                  className="h-20 w-16 flex items-center cursor-pointer"
                  onClick={() => history.goBack()}
                >
                  <RiArrowLeftSLine color="black" className="w-10 h-10" />
                </div>
                <div className="lg:h-20  w-full  flex flex-col lg:flex-row justify-end lg:items-center border-b-2 mb-10">
                  {localStorage.getItem("userToken") && (
                    <div className="flex flex-col md:flex-row md:space-x-2 lg:space-x-3">
                      <Button
                        edit
                        onClick={() => setEditMode(true)}
                        text="editItem"
                        invert
                      />
                      <Button
                        remove
                        invert
                        text="deleteItem"
                        onClick={confirmModal}
                      />
                      {photos !== [] && isReady ? (
                        <Button
                          file
                          text="downloadPDF"
                          onClick={() => setShowDownloadModal(true)}
                        />
                      ) : (
                        <Button invert text="preparingDownloadPDF" />
                      )}
                    </div>
                  )}
                </div>
              </div>
              <div className="lg:pl-16   md:pr-5 pb-80">
                <div className=" flex md:flex-row flex-col">
                  {!hide ? (
                    <PhotoContainer
                      limited
                      editMode={editMode}
                      hide={hide}
                      src={
                        item?.url === null
                          ? "https://i.pinimg.com/564x/ec/14/7c/ec147c4c53abfe86df2bc7e70c0181ff.jpg"
                          : item?.url
                      }
                      onClick={() => setHide(true)}
                    />
                  ) : (
                    <div className=" lg:w-1/5  lg:pt-8">
                      <FileUpload id={1} />
                    </div>
                  )}

                  <div className="md:ml-5  w-4/5">
                    <div className="flex flex-col md:flex-row  mb-2 mt-2 md:mb-0 md:mt-0">
                      <div className=" md:w-48 md:mr-2 ">
                        <Text gray>{t("itemName")}:</Text>
                      </div>
                      <div className="b w-full">
                        <Text black>{item?.name}</Text>
                      </div>
                    </div>
                    {item?.collection?.Parent !== null && (
                      <div className="flex flex-col md:flex-row mb-2 md:mb-0 ">
                        <div className="md:w-48 md:mr-2 ">
                          <Text gray>{t("collectionName")}:</Text>
                        </div>
                        <div className=" w-full">
                          <Text black>{item?.collection?.Parent?.name}</Text>
                        </div>
                      </div>
                    )}
                    <div className="flex flex-col md:flex-row   ">
                      {item?.collection?.Parent !== null ? (
                        <div className=" md:w-48 md:mr-2  ">
                          <Text gray>{t("subCollectionName")}:</Text>
                        </div>
                      ) : (
                        <div className=" md:w-48 md:mr-2">
                          <Text gray>{t("collectionName")}:</Text>
                        </div>
                      )}
                      <div className=" w-full">
                        <Text black>{item?.collection?.name}</Text>
                      </div>
                    </div>

                    <div className="mt-3 max-h-56 ">
                      {item?.description?.length > 400 ? (
                        <div>
                          <p className="text-gray-500  break-words">
                            {item?.description?.substring(0, 400)}
                            {"..."}
                            <span
                              onClick={() => setShowDescriptionModal(true)}
                              className="text-blue-primary ml-5 text-sm underline cursor-pointer hover:text-blue-400 "
                            >
                              {t("seeMore")}
                            </span>
                          </p>
                        </div>
                      ) : (
                        <p className="text-gray-500 break-words">
                          {item?.description}
                        </p>
                      )}
                    </div>
                  </div>
                </div>

                <div className="grid lg:grid-cols-2 gap-5 mt-44 md:mt-5">
                  <InfoContainer title="dimension" info={item?.dimension} />
                  <InfoContainer
                    title="preservation"
                    info={item?.preservation}
                  />
                </div>
                {item?.origin !== "" && (
                  <div className="mt-10 ">
                    <div className="mb-5">
                      <Text large black>
                        {t("origin")}
                      </Text>
                    </div>

                    <InfoContainer info={item?.origin} />
                  </div>
                )}

                <div className="grid lg:grid-cols-2 gap-5 mt-5">
                  <InfoContainer
                    title="whenAndHowFound"
                    info={item?.how_made}
                    long
                  />
                  {item?.where_made !== "" && (
                    <InfoContainer
                      title="whereFound"
                      info={item?.where_made}
                      long
                    />
                  )}
                </div>
                <div className="grid lg:grid-cols-2 gap-5 mt-5">
                  {item?.ageId !== null && (
                    <InfoContainer title="period" info={item?.age?.name} />
                  )}
                  {item?.categoryId !== null && (
                    <InfoContainer
                      title="categories"
                      info={item?.category?.name}
                    />
                  )}
                </div>
                <div className="grid lg:grid-cols-2 gap-5 mt-5">
                  {item?.materialId !== null && (
                    <InfoContainer
                      title="material"
                      info={item?.Material?.name}
                    />
                  )}
                  {item?.speciesId !== null && (
                    <InfoContainer title="types" info={item?.species?.name} />
                  )}
                </div>

                <div className="mt-10">
                  <Text large black>
                    {t("museumInformation")}
                  </Text>

                  <div className="grid lg:grid-cols-3 gap-5 mt-5 mb-5">
                    <InfoContainer
                      title="invItemNumber"
                      info={item?.invNumber}
                    />
                    {item?.date_enrollment !== null &&
                      item?.date_enrollment !== "" && (
                        <InfoContainer
                          title="entryDate"
                          info={moment(item?.date_enrollment).format(
                            "DD.MM.YYYY"
                          )}
                        />
                      )}
                    <InfoContainer
                      title="accommodation"
                      info={item?.accommodation}
                    />
                  </div>
                </div>

                {item?.restauration !== "" && (
                  <div className="mt-5">
                    <InfoContainer
                      long
                      title="restauration"
                      info={item?.restauration}
                    />
                  </div>
                )}
                {item?.note !== "" && (
                  <div className="mt-5">
                    <div>
                      <div className="mb-1">
                        <Text>{t("note")}</Text>
                      </div>
                      {/*
                      <div
                        dangerouslySetInnerHTML={{ __html: item?.note }}
                        className={` flex  rounded-lg px-5 border overflow-auto h-28 py-2 `}
                      ></div>*/}

                      <ReactQuill
                        value={item?.note}
                        readOnly={true}
                        theme="snow"
                        modules={{ toolbar: false }}
                        style={{
                          width: "100%",
                        }}
                      />
                    </div>
                  </div>
                )}

                <div className="mt-10 ">
                  <Text large black>
                    {t("gallery")}
                  </Text>
                  {photos?.length === 0 && (
                    <div className=" py-8 flex justify-center">
                      <Text gray>
                        {t("gallery")} {t("noItems").toLowerCase()}
                      </Text>
                    </div>
                  )}
                  {showGallery && (
                    <Gallery
                      photosLength={photos?.length}
                      initialPhotoId={initialPhotoId}
                      src={imageSrc}
                      onClick={() => setShowGallery(false)}
                      onRightArrowClick={() => {
                        setImageSrc(photos[initialPhotoId + 1]?.url);
                        setInitialPhotoId(initialPhotoId + 1);
                      }}
                      onLeftArrowClick={() => {
                        setImageSrc(photos[initialPhotoId - 1]?.url);
                        setInitialPhotoId(initialPhotoId - 1);
                      }}
                    />
                  )}
                  {!loadingImages ? (
                    <div className="grid  mt-8 grid-cols-1 gap-5 md:grid-cols-2  xl:grid-cols-4 xl:gap-3 w-full ">
                      {photos.length > 0 &&
                        photos.map((elem, id) => (
                          <div className="mt-10  w-full max-h-36 flex justify-center items-center relative ">
                            <img
                              onClick={() => {
                                setInitialPhotoId(id);
                                setImageSrc(photos[id]?.url);
                                setShowGallery(true);
                              }}
                              src={elem?.url}
                              className="w-full object-cover h-44 hover:shadow-xl cursor-pointer"
                              style={{ borderRadius: "8px" }}
                            />
                          </div>
                        ))}
                    </div>
                  ) : (
                    <div className="h-full w-full flex justify-center items-center">
                      <Loader />
                    </div>
                  )}
                </div>
              </div>
            </div>
          ) : (
            <div className=" bg-white rounded mx-5 h-screen px-10 pt-5 pb-12 flex justify-center items-center ">
              <Loader />
            </div>
          )}
        </div>
      )}

      {showAlert ? (
        <div className="w-full flex justify-center">
          <Alert
            text="Promjene su uspješno spremljene!"
            onClick={() => setShowAlert(false)}
          />
        </div>
      ) : null}
    </div>
  );
};

export default ItemInfo;
