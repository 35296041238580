/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { useHistory } from "react-router-dom";

import { IoPersonCircle } from "react-icons/io5";
import { GiHamburgerMenu } from "react-icons/gi";
import { SignoutModal } from "containers";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import apiRequest from "helpers/apiRequest";
import { Sidebar } from "containers";
import LanguageDropdown from "containers/LanguageDropdown";
import { changeLanguage } from "redux/actions/languageActions";
import ErrorAlert from "components/ErrorAlert";
import { VscSignOut } from "react-icons/vsc";

const Header = ({ onChange }) => {
  const [showModal, setShowModal] = useState(false);
  const [showSidebar, setShowSidebar] = useState(false);

  const history = useHistory();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [error, setError] = useState(null);

  const createError = (msg) => {
    setError(<ErrorAlert errorText={msg} />);
    setTimeout(() => {
      setError(null);
    }, 3000);
  };

  const logOutUser = async () => {
    try {
      const response = await apiRequest({
        method: "get",
        url: `auth/logout`,
      });
      if (response.status === 200) {
        localStorage.clear();
        history.push("/auth/login");
      }
    } catch (err) {
      if (err?.response?.status === 401) {
        localStorage.clear();
        history.push("/auth/login");
      }

      if (err?.response?.status === 500) {
        history.push("/auth/login");
      }
      createError(err?.response?.data?.error);
    }
  };

  return (
    <div>
      <div className="w-full h-16 bg-white  border-b-2 px-3 ">
        <div className="h-full flex row ">
          <div className="w-full flex row items-center ">
            <GiHamburgerMenu
              id="burger"
              className="w-8 h-10 sm:hidden mr-4 cursor-pointer"
              color="#0369A1"
              onClick={() => setShowSidebar(!showSidebar)}
            />
          </div>
          <div className="flex row  items-center">
            <div>
              <LanguageDropdown
                onClick={(val) => dispatch(changeLanguage(val))}
              />
            </div>
            {localStorage.getItem("userToken") ? (
              <div
                onClick={() => setShowModal(!showModal)}
                className="w-12 h-12 rounded-full   ml-2 overflow-hidden flex justify-center items-center cursor-pointer"
              >
                <IoPersonCircle className="w-14 h-14 " color="#0369A1" />
              </div>
            ) : (
              <div
                className="cursor-pointer"
                onClick={() => setShowModal(!showModal)}
              >
                <VscSignOut color="gray" className="w-10 h-8 ml-5 mr-3 " />
              </div>
            )}

            {showModal && (
              <SignoutModal
                text={localStorage.getItem("userToken") ? "logOut" : "logIn"}
                onClick={() => {
                  setShowModal(false);
                  localStorage.getItem("userToken")
                    ? logOutUser()
                    : history.push("/auth/login");
                }}
              />
            )}
          </div>
        </div>
      </div>
      <div
        onClick={() => setShowSidebar(!showSidebar)}
        className={`absolute w-1/2 h-full block z-10 sm:hidden ${
          showSidebar ? "block" : "hidden"
        }`}
      >
        <Sidebar />
      </div>
    </div>
  );
};

export default Header;
