import React from "react";
import Loader from "react-loader-spinner";

const CustomLoader = ({ small, xs, gray }) => {
  return (
    <Loader
      type="Oval"
      color={gray ? "gray" : "#0369A1"}
      height={xs ? 20 : small ? 40 : 60}
      width={xs ? 20 : small ? 40 : 60}
    />
  );
};

export default CustomLoader;
