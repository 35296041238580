import React from "react";

import { Text, Dropdown } from "../components";
import { useTranslation } from "react-i18next";

const FilterContainer = () => {
  const { t } = useTranslation();

  return (
    <div className="h-auto lg:h-full bg-white rounded px-5 py-5  lg:px-6 pt-6">
      <Text>{t("filter")}</Text>
      <div className="mt-8 mb-3 relative z-1">
        <Dropdown title="collection" />
      </div>
      <div className="relative">
        <Dropdown title="preservation" />
      </div>
    </div>
  );
};

export default FilterContainer;
