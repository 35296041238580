import React, { useState } from "react";
import DatePicker from "react-datepicker";
import { useTranslation } from "react-i18next";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { Text } from "components";

const DatePickerComponent = ({ title, onChange, selected }) => {
  const { t } = useTranslation();
  const [startDate, setStartDate] = useState(null);
  return (
    <div>
      <div className="mb-1">
        <Text>{t(title)}</Text>
      </div>
      <DatePicker
        placeholderText="DD/MM/YYYY"
        className="w-full rounded-lg h-10 border border-gray-200"
        selected={selected}
        dateFormat="d.MM.yyyy"
        onChange={(date) => {
          //setStartDate(date);
          onChange(date);
        }}
        maxDate={new Date()}
      />
    </div>
  );
};

export default DatePickerComponent;
