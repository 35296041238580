import React from "react";

const Text = ({ children, white, thin, blue, gray, large, black, small }) => {
  return (
    <h4
      className={`  font-inter font-medium  
       ${
         white
           ? "text-white"
           : blue
           ? "text-blue-primary"
           : gray
           ? "text-gray-400"
           : black
           ? "text-black"
           : "text-black-light"
       } ${large ? "text-2xl" : small ? "text-sm" : "text-sm lg:text-lg"}`}
    >
      {children}
    </h4>
  );
};
export default Text;
