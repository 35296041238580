
const INITAL_STATE = {
    selectedLanguage: 'ba'
}

export default function languageReducer( state = INITAL_STATE, action ) {
    switch(action.type){
        case 'CHANGE_LANGUAGE' : {
            return { ...state, selectedLanguage: action.payload }
        }
        default : {
            return {...state}
        }
    }
}