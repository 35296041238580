/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Step from "./components/Step";
import FileUpload from "./components/FileUpload";
import { Button, Alert, Text } from "components";
import apiRequest from "helpers/apiRequest";
import Modal from "components/SuccessModal";
import ErrorAlert from "components/ErrorAlert";
import Loader from "components/Loader";
import { PhotoContainer } from "containers";
import Check from "assets/check.png";

const SecondStepContainer = (props) => {
  const { t } = useTranslation();

  const [showAlert, setShowAlert] = useState(false);
  const [images, setImages] = useState([]);
  const [headImage, setHeadImage] = useState([]);
  const [successModal, setSuccessModal] = useState(false);
  const [showError, setShowError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [chooseHead, setChooseHead] = useState(false);

  const history = useHistory();

  const createError = (msg) => {
    setShowError(<ErrorAlert errorText={msg} />);
    setTimeout(() => {
      setShowError(null);
    }, 3000);
  };

  const checkImages = () => {
    if (images.length === 0) {
      setShowError(<ErrorAlert errorText="chooseImages" />);
      setTimeout(() => {
        setShowAlert(null);
      }, 3000);
    } else {
      saveImages();
      getImages();
    }
  };

  const getImages = async () => {
    try {
      const response = await apiRequest({
        method: "get",
        url: `item/get-pictures/${props.match.params.itemId}`,
      });
      if (response.status === 200) {
        let img = [...response.data];
        img.map((elem) => {
          return {
            id: elem.id,
            url: elem.url,
            selected: false,
          };
        });
        img[0].selected = true;
        setImages([...img]);
        setHeadImage(img[0]);
        setIsLoading(false);
        setChooseHead(true);
      }
    } catch (err) {
      if (err.response === 401) {
        history.push("/auth/login");
      }
      console.log(err);
    }
  };

  const saveImages = async () => {
    setIsLoading(true);
    const formData = new FormData();
    formData.append("itemId", parseInt(props.match.params.itemId));
    images.forEach((elem) => {
      formData.append("data", elem.file);
    });

    try {
      const response = await apiRequest({
        method: "post",
        url: "item/upload-picture",
        data: formData,
      });
      if (response.status === 200) {
        setIsLoading(false);
        getImages();
      }
    } catch (err) {
      createError(err.response.data.error);
    }
  };

  const selectImage = (elem) => {
    const imgs = [...images];
    imgs.forEach((image) => {
      image.selected = false;
      if (image.id === elem.id) {
        elem.selected = true;
      }
    });
    setHeadImage(elem);
    setImages([...imgs]);
  };

  const saveHead = async () => {
    //console.log("HEAD ID:", props?.location?.state?.collectionId);
    try {
      const response = await apiRequest({
        method: "PUT",
        url: `item/${props.match.params.itemId}`,
        data: {
          url: headImage.url,
          collectionId: props?.location?.state?.collectionId,
        },
      });
      if (response.status === 200) {
        setSuccessModal(true);
      }
    } catch (err) {
      if (err?.response?.status === 401) {
        history.push("/auth/login");
      }
      console.log(err);
    }
  };

  useEffect(() => {
    getImages();
  }, []);
  if (isLoading)
    return (
      <div className="h-full w-full flex flex-col space-y-5 justify-center items-center">
        <Text>{t("uploadingPhotos")}</Text>
        <Loader />
      </div>
    );
  return (
    <div className="bg-blue-100 lg:h-full w-full pt-5 lg:pb-5 lg:overflow-hidden relative">
      {showError}
      <Modal
        open={successModal}
        title="successItemSave"
        subtitle="successItemSaveAddition"
        onConfirm={() => {
          setSuccessModal(false);
          history.push({
            pathname: !!props?.location?.state?.collectionId
              ? `/admin/items/${props?.location?.state?.collectionId}/0`
              : "/admin/collections",
            state: { fromSecondStep: true },
          });
        }}
      />
      <div className=" bg-white rounded mx-5 px-3 py-3 lg:py-0 lg:px-10  pb-20  h-screen relative overflow-y-auto lg:overflow-y-auto">
        <div className="lg:h-24  flex flex-col lg:flex-row  lg:items-center border-b-2 mb-10">
          <h2 className="text-4xl font-inter border-b-2 pb-3 lg:border-b-0 lg:pb-0  lg:w-1/3 ">
            {t("addNewItem")}
          </h2>
          <Step
            clickable
            onClick={() =>
              history.push({
                pathname: `/admin/firstStep/0/0`,
                state: { from: "secondStep", id: props.match.params.itemId },
              })
            }
            title="generalInfo"
            subtitle="generalSpecification"
            step="01"
            active
            completed
          />
          <Step title="multimedia" subtitle="picsSkechVideo" step="02" active />
        </div>
        {!chooseHead ? (
          <>
            <div className="grid  xl:grid-cols-3 grid-cols-2 gap-y-10">
              <Text>{t("addImages")}</Text>
              <div className="col-span-2">
                <FileUpload
                  images={images}
                  setImages={setImages}
                  multiple={true}
                />
              </div>
            </div>
          </>
        ) : (
          <div className="flex flex-col lg:flex-row">
            <div className="lg:w-1/2">
              <Text>{t("chooseHead")}</Text>
            </div>
            <div className="w-full mt-10 lg:mt-0 lg:grid lg:grid-cols-4">
              {images.length > 0 &&
                images.map((elem) => (
                  <div
                    className="image-class mt-3 md:mt-0"
                    onClick={() => selectImage(elem)}
                  >
                    <img
                      src={elem.url}
                      alt="image"
                      className={
                        elem.selected ? "image selected-image" : "image"
                      }
                    />
                    {elem.selected && (
                      <div className="absolute rounded-full -top-2 lg:right-2 lg:w-8 lg:h-8 bg-blue-400 flex justify-center pt-1 items-center">
                        <img src={Check} alt="check" />
                      </div>
                    )}
                  </div>
                ))}
            </div>
          </div>
        )}

        <div className="h-20  flex row justify-end items-center border-t-2 mt-16">
          {!chooseHead ? (
            <>
              <Button
                back
                text="goBack"
                invert
                onClick={() =>
                  history.push({
                    pathname: `/admin/firstStep/0/0`,
                    state: {
                      from: "secondStep",
                      id: props.match.params.itemId,
                    },
                  })
                }
              />
              <div className="ml-3">
                <Button
                  text="saveImages"
                  onClick={() => {
                    checkImages();
                  }}
                />
              </div>
            </>
          ) : (
            <div className="ml-3">
              <Button
                text="saveItem"
                onClick={() => {
                  saveHead();
                }}
              />
            </div>
          )}
        </div>
      </div>
      {showAlert ? (
        <div className="w-full flex justify-center">
          <Alert
            text="successItemSave"
            onClick={() => {
              setShowAlert(false);
              history.push("/admin/collections");
            }}
          />
        </div>
      ) : null}
    </div>
  );
};

export default SecondStepContainer;
