import axios from 'axios'

export const setInitialConfig = () => {
  axios.defaults.validateStatus = (status) => status >= 200 && status <= 299
  axios.interceptors.response.use(
    (response) => response,
    (error) => {
      let errorData = null
      if (error.response) {
        if (
          error.response.status >= 500 ||
          [401, 404].includes(error.response.status)
        ) {
          if (process.env.REACT_APP_APP_ENVIRONMENT === 'local') {
            console.warn(
              `Error with request. Status: ${error.response.status}.`,
            )
          }
        } else if (error.response.data) {
          errorData = error.response.data
        }
      }
      return Promise.reject(errorData)
    },
  )
}
