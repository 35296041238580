import React from "react";

import { Text } from "components";
import { VscSignOut } from "react-icons/vsc";
import { useTranslation } from "react-i18next";

const SignoutModal = ({ onClick, text }) => {
  const { t } = useTranslation();
  return (
    <div
      onClick={onClick}
      className="w-auto h-12 bg-white absolute top-14 right-5 px-5 rounded-lg flex items-center border border-gray-400 z-10"
    >
      <div className="cursor-pointer flex row justify-center items-center">
        <VscSignOut color="gray" className="w-5 h-5 mr-3" />
        <Text>{t(text)}</Text>
      </div>
    </div>
  );
};

export default SignoutModal;
