import React from "react";
import Select from "react-select";
import { useTranslation } from "react-i18next";
import { BsPlusSquare } from "react-icons/bs";

const Dropdown = ({
  options,
  title,
  onChange,
  defaultCollection,
  addNew,
  onClick,
  cancel,
  onCancelClick,
  value,
  obligatory,
}) => {
  const { t } = useTranslation();

  const style = {
    control: (styles) => ({
      ...styles,
      border: "1px solid rgb(229, 231, 235)", // default border color
      borderRadius: "0.5rem",
      boxShadow: "none", // no box-shadow
    }),
  };

  return (
    <>
      <div className="flex flex-row items-center space-x-4 ">
        {addNew && (
          <BsPlusSquare
            onClick={onClick}
            color="grey"
            className="w-5 h-7 mb-1 cursor-pointer  hover:shadow-lg"
          />
        )}
        <h4 className="mb-1 block text-sm font-medium text-gray-700 lg:text-lg">
          {t(title)}
        </h4>
        {obligatory && <p className=" text-red text-lg">*</p>}
        {cancel && (
          <div className=" w-full h-full flex justify-end pt-1 ">
            <h4
              onClick={onCancelClick}
              className="mb-1  text-xs  font-medium text-blue-primary cursor-pointer hover:text-blue-secondary"
            >
              {t("cancelTwo")}
            </h4>
          </div>
        )}
      </div>
      <Select
        value={value}
        styles={style}
        options={options}
        defaultValue={defaultCollection}
        onChange={onChange}
      />
    </>
  );
};

export default Dropdown;
