/* eslint-disable import/no-anonymous-default-export */
import React, { useEffect } from "react";
import { Modal } from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import { useTranslation } from "react-i18next";
import { HiDocumentDownload } from "react-icons/hi";
import { PDFDownloadLink } from "@react-pdf/renderer";
import ItemPDF from "components/ItemPDF";

const DownloadModal = ({
  open,
  onClose,
  onConfirm,
  onConfirmWithoutPhotos,
  confirmationText,
  confirmationWithoutPhotosText,
  confirmationDesc,
  confirmButtonText,
  info,
  download,
  item,
  photos,
}) => {
  const { t } = useTranslation();

  return (
    <Modal open={open} onClose={onClose}>
      <div className="w-full  max-w-lg p-5 relative mx-auto my-auto bg-white ">
        <div className="">
          <div className="text-center p-5 flex-auto justify-center">
            <div className="w-full flex items-center justify-center ">
              <div className="w-12 h-12 rounded-full bg-blue-primary flex justify-center items-center">
                <HiDocumentDownload className="w-7 h-7" color="white" />
              </div>
            </div>

            <h2 className="text-xl font-bold py-4 ">{t(confirmationText)}</h2>
            <p className="text-sm text-gray-500 px-8">{t(confirmationDesc)}</p>
          </div>
          <div className="p-3   space-y-2  mt-2 text-center  md:block">
            <PDFDownloadLink
              document={<ItemPDF item={item} photos={photos} />}
              fileName={`${item?.name}.pdf`}
            >
              {({ blob, url, loading, error }) => (
                <button
                  className="mb-2 md:mb-0 w-full   px-5 py-2 text-sm shadow-sm font-medium tracking-wider  rounded-lg hover:shadow-lg bg-blue-primary text-white"
                  onClick={onConfirm}
                >
                  {t(confirmButtonText)}
                </button>
              )}
            </PDFDownloadLink>

            <div></div>

            <PDFDownloadLink
              document={<ItemPDF item={item} photos={photos} without />}
              fileName={`${item?.name}.pdf`}
            >
              {({ blob, url, loading, error }) => (
                <button
                  className="mb-2 md:mb-0 w-full   px-5 py-2 text-sm shadow-sm font-medium tracking-wider  rounded-lg hover:shadow-lg border-2 border-blue-primary text-blue-primary"
                  onClick={onConfirmWithoutPhotos}
                >
                  {t(confirmationWithoutPhotosText)}
                </button>
              )}
            </PDFDownloadLink>

            <button
              className="mb-2 md:mb-0 w-full bg-white px-5 py-2 text-sm shadow-sm font-medium tracking-wider border text-gray-600 rounded-lg hover:shadow-lg hover:bg-gray-100"
              onClick={onClose}
            >
              {t("cancel")}
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default DownloadModal;
