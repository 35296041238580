import React from "react";
import { HiPlusCircle, HiDocumentDownload } from "react-icons/hi";
import { useTranslation } from "react-i18next";

import { Text } from ".";

const AddButton = ({ text, invert, onClick, file, limitedWidth }) => {
  const { t } = useTranslation();

  return (
    <div
      className={`h-14 flex justify-center items-center rounded-lg mb-4 border-2 border-blue-primary cursor-pointer hover:shadow-md 
        ${invert ? "bg-transparent" : "bg-blue-primary"} ${
        limitedWidth ? "w-52" : "w-full"
      }`}
      onClick={onClick}
    >
      {file ? (
        <HiDocumentDownload
          color={invert ? "#0369A1" : "white"}
          className="w-5 h-5 mr-3"
        />
      ) : (
        <HiPlusCircle
          color={invert ? "#0369A1" : "white"}
          className="w-5 h-5 mr-3"
        />
      )}

      {invert ? (
        <Text small blue>
          {t(text)}
        </Text>
      ) : (
        <Text small white>
          {t(text)}
        </Text>
      )}
    </div>
  );
};

export default AddButton;
