import React from "react";
import { useTranslation } from "react-i18next";

import { Text } from "components";

const TextArea = ({
  title,
  placeholder,
  value,
  onChange,
  invalid,
  description,
  obligatory,
}) => {
  const { t } = useTranslation();
  return (
    <div className="px-1">
      <div className="mb-1 flex flex-row">
        <Text>{t(title)}</Text>
        {obligatory && <p className="ml-4 text-red text-lg">*</p>}
      </div>
      <div className={`shadow-sm mt-1    `}>
        <textarea
          id="about"
          name="about"
          rows={description ? 8 : 3}
          className={`w-full block sm:text-sm border   rounded-md border-gray-300 ${
            invalid && "border-red"
          }`}
          placeholder={t(placeholder)}
          value={value?.charAt(0)?.toUpperCase() + value?.slice(1)}
          onChange={onChange}
        />
      </div>
    </div>
  );
};

export default TextArea;
