import React from "react";
//import { AiOutlineHome,  } from "react-icons/ai";
import { BsBook } from "react-icons/bs";
import { FiSettings } from "react-icons/fi";
import { IoPeopleOutline, IoAnalyticsOutline } from "react-icons/io5";
import { MdCollectionsBookmark } from "react-icons/md";
import { RiCollageFill } from "react-icons/ri";
import { useTranslation } from "react-i18next";

import { Text } from ".";

const SidebarItem = ({ icon, text, active, onClick }) => {
  const { t } = useTranslation();
  return (
    <div
      onClick={onClick}
      className={
        "h-11 mt-3 py-1 xl:px-3 lg:px-2 rounded-lg flex items-center cursor-pointer " +
        (active ? "bg-blue-dark" : "bg-blue-primary")
      }
    >
      {icon === "RiCollageFill" ? (
        <RiCollageFill color="white" className="w-6 h-4 md:w-8 md:h-6 mr-3" />
      ) : icon === "BsBook" ? (
        <MdCollectionsBookmark
          color="white"
          className="w-6 h-4 md:w-8 md:h-6 mr-3"
        />
      ) : icon === "FiSettings" ? (
        <FiSettings color="white" className="w-6 h-4 md:w-8 md:h-6 mr-3" />
      ) : (
        <IoPeopleOutline color="white" className="w-6 h-4 md:w-8 md:h-6 mr-3" />
      )}

      <div className="mt-1">
        <p className="text-white text-sm font-bold xl:text-lg">{t(text)}</p>
      </div>
    </div>
  );
};

export default SidebarItem;
