import React from "react";

const Border = () => {
  return (
    <div>
      <div className="w-1 h-8  border-l  "></div>
      <div className="w-1 h-5 border-r transform -rotate-45 ml-1 absolute "></div>
      <div className="w-1 h-5  border-r transform rotate-45 ml-1 mt-3 "></div>
      <div className="w-1 h-8  border-l"></div>
    </div>
  );
};

export default Border;
