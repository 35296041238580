/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";

import Pagination from "components/Pagination";
import { RiArrowLeftSLine } from "react-icons/ri";
import AddSubcategoryModal from "components/AddSubcategoryModal";
import ConfimationModal from "components/ConfirmationModal";
import apiRequest from "helpers/apiRequest";
import Loader from "components/Loader";
import { AddButton } from "components";
import { AiOutlineSearch } from "react-icons/ai";
import { TextInput } from "components";
import { FiEdit } from "react-icons/fi";
import { RiDeleteBin6Line } from "react-icons/ri";
import Tooltip from "components/Tooltip";
import { IoIosArrowForward } from "react-icons/io";

const SubCollections = ({ match, location }) => {
  const { t } = useTranslation();
  const history = useHistory();

  const [subCollections, setSubCollections] = useState(null);
  const [pag, setPagination] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [confirmModal, setCOnfirmModal] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);
  const [editColName, setEditColName] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [invalidEdit, setInvalidEdit] = useState(false);
  /* Filter */
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(5);
  const [orderBy, setOrderBy] = useState("createdAt");
  const [orderType, setOrderType] = useState("DESC");
  const [noItems, setNoItems] = useState(false);
  const [itemToDeleteName, setItemToDeleteName] = useState("");
  const getSubCollections = async () => {
    try {
      const response = await apiRequest({
        method: "get",
        url: `collection/list/${match.params.collectionId}?page=${page}&pageSize=${pageSize}&orderBy=${orderBy}&orderType=${orderType}&search=${searchTerm}`,
      });
      if (response.status === 200) {
        let col = [];
        response.data.files.map((elem) => {
          return col.push({ ...elem, edit: false });
        });
        setSubCollections([...col]);
        setPagination(response.data);
      }
    } catch (err) {
      if (err?.response?.status === 401) {
        history.push("/auth/login");
      }
      setNoItems(true);
      console.log(err);
    }
  };

  const goToNext = (id) => {
    history.push(`/admin/items/${match.params.collectionId}/${id}`);
  };

  const changePage = (page) => {
    setPage(page.selected + 1);
  };

  const confirmDelete = (elem) => {
    setItemToDelete(elem.id);
    setItemToDeleteName(elem.name);
    setCOnfirmModal(true);
  };

  const deleteItem = async () => {
    setCOnfirmModal(false);
    try {
      const response = await apiRequest({
        method: "delete",
        url: `subcollection/${itemToDelete}`,
      });
      if (response.status === 200) {
        getSubCollections();
      }
    } catch (err) {
      if (err?.response?.status === 401) {
        history.push("/auth/login");
      }
      console.log(err);
    }
  };

  const edit = (e) => {
    let col = [...subCollections];
    col.forEach((elem) => {
      if (elem.id === e.id) {
        elem.edit = !elem.edit;
        setEditColName(elem.name);
      }
    });
    setSubCollections([...col]);
  };

  const saveEdit = async (elem) => {
    try {
      const response = await apiRequest({
        method: "put",
        url: `subcollection/${elem.id}`,
        data: {
          name: editColName,
        },
      });
      if (response.status === 200) {
        edit(elem);
        getSubCollections();
      }
    } catch (err) {
      if (err?.response?.status === 401) {
        history.push("/auth/login");
      }
      console.log(err);
    }
  };

  useEffect(() => {
    getSubCollections();
  }, [page, searchTerm]);

  const checkValue = (elem) => {
    if (editColName === "") {
      return setInvalidEdit(true);
    }
    return saveEdit(elem);
  };

  return (
    <div className="bg-blue-100 h-screen w-full pt-5 pb-5 overflow-hidden">
      <AddSubcategoryModal
        open={modalOpen}
        collectionId={match.params.collectionId}
        confirmButtonText={"done"}
        onClose={() => {
          setModalOpen(false);
          getSubCollections();
        }}
        onConfirm={() => {
          setModalOpen(false);
          getSubCollections();
        }}
      />
      <ConfimationModal
        open={confirmModal}
        confirmationText={"sureToDeleteSubcollection"}
        confirmationDesc={"ifDeleteSubcollection"}
        confirmButtonText={"delete"}
        itemToDelete={itemToDeleteName}
        onClose={() => setCOnfirmModal(false)}
        onConfirm={() => deleteItem()}
      />
      <div className=" bg-white overflow-y-scroll h-full md:h-screen rounded mx-5  lg:px-10 px-3 pt-5 pb-12">
        <div className="lg:h-20 flex flex-col lg:flex-row lg:justify-between lg:items-center border-b-2">
          <div className=" flex flex-row items-center">
            <div
              className="h-20 w-16 flex items-center cursor-pointer"
              onClick={() =>
                location?.state?.fromSecondStep
                  ? history.push("admin/collections")
                  : history.goBack()
              }
            >
              <RiArrowLeftSLine color="black" className="w-10 h-10" />
            </div>
            <h2 className="text-3xl md:text-4xl font-inter  lg:w-1/2  lg:mb-0 ">
              {t("subCollection")}
            </h2>
          </div>
          {localStorage.getItem("userToken") && (
            <div className=" lg:w-1/2 h-full flex flex-row space-x-5 lg:space-x-0 lg:justify-end lg:items-center  ">
              <div>
                <AddButton
                  text="addNewSub"
                  invert
                  limitedWidth
                  onClick={() => setModalOpen(true)}
                />
              </div>
            </div>
          )}
        </div>
        <div className="flex flex-row  w-1/2 h-10 items-center mt-5 mb-5  overflow-hidden pl-2 border rounded-md border-gray-300  ">
          <AiOutlineSearch className="mr-2 w-8 h-6" color="gray" />
          <input
            value={searchTerm.charAt(0).toUpperCase() + searchTerm.slice(1)}
            placeholder={t("searchSubcollections")}
            className="w-full h-full focus:outline-none"
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
        {subCollections && pag !== null ? (
          <div className="h-full">
            <div class="flex flex-col">
              <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div class="py-2 align-middle inline-block w-full sm:px-6 lg:px-8">
                  <div class="shadow overflow-y-scroll md:overflow-hidden border-b border-gray-200 sm:rounded-lg">
                    <table class="w-full divide-y divide-gray-200 ">
                      <thead class="bg-gray-50">
                        <tr>
                          <th
                            scope="col"
                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                          >
                            {t("subCollectionName").toUpperCase()}
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                          >
                            {t("numOfItem")}
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-3  text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                          ></th>
                        </tr>
                      </thead>
                      {subCollections && pag !== null ? (
                        subCollections.map((elem) => (
                          <tbody className="bg-white divide-y divide-gray-200">
                            <tr className="cursor-pointer hover:bg-blue-50">
                              <td className="  whitespace-nowrap ">
                                {!elem.edit ? (
                                  <div
                                    className="text-sm px-6 py-4  text-gray-900 cursor-pointer"
                                    onClick={() =>
                                      !elem.edit && goToNext(elem.id)
                                    }
                                  >
                                    {elem.name}
                                  </div>
                                ) : (
                                  <div className="text-sm text-gray-900">
                                    <TextInput
                                      value={editColName}
                                      invalid={invalidEdit}
                                      onChange={(e) => {
                                        setEditColName(e.target.value);
                                        setInvalidEdit(false);
                                      }}
                                    />
                                  </div>
                                )}
                              </td>

                              <td className="  whitespace-nowrap  ">
                                <div
                                  className="text-sm px-6 py-4  text-gray-900 cursor-pointer"
                                  onClick={() =>
                                    !elem.edit && goToNext(elem.id)
                                  }
                                >
                                  {elem.itemCount}
                                </div>
                              </td>

                              {!elem.edit ? (
                                <td className="flex justify-end space-x-3 whitespace-nowrap">
                                  {localStorage.getItem("userToken") ? (
                                    <>
                                      <div
                                        onClick={() => goToNext(elem.id)}
                                        className=" w-full "
                                      ></div>
                                      <div
                                        className=" py-4"
                                        onClick={() => edit(elem)}
                                      >
                                        <Tooltip
                                          tooltipText="edit"
                                          element={
                                            <FiEdit
                                              color="#0369A1"
                                              className="w-5 h-5 cursor-pointer hover:shadow-lg"
                                            />
                                          }
                                        />
                                      </div>
                                      <div
                                        className="pr-6 py-4"
                                        onClick={() => {
                                          setCOnfirmModal(true);
                                          setItemToDelete(elem.id);
                                          setItemToDeleteName(elem.name);
                                        }}
                                      >
                                        <Tooltip
                                          tooltipText="delete"
                                          element={
                                            <RiDeleteBin6Line
                                              color="red"
                                              className="w-5 h-5 cursor-pointer hover:shadow-lg"
                                            />
                                          }
                                        />
                                      </div>
                                    </>
                                  ) : (
                                    <div className="py-4 pr-6">
                                      <IoIosArrowForward
                                        color="#0369A1"
                                        className="w-5 h-5 bg-transparent cursor-pointer "
                                      />
                                    </div>
                                  )}
                                </td>
                              ) : (
                                <td className="px-6 h-20 items-center flex justify-end space-x-3 whitespace-nowrap">
                                  <div
                                    className="text-sm text-blue-primary cursor-pointer hover:text-blue-400"
                                    onClick={() => checkValue(elem)}
                                  >
                                    {t("done")}
                                  </div>
                                  {localStorage.getItem("userToken") && (
                                    <div
                                      className="text-sm text-gray-400 cursor-pointer hover:text-gray-300"
                                      onClick={() => edit(elem)}
                                    >
                                      {t("cancel")}
                                    </div>
                                  )}
                                </td>
                              )}
                            </tr>
                          </tbody>
                        ))
                      ) : (
                        <h1>{t("noItems")}</h1>
                      )}
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-5">
              <Pagination pages={pag} changePage={changePage} />
            </div>
          </div>
        ) : noItems ? (
          <div className="h-full w-full flex items-center justify-center">
            {t("noItems")}
          </div>
        ) : (
          <div className="h-full w-full flex justify-center items-center">
            <Loader />
          </div>
        )}
      </div>
    </div>
  );
};

export default SubCollections;
