import React, { useState } from "react";

import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { HiPlusCircle } from "react-icons/hi";

import { Text } from "components";
import apiRequest from "helpers/apiRequest";
import ConfimationModal from "components/InfoModal";
import ErrorAlert from "components/ErrorAlert";
import Tooltip from "components/Tooltip";
import { RiDeleteBin6Line } from "react-icons/ri";

const AddSubCollection = ({ match }) => {
  const [collectionData, setCollectionData] = useState([]);
  const [confirmationModal, setConfrimationModal] = useState(false);
  const [collection, setCollection] = useState({
    value: "",
    invalid: false,
  });

  const { t } = useTranslation();
  const history = useHistory();
  const [error, setError] = useState(null);

  const getSubCollections = async () => {
    try {
      const response = await apiRequest({
        method: "get",
        url: `collection/list/${match.params.categoryId}`,
      });
      if (response.status === 200) {
        setCollectionData([...response.data.files]);
      }
    } catch (err) {
      if (err?.response?.status === 401) {
        history.push("/auth/login");
      }
      console.log(err);
    }
  };
  const createError = (msg = "fillAllFields") => {
    setError(<ErrorAlert errorText={msg} />);
    setTimeout(() => {
      setError(null);
    }, 3000);
  };

  const saveCollection = async () => {
    setConfrimationModal(null);
    try {
      const response = await apiRequest({
        method: "post",
        url: "subcollection",
        data: {
          collectionId: match.params.categoryId,
          name: collection.value,
        },
      });
      if (response.status === 200) {
        setCollection({ value: "", invalid: false });
        getSubCollections();
      }
    } catch (err) {
      if (err?.response?.status === 401) {
        history.push("/auth/login");
      }
      createError(err.response.data.error);
    }
  };
  const checkValid = () => {
    if (collection.value === "")
      return setCollection({ ...collection, invalid: true });
    return setConfrimationModal(true);
  };

  const deleteItem = async (id) => {
    try {
      const response = await apiRequest({
        method: "delete",
        url: `subcollection/${id}`,
      });
      if (response.status === 200) {
        getSubCollections();
      }
    } catch (err) {
      if (err?.response?.status === 401) {
        history.push("/auth/login");
      }
      console.log(err);
    }
    console.log(id);
  };
  return (
    <div className="bg-blue-100 h-screen w-full pt-5 pb-5 overflow-hidden">
      <ConfimationModal
        open={confirmationModal}
        confirmButtonText="continue"
        confirmationDesc="sureToProceed"
        onClose={() => setConfrimationModal(false)}
        onConfirm={saveCollection}
        onCancel={() => setConfrimationModal(false)}
      />
      {error}
      <div className=" bg-white rounded mx-5 h-screen px-10 pt-5 pb-12 overflow-y-auto">
        <div className="h-20 lg:w-full flex row justify-between items-center border-b-2 mb-16">
          <h2 className="text-2xl md:text-4xl font-inter  lg:w-1/2 ">
            {t("addSubCategory")}
          </h2>
        </div>
        <div>
          <div className="mb-1">
            <Text>{t("addSubCategory")}</Text>
          </div>
          <div className="flex">
            <div className="h-10 lg:w-1/2">
              <input
                className={`h-full w-full px-5 rounded-lg border`}
                value={
                  collection.value.charAt(0).toUpperCase() +
                  collection.value.slice(1)
                }
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    checkValid();
                  }
                }}
                onChange={(e) =>
                  setCollection({
                    ...collection,
                    value: e.target.value,
                    invalid: false,
                  })
                }
              />
              {collection.invalid && (
                <p className="text-red text-xs mb-5 ml-2">{t("emptyField")}</p>
              )}
            </div>
            <div className="h-10 w-24">
              <button
                className="ml-3 text-blue-primary h-full w-full rounded-lg mb-4 border-2 border-blue-primary cursor-pointer flex items-center justify-center"
                onClick={() => checkValid()}
              >
                <p className="hidden">{t("add")}</p>
                <p>
                  <HiPlusCircle color="#0369A1" />
                </p>
              </button>
            </div>
          </div>
        </div>
        {collectionData.length > 0 && (
          <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8 mt-10">
            <div className="py-2 align-middle inline-block w-full sm:px-6 lg:px-8">
              <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                <table className="w-full divide-y divide-gray-200">
                  <thead className="bg-gray-50">
                    <tr>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        {t("subCollectionName").toUpperCase()}
                      </th>
                    </tr>
                  </thead>
                  {collectionData.map((elem) => (
                    <tbody className="bg-white divide-y divide-gray-200">
                      <tr className="flex justify-between cursor-pointer hover:bg-blue-50">
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="text-sm text-gray-900">
                            {elem.name}
                          </div>
                        </td>
                        {localStorage.getItem("userToken") && (
                          <td className="px-6 py-4 whitespace-nowrap">
                            <div
                              className=""
                              onClick={() => {
                                deleteItem(elem.id);
                              }}
                            >
                              <Tooltip
                                tooltipText="delete"
                                element={
                                  <RiDeleteBin6Line
                                    color="red"
                                    className="w-5 h-5 cursor-pointer hover:shadow-lg"
                                  />
                                }
                              />
                            </div>
                          </td>
                        )}
                      </tr>
                    </tbody>
                  ))}
                </table>
              </div>
            </div>
          </div>
        )}
        <div className="w-full flex flex-col md:flex-row mt-10">
          <div className="mr-10 h-10 w-48">
            <button
              className="  text-blue-primary h-full w-full rounded-lg mb-4 border-2 border-blue-primary cursor-pointer flex items-center justify-center"
              onClick={() => history.push("/admin/collections")}
            >
              <p className="">{t("backToHome")}</p>
            </button>
          </div>
          <div className="mr-10 mb-5 md:mb-0 h-10  ">
            <button
              className="text-white bg-blue-primary h-full w-full rounded-lg mb-4 px-5 py-6 md:py-0  border-2 border-blue-primary cursor-pointer flex items-center justify-center"
              onClick={() =>
                history.push(`/admin/firstStep/${match.params.categoryId}/0`)
              }
            >
              <p className="">{t("addItems")}</p>
            </button>
          </div>
        </div>
        <div className="h-20 lg:w-3/4 flex row justify-end items-center border-t-2 mt-16"></div>
      </div>
    </div>
  );
};

export default AddSubCollection;
