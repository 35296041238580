/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import { IoClose } from "react-icons/io5";

import { Text } from "components";

const PhotoContainer = ({
  limited,
  name,
  size,
  editMode,
  onClick,
  hide,
  src,
  fullHeight,
}) => {
  return (
    <>
      {!hide ? (
        <div
          className={`${limited ? "md:w-1/3 h:40 2xl:h-52" : "w-full h-52"} ${
            fullHeight ? "h-full" : null
          }`}
        >
          <div
            className={`h-full rounded-lg overflow-hidden relative flex justify-center items-center `}
          >
            {editMode ? (
              <div
                onClick={onClick}
                className="w-8 h-8 rounded-full cursor-pointer bg-gray-400 absolute  flex items-center justify-center right-3 top-3 "
              >
                <IoClose color="black" className="w-5 h-5" />
              </div>
            ) : null}

            <img
              src={src}
              className="w-full  h-52"
              onError={(e) => {
                e.target.onerror = null;
                e.target.src =
                  "https://e-muzej-bucket.s3.eu-central-1.amazonaws.com/Default37-15-10-2021-2-58-09/15-10-2021-2-58-58No_Image.png";
              }}
            />
          </div>
          <Text black>{name}</Text>
          <Text gray>{size}</Text>
        </div>
      ) : null}
    </>
  );
};

export default PhotoContainer;
